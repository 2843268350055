import { LotSellByStatus } from '@/types/fulfillment';
import i18n from '@/services/i18n';

export const LotSellByStatusThresholds = {
  [LotSellByStatus.Imminent]: 30,
  [LotSellByStatus.Approaching]: 90,
};

export const getLotSellByStatus = (daysUntilSellBy: number | undefined): LotSellByStatus => {
  if (!Number.isInteger(daysUntilSellBy)) {
    return LotSellByStatus.None;
  }
  if (daysUntilSellBy <= LotSellByStatusThresholds[LotSellByStatus.Imminent]) {
    return LotSellByStatus.Imminent;
  }
  if (daysUntilSellBy <= LotSellByStatusThresholds[LotSellByStatus.Approaching]) {
    return LotSellByStatus.Approaching;
  }
  return LotSellByStatus.Distant;
};

export const getLotSellByStatusDisplayConfig = (
  status: LotSellByStatus
): { badgeColor: string; bgColor: string; labelColor?: string; label?: string } => {
  // BadgeColor and labelColor should use DS enums instead of string
  switch (status) {
    case LotSellByStatus.Imminent:
      return {
        label: i18n.global.t('account.fulfillment.lots.lotTable.status.badge.imminent'),
        badgeColor: 'red-full',
        bgColor: 'error-alt',
        labelColor: 'error',
      };
    case LotSellByStatus.Approaching:
      return {
        label: i18n.global.t('account.fulfillment.lots.lotTable.status.badge.approaching'),
        badgeColor: 'orange',
        bgColor: 'warning-100',
        labelColor: 'warning',
      };
    case LotSellByStatus.Distant:
      return {
        label: i18n.global.t('account.fulfillment.lots.lotTable.status.badge.distant'),
        badgeColor: 'green',
        bgColor: 'success-100',
        labelColor: 'success',
      };
    case LotSellByStatus.None:
    default:
      return {
        label: i18n.global.t('account.fulfillment.lots.lotTable.status.badge.none'),
        badgeColor: 'grey',
        bgColor: 'neutral-300',
        labelColor: 'primary',
      };
  }
};

export const getLotByItemId =
  (id: string) =>
  ({ fulfillmentItemId }) =>
    id === fulfillmentItemId;
