<script setup lang="ts">
import { Ref, computed } from 'vue';
import Product from '@/types/product';
import ProductSnippet from '@/components/product/product-snippet.vue';
import { SkeletonCard } from '@bc/discovery/ui/cards/skeleton-card';
import { AkIcon, AkHeading3 } from '@ankorstore/design-system';
import type { AdditionalLikeTrackingData } from '@/types/analytics/recommendation-tracking';

const props = defineProps<{
  items: Ref<Product[]>;
  isLoading: Ref<boolean>;
  parentSectionId: string;
  pageNumber: number;
  parentComponent: string;
  additionalLikeTrackingData: AdditionalLikeTrackingData;
}>();
defineEmits(['clicked', 'brandClicked', 'quickAddToCartClicked']);

const itemList = computed(() =>
  props.items.value.map((product: Product, index: number) => ({
    product,
    additionalLikeTrackingData: {
      ...props.additionalLikeTrackingData,
      position: index,
      id_product: `${product.id}`,
    },
  }))
);
</script>

<template>
  <div class="similar-products-grid">
    <span class="similar-products-grid__header">
      <AkIcon
        symbol="stars"
        size="lg"
      />
      <AkHeading3>
        {{ $t('discovery.recommendations.similar_products.drawer.title') }}
      </AkHeading3>
    </span>
    <div class="similar-products-grid__container">
      <template v-if="isLoading.value">
        <SkeletonCard
          v-for="i in 12"
          :key="i"
          class="similar-products-grid__item"
        />
      </template>
      <template v-else-if="Boolean(itemList.length)">
        <ProductSnippet
          v-for="(item, index) in itemList"
          :key="item.product.id"
          class="similar-products-grid__item"
          :with-brand-link="true"
          :item="item.product"
          :index="index"
          :parent-section-id="parentSectionId"
          :page-number="pageNumber"
          :parent-component="parentComponent"
          :disabled-event-bus="true"
          :additional-like-tracking-data="item.additionalLikeTrackingData"
          @clicked="$emit('clicked')"
          @brand-clicked="$emit('brandClicked')"
          @quick-add-to-cart-clicked="$emit('quickAddToCartClicked')"
        />
      </template>
    </div>
  </div>
</template>

<style scoped lang="scss">
@use '@css/abstracts/design-tokens' as d;
.similar-products-grid {
  padding: 2rem 1.5rem;
  overflow-x: hidden;
  &__header {
    display: flex;
    align-items: center;
    margin-bottom: 2rem;
  }
  &__container {
    display: grid;
    place-items: start;
    grid-gap: 1rem;
    grid-template-columns: repeat(2, 1fr);
    @include d.lg() {
      grid-template-columns: repeat(3, 1fr);
    }
  }
  &__item {
    width: 100%;
    margin: 0;
  }
}
</style>
