<template>
  <AkDrawer
    :is-open="opened"
    :overlay="false"
    right
    :width="fullWidth ? '100%' : '40%'"
    class="filters-drawer"
    @update:open="open"
    @update:close="close"
  >
    <div class="filters-drawer__main">
      <AkHeading4 class="ds-mb-2">
        {{ $t('brands.contactManagement.filtersDrawer.title') }}
      </AkHeading4>
      <AkParagraph size="sm">
        {{ $t('brands.contactManagement.filtersDrawer.subTitle') }}
      </AkParagraph>
      <div class="ds-mt-5">
        <ActiveFilters />
      </div>
      <template
        v-for="(group, index) in getGroupedFilters"
        :key="index"
      >
        <div
          class="ds-flex ds-justify-between ds-border-t ds-border-solid ds-border-neutral-300 ds-mt-5 ds-pt-5"
          @click="openFilterGroup(index)"
        >
          <AkHeading4
            class="filter-group-name"
            data-testid="filter-group-name"
            >{{ getGroupNameTranslation(group) }}</AkHeading4
          >
          <AkIcon
            :symbol="showGroup[index] ? 'chevron-up' : 'chevron-down'"
            size="md"
            data-testid="filter-group-toggle"
            class="ds-cursor-pointer"
          />
        </div>
        <AkExpander
          :keep-alive="true"
          :open="showGroup[index]"
        >
          <template
            v-for="(item, itemIndex) in group.items"
            :key="itemIndex"
          >
            <AkParagraph
              v-if="item.groupName"
              class="ds-mt-5 ds-text-neutral-900"
              data-testid="filter-subgroup-name"
              >{{ getGroupNameTranslation(item) }}</AkParagraph
            >
            <template
              v-for="(comp, compIndex) in item.components"
              :key="compIndex"
            >
              <div
                v-if="comp.values || comp.attributes || comp.type === WidgetTypes.ToggleWidget"
                class="filters-drawer__section"
              >
                <component
                  :is="returnComponent(comp.type)"
                  :ref="comp.type"
                  :filter="comp"
                  :model-value="selectedFilters[comp.attributeName]"
                  @update:model-value="setSelectedFilter(comp.attributeName, $event)"
                />
              </div>
            </template>
          </template>
        </AkExpander>
      </template>
    </div>
    <div class="filters-drawer__bottom">
      <AkButton
        size="md"
        @click="submitFilters"
      >
        {{ $t('brands.contactManagement.filtersDrawer.apply') }}
      </AkButton>
    </div>
  </AkDrawer>
</template>

<script lang="ts">
import { defineComponent, ref, computed, type ComputedRef, type Component } from 'vue';
import { useStore } from '@/composables/use-store';
import { useBreakpoints } from '@/modules/design-system-candidates';
import { debounce } from 'lodash-es';
import { AkParagraph, AkHeading4, AkButton, AkDrawer, AkExpander } from '@ankorstore/design-system';
import { type ContactFilter, ContactFilterType } from '../../types/api.types';
import ActiveFilters from '../active-filters/active-filters.vue';
import RangeWidget from './range-widget.vue';
import DropdownWidget from './dropdown-widget.vue';
import MultiSelectWidget from './multi-select-widget.vue';
import ListWidget from './list-widget.vue';
import ToggleWidget from './toggle-widget.vue';
import Analytics from '@/services/analytics';
import { BrandClickedEvent } from '@/services/analytics/events/brand-clicked.event';
import { BrandClickAction } from '@/types/analytics/brand-click-event';
import { WidgetTypes } from '../../types/filter.types';
import { useI18n } from 'vue-i18n';

export default defineComponent({
  name: 'FiltersDrawer',
  components: {
    RangeWidget,
    DropdownWidget,
    MultiSelectWidget,
    ListWidget,
    ToggleWidget,
    ActiveFilters,
    AkParagraph,
    AkHeading4,
    AkButton,
    AkDrawer,
    AkExpander,
  },
  setup() {
    const { isMobile, isXlMobile } = useBreakpoints();
    const store = useStore();
    const { t } = useI18n();

    const opened = ref(false);
    const showGroup = ref([true, true, true]);
    const getGroupedFilters = computed(() => store.getters['account/contactManagement/getGroupedFilters']);

    const fullWidth = computed(() => isXlMobile.value || isMobile.value);
    const selectedFilters = computed(() => store.getters['account/contactManagement/getFiltersState']);
    const selectedFiltersStateForAnalytics = computed(
      () => store.getters['account/contactManagement/getFiltersStateForAnalytics']
    );
    const brandId: ComputedRef<number> = computed(() => store.getters['brand']?.id);
    const DEBOUNCE_TIME = 350;

    const close = () => {
      opened.value = false;
    };

    const returnComponent = (component: WidgetTypes): Component | null => {
      switch (component) {
        case WidgetTypes.RangeWidget:
          return RangeWidget;
        case WidgetTypes.DropdownWidget:
          return DropdownWidget;
        case WidgetTypes.MultiSelectWidget:
          return MultiSelectWidget;
        case WidgetTypes.ListWidget:
          return ListWidget;
        case WidgetTypes.ToggleWidget:
          return ToggleWidget;
      }
      return null;
    };

    const open = () => {
      opened.value = true;
    };

    const setSelectedFilter = (id: string, value: ContactFilter) => {
      if (value.type === ContactFilterType.Range && !value.min && !value.exclusiveMax) {
        updateFilter(id, null);
        return;
      }
      if (value.type === ContactFilterType.Value && !value.value?.length) {
        updateFilter(id, null);
        return;
      }
      if (value.type === ContactFilterType.Boolean && value.value === undefined) {
        updateFilter(id, null);
        return;
      }
      updateFilter(id, value);
    };

    const updateFilter = debounce((id, value) => {
      Analytics.track(
        new BrandClickedEvent({
          brand_id: brandId.value,
          action: BrandClickAction.BrandApplyFilters,
          value: selectedFiltersStateForAnalytics.value,
        })
      );
      store.dispatch('account/contactManagement/setFilter', [id, value]);
    }, DEBOUNCE_TIME);

    const submitFilters = async () => {
      close();
    };

    const openFilterGroup = (i: number) => {
      showGroup.value[i] = !showGroup.value[i];
    };

    const getGroupNameTranslation = ({ groupName, components }): string => {
      switch (groupName) {
        case 'orders-history':
          return t('network.filters.title.ordersHistory');
        case 'items-in-cart':
          if (components?.[0].type === WidgetTypes.ToggleWidget) {
            return t('network.filters.title.itemsInCart');
          }
          return null;
        case 'shop-details':
          return t('network.filters.title.shopDetails');
        case 'shop-information':
          return t('network.filters.title.shopInformation');
        case 'purchasing-benefit':
          return t('network.filters.title.purchasingBenefit');
        case 'discounts':
          return t('network.filters.title.discounts');
        default:
          return '';
      }
    };

    return {
      open,
      close,
      opened,
      selectedFilters,
      setSelectedFilter,
      submitFilters,
      fullWidth,
      returnComponent,
      WidgetTypes,
      selectedFiltersStateForAnalytics,
      getGroupedFilters,
      openFilterGroup,
      showGroup,
      getGroupNameTranslation,
    };
  },
});
</script>

<style lang="scss">
.filters-drawer {
  z-index: 999 !important;
}

.ak-drawer__content {
  @apply ds-flex ds-flex-col ds-h-screen ds-justify-between;
}

.filters-drawer__main {
  @apply ds-p-6 ds-mb-auto;
}

.filters-drawer__section {
  @apply ds-pt-3 ds-mb-3;
}

.filters-drawer__bottom {
  @apply ds-text-right ds-p-5;
}

.filter-group-name {
  @apply ds-text-base #{!important};
}
</style>
