<template>
  <div
    class="ds-flex ds-flex-col ds-flex-auto ds-h-full ds-mt-auto ds-px-4 ds-py-5 ds-bg-white ds-border-t ds-border-solid ds-border-neutral-300"
  >
    <router-link
      :to="item.link"
      data-testid="product-name"
      @click.prevent="$emit('handleClickProduct', $event)"
    >
      <h2 class="product-title ak-stretched-link ds-text-sm md:ds-text-base ds-font-bold ds-leading-2 md:ds-leading-6 ds-mb-3">
        {{ item.name }}
      </h2>
    </router-link>

    <div
      v-if="withBrandLink"
      class="product-properties__brand-container"
    >
      <router-link
        :to="item.brand.link"
        class="product-properties__brand-link"
        :class="NativeAdClickOrigin.BrandName"
        @click.prevent.stop="brandClicked"
      >
        {{ item.brand.name.toUpperCase() }}
      </router-link>
      <BadgeBPG
        :is-visible="isBpgCompliant"
        with-tooltip
        context="tile"
      />
    </div>

    <a
      v-if="withLinkToFP"
      :href="item.link"
      class="text-center linkToFP"
    >
      {{ $t('see the product page') }}
    </a>

    <div
      v-if="priceBreakdown"
      class="product-properties ds-flex ds-flex-col"
      @click="handlePriceBreakdownClick"
    >
      <WholesalePrice
        :item="item"
        :price-breakdown="priceBreakdown"
        :user="user"
        :user-is-retailer="userIsRetailer"
        :can-get-wholesale-price="canGetWholesalePrice"
        :brand-discount="brandDiscountOrigin"
        :offer-discount="offerDiscountOrigin"
        :is-bpg-compliant="isBpgCompliant"
        :context="BPGContext.tile"
        size-info-icon="sm"
      />
      <span
        v-if="$isEnabled('RET-2003')"
        class="price-retail"
      >
        {{ `${$t('SRP')} ${formattedRetailPrice}` }}
      </span>

      <span
        v-if="hasAvailableVariantLabel"
        class="variant-available"
      >
        {{ availableVariantLabel }}
      </span>
    </div>
    <div
      v-if="isSponsored"
      class="product-properties__sponsor-container"
    >
      <AkParagraph
        uppercase
        class="ds-text-neutral-900 ds-mr-1"
        data-testid="sponsored-label"
      >
        {{ $t('catalog.sponsoredItems.label') }}
      </AkParagraph>
      <AkTooltipInformation
        trigger="hover"
        placement="right"
        class="product-properties__sponsor-container-tooltip"
        type="success"
      >
        <AkIcon
          symbol="info-circle"
          class="ds-text-neutral-900"
          data-testid="sponsored-info-icon"
        />
        <template #container>
          <AkLink
            v-if="isBrandWithAdsEnabled"
            class="hover:ds-text-white"
            size="sm"
            color="white"
            data-testid="sponsored-tooltip-link"
            @click="trackAdsTooltipClick"
          >
            {{ sponsoredItemTooltipContent }}
          </AkLink>
          <AkParagraph
            v-else
            data-testid="sponsored-tooltip-text"
          >
            {{ sponsoredItemTooltipContent }}
          </AkParagraph>
        </template>
      </AkTooltipInformation>
    </div>
    <div
      v-if="isPreorder(item) && !isProductOutOfStock(item)"
      class="product-properties__preorder"
    >
      <AkIcon
        ref="iconTruck"
        symbol="truck"
      />
      <span>
        {{ `${$t('catalog.shippingDate.lbl')} ${formatDate(getPreorderAvailableAt(item), 'dd/MM/yyyy')}` }}
      </span>
    </div>
  </div>
</template>

<script lang="ts">
import { computed, defineComponent, PropType } from 'vue';
import Product, { PriceBreakdown, ProductDiscount } from '@/types/product';
import WholesalePrice from '@/components/product/sub-components/wholesale-price.vue';
import { LinkRoutingMixin } from '@/mixins/link-routing';
import { getVariantTypeLabel } from '@/utilities/variant';
import {
  getMainVariant,
  getVariantValuesByOptionName,
  isPreorder,
  isProductOutOfStock,
  getPreorderAvailableAt,
} from '@/services/product-variants';
import { BadgeConfiguration } from '@/modules/promote-engage/touchpoints/services/badges-list.service';
import { BrandTagTypeEnum } from '@/types/brand';
import { AkIcon, AkParagraph, AkTooltipInformation } from '@ankorstore/design-system';
import BadgeBPG from '@/components/badges/bpg/badge-bpg.vue';
import { User } from '@/types/user';
import { BPGContext } from '@/types/badges/bpg/bpg';
import { isEnabled } from '@/services/features';
import { useI18n } from 'vue-i18n';
import { useStore } from '@/composables/use-store';
import usePrice from '@/composables/use-price';
import { useRouter } from 'vue-router';
import { NativeAdClickOrigin } from '@bc/advertisement';
import { formatDate } from '@/services/date-locale';
import { ESProductHit } from '@bc/discovery';

export default defineComponent({
  name: 'ProductProperties',
  components: {
    WholesalePrice,
    AkTooltipInformation,
    AkIcon,
    BadgeBPG,
    AkParagraph,
  },
  mixins: [LinkRoutingMixin],
  props: {
    item: {
      type: Object as PropType<Product | ESProductHit>,
      required: true,
    },
    user: {
      type: Object as PropType<User>,
      default: null,
    },
    userIsRetailer: {
      type: Boolean,
      required: true,
    },
    brandDiscountOrigin: {
      type: Object as PropType<ProductDiscount>,
      required: false,
      default: null,
    },
    offerDiscountOrigin: {
      type: Object as PropType<ProductDiscount>,
      required: false,
      default: null,
    },
    priceBreakdown: {
      type: Object as PropType<PriceBreakdown>,
      required: false,
      default: null,
    },
    canGetWholesalePrice: {
      type: Boolean,
      default: false,
    },
    withLinkToFP: {
      type: Boolean,
      default: false,
    },
    withBrandLink: {
      type: Boolean,
      default: false,
    },
    isSponsored: {
      type: Boolean,
      default: false,
    },
  },
  emits: ['handleClickProduct', 'handleBrandClick', 'adsTooltipClicked'],
  setup(props, { emit }) {
    const store = useStore();

    const router = useRouter();

    const { formatPrice } = usePrice();

    const i18n = useI18n();

    const isBrandWithAdsEnabled = computed(() => {
      return store.getters.isUserAuthenticated && !store.getters.userIsRetailer && isEnabled('MNY_115');
    });
    const sponsoredItemTooltipContent = computed(() => {
      return isBrandWithAdsEnabled.value
        ? i18n.t('sponsorCarousel.label.tooltipTextForBrand')
        : i18n.t('sponsorCarousel.label.tooltipText');
    });

    function trackAdsTooltipClick() {
      emit('adsTooltipClicked');
    }

    const formattedRetailPrice = computed(() => {
      return formatPrice(props.priceBreakdown?.retailPrice);
    });

    const formattedWholesalePrice = computed(() => {
      return formatPrice(props.priceBreakdown?.wholesalePrice);
    });

    function handlePriceBreakdownClick(): void {
      if (props.canGetWholesalePrice) {
        emit('handleClickProduct');
        router.push(props.item.link);
      }
    }

    return {
      sponsoredItemTooltipContent,
      isBrandWithAdsEnabled,
      formattedRetailPrice,
      formattedWholesalePrice,
      trackAdsTooltipClick,
      handlePriceBreakdownClick,
      isPreorder,
      getPreorderAvailableAt,
      isProductOutOfStock,
      NativeAdClickOrigin,
      formatDate,
    };
  },
  data: function () {
    return {
      BPGContext,
    };
  },
  computed: {
    isBpgCompliant(): boolean {
      return isEnabled('pricing.bxp-1491.store.listing') && Boolean(this.item.brand.brand_tags?.includes(BrandTagTypeEnum.BPG));
    },
    discounts(): BadgeConfiguration[] {
      const discounts = [];

      if (this.brandDiscountOrigin) {
        discounts.push(this.brandDiscountOrigin.percentage);
      }

      if (this.offerDiscountOrigin) {
        discounts.push(this.offerDiscountOrigin.percentage);
      }

      return [
        {
          priority: 1,
          discounts,
          color: 'purple-full',
        },
      ];
    },
    availableVariantLabel(): string {
      const mainOptionName = getMainVariant(this.item.variants)?.name;
      const values = getVariantValuesByOptionName(this.item.variants, mainOptionName);
      return getVariantTypeLabel(mainOptionName, values.length);
    },
    hasAvailableVariantLabel(): boolean {
      return this.item.variants && this.item.variants[0]?.options?.length >= 1;
    },
  },
  methods: {
    brandClicked(): void {
      this.$store.dispatch('shop/storeBrand', { brand: this.item.brand });
      this.$emit('handleBrandClick');
    },
  },
});
</script>

<style lang="scss" scoped>
@import '@css/vue-import';

:deep(.icon-award) {
  font-size: 0.675rem;
  z-index: $z-index-catalog-filters;
}

.bottom-badges {
  column-gap: 6px;
  margin-bottom: 8px;
}

.product-properties {
  z-index: 1;
  @apply ds-cursor-pointer;

  &__sponsor-container {
    @apply ds-flex ds-items-center ds-mt-2;
  }

  &__sponsor-container-tooltip {
    z-index: 4;
  }

  &__brand-link {
    @apply ds-text-xs ds-text-neutral-700 ds-underline;
    z-index: $z-index-catalog-card-items;
  }

  &__reliable-container {
    @apply ds-bg-primary ds-text-accent-alt ds-rounded-xl ds-flex ds-justify-center ds-items-center;
    z-index: $z-index-catalog-filters;
    width: 16px;
    height: 16px;
  }

  &__brand-container {
    @apply ds-inline-flex ds-gap-1 ds-items-center ds-mb-2;
  }
  &__preorder {
    @apply ds-text-neutral-700 ds-inline-block ds-mt-2 ds-font-basic ds-font-semibold;
    font-size: 12px;
    i {
      @apply ds-inline-block;
      margin-top: -1px;
      vertical-align: top;
    }
  }
}

.product-title {
  @apply ds-font-basic;
  text-overflow: ellipsis;
  line-clamp: 2;
  overflow: hidden;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;

  .newness {
    @apply ds-text-discount-700 ds-font-basic;
    font-style: italic;
    text-transform: none;
  }
}

.linkToFP {
  @apply ds-font-basic;
  display: block;
  text-decoration: underline;
  margin-bottom: 25px;
  font-size: 14px;
}

.price-retail,
.variant-available {
  @apply ds-text-neutral-700;
  font-size: 12px;
}

.linkToBrand {
  font-size: 12px;
  margin-bottom: 6px;
  z-index: $z-index-catalog-card-items;
}

.sold-by {
  font-size: 12px;
  line-height: 16px;
  font-weight: bold;
}
</style>
