<template>
  <div
    v-if="formatCountArticles"
    ref="badge"
    class="ak-badge"
    data-testid="badge-count-articles"
  >
    {{ formatCountArticles }}
  </div>
</template>

<script lang="ts">
import { productsCount } from '@/modules/cart-2/composables/use-counters';
import { defineComponent, computed } from 'vue';
import { useStore } from '@/composables/use-store';

export default defineComponent({
  name: 'BadgeCountArticles',
  setup() {
    const store = useStore();

    const preorderItemsCount = computed(() => store.getters['cartPreorder/totalCountArticles']);
    const formatCountArticles = computed(() => {
      return parseInt(productsCount.value, 10) + preorderItemsCount.value <= 99
        ? parseInt(productsCount.value, 10) + preorderItemsCount.value
        : '99+';
    });

    return {
      formatCountArticles,
    };
  },
  data() {
    return {
      quantities: {},
    };
  },
  watch: {
    formatCountArticles() {
      if (!this.$refs.badge) {
        return;
      }
      (this.$refs.badge as HTMLElement).classList.add('pulse');

      setTimeout(() => {
        if (!this.$refs.badge) {
          return;
        }
        (this.$refs.badge as HTMLElement).classList.remove('pulse');
      }, 500);
    },
  },
});
</script>

<style scoped>
select {
  width: 100%;
}
button {
  width: 100%;
  margin-top: 10px;
}
</style>

<style scoped lang="scss">
@import '@css/vue-import';

.ak-badge {
  position: absolute;
  font-size: 9px;
  @apply ds-bg-warning-700 ds-font-basic;
  border-radius: 50%;
  @apply ds-border ds-border-solid ds-border-white;
  display: flex;
  height: 20px;
  width: 20px;
  justify-content: center;
  align-items: center;
  @apply ds-text-white;
  left: 11px;
  bottom: 6px;
  font-weight: 700;
  opacity: 0.9;

  @include media-breakpoint-up(xl) {
    left: -15px;
    top: -3px;
  }
}

$zoom: 1.3;

@keyframes pulse {
  from {
    transform: scale(1, 1);
  }

  50% {
    transform: scale($zoom, $zoom);
  }

  to {
    transform: scale(1, 1);
  }
}

.pulse {
  animation-name: pulse;
  animation-duration: 500ms;
}
</style>
