<template>
  <Card
    :class="{
      'product-snippet': true,
      'has-checkbox': withCheckbox,
      'product-snippet--full': fullWidth,
      'product-snippet--sponsored': isSponsored,
    }"
    data-testid="productSnippetCard"
    @mouseenter="isHovered = true"
    @mouseleave="isHovered = false"
  >
    <template #topLeftActions>
      <div
        v-if="withCheckbox"
        class="checkbox-form"
      >
        <AkCheckbox
          :id="'checkbox-' + item.id"
          :checked="isCheckboxChecked"
          :value="`${item.id}`"
          @change="(isChecked: boolean) => $emit('checked', isChecked)"
        />
      </div>

      <QuickAddToCart
        v-else-if="
          (isMobile || isXlMobile || isTablet || isXlTablet) && withQuickAddToCart && hasPriceBreakdown && canHaveQuickCart
        "
        :key="item.id"
        :index="index"
        :page-number="pageNumber"
        :product="item"
        :price-breakdown="priceBreakdown"
        :parent-section-id="parentSectionId"
        :parent-component="parentComponent"
        :is-sponsored="isSponsored"
        :disable-tracking="disableTracking"
        mobile
        data-testid="qatc-mobile"
        @clicked="handleQuickAddToCartClicked"
        @product-added-to-cart="handleVariantAddToCart"
        @click-variants-modal="openQuickAddToCartModal"
      />

      <StockAlertBtn
        v-else-if="isRestockAlertBtnVisible"
        class="product-snippet__stock-alert-btn-mobile"
        mobile
        :is-loading="isStockAlertLoading"
        :stock-alert="productVariantStockAlert"
        data-testid="productSnippetStockAlertBtnMobile"
        @set-stock-alert="onSetStockAlert"
      />
    </template>

    <template #topRightActions>
      <div class="product-snippet__cta-buttons">
        <LikeButton
          v-if="canHaveFavourite"
          :id="item.id"
          class="ds-justify-self-end"
          type="product"
          data-testid="favourite-product"
          :with-tooltip="true"
          :is-focused="isHovered"
          :disable-tracking="true"
          @like-product="handleProductLiked"
          @unlike-product="handleProductDisliked"
        />
        <SimilarProductsButton
          v-if="$isEnabled('RET-3124') && withSimilarProductsDrawerButton"
          :is-focused="isHovered"
          @click="$emit('similarProductsClicked')"
        />
      </div>
    </template>

    <template #middleActions>
      <QuickAddToCart
        v-if="showQuickAdd"
        :key="'desktop-' + item.id"
        class="product-snippet__qatc"
        :index="index"
        :page-number="pageNumber"
        :product="item"
        :price-breakdown="priceBreakdown"
        :parent-section-id="parentSectionId"
        :parent-component="parentComponent"
        :is-sponsored="isSponsored"
        data-testid="qatc-desktop"
        @clicked="handleQuickAddToCartClicked"
        @product-added-to-cart="handleVariantAddToCart"
        @click-variants-modal="openQuickAddToCartModal"
      />
      <div
        v-else-if="isRestockAlertBtnVisible"
        class="product-snippet__stock-alert"
      >
        <StockAlertBtn
          class="product-snippet__stock-alert-btn"
          :is-loading="isStockAlertLoading"
          :stock-alert="productVariantStockAlert"
          btn-size="md"
          :multiline="true"
          data-testid="productSnippetStockAlertBtnDesktop"
          @set-stock-alert="onSetStockAlert"
        />
      </div>
    </template>

    <template #topContent>
      <ProductSnippetImage
        class="product-snippet__image"
        :can-get-wholesale-price="canGetWholesalePrice"
        :item="item"
        :index="index"
        :page-number="pageNumber"
        :price-breakdown="priceBreakdown"
        :brand-discount-origin="brandDiscountOrigin"
        :discount-type="item.discount_type"
        :offer-discount-origin="appliedDiscount"
        :with-quick-add-to-cart="withQuickAddToCart"
        :with-best-seller-badge="withBestSellerBadge"
        :with-new-badge="withNewBadge"
      />
    </template>

    <template #bottomContent>
      <ProductProperties
        v-if="hasPriceBreakdown"
        :retail-price="retail_price"
        :original-wholesale-price="original_wholesale_price"
        :can-get-wholesale-price="canGetWholesalePrice"
        :user-is-retailer="userIsRetailer"
        :user="user"
        :brand-discount-origin="brandDiscountOrigin"
        :offer-discount-origin="appliedDiscount"
        :price-breakdown="priceBreakdown"
        :item="item"
        :with-link-to-f-p="withLinkToFP"
        :with-brand-link="withBrandLink"
        :is-sponsored="isSponsored"
        @handle-click-product="handleProductClick"
        @handle-brand-click="handleBrandClick"
        @ads-tooltip-clicked="handleAdsTooltipClicked"
      />

      <Teleport to="body">
        <AkModal
          v-if="item.variants && withQuickAddToCart && hasPriceBreakdown"
          ref="quickAddToCartModal"
          size="xl"
          :with-padding="false"
          data-testid="addToCartModal"
        >
          <AddToCartPopin
            :product-id="item.id"
            :price-breakdown="priceBreakdown"
            @add-to-cart-variant="handleVariantAddToCart"
          />
        </AkModal>
      </Teleport>
    </template>
  </Card>
</template>

<script lang="ts">
import { defineComponent, ref, computed, PropType, toRefs } from 'vue';
import { loginPopinMixin } from '@/mixins/login-popin';
import { sitewideOfferMixin } from '@/mixins/sitewide-offer';
import Product, { PriceBreakdown, Variant, ProductDiscount, ProductHitBrand } from '@/types/product';
import { Brand } from '@/types/api/brand';
import { getHighestDiscount } from '@/services/product';
import { LinkRoutingMixin } from '@/mixins/link-routing';
import { getDiscount, getPriceBreakdown } from '@/services/utils/tooling';
import { isProductMultiple, isProductOutOfStock, isPreorder } from '@/services/product-variants';
import type { AdditionalLikeTrackingData } from '@/types/analytics/recommendation-tracking';
import { AkCheckbox, AkModal } from '@ankorstore/design-system';
import { useEventBus, getSectionId, SectionType } from '@bc/shared';
import LikeButton from '@/components/like-button.vue';
import Card from '@/modules/cards/card.vue';
import QuickAddToCart from '@/components/cart/quick-add-to-cart.vue';
import AddToCartPopin from '@/components/popins/add-to-cart-popin.vue';
import ProductProperties from '@/components/product/sub-components/product-properties.vue';
import ProductSnippetImage from '@/components/product/sub-components/product-snippet-image.vue';
import { DineroObject } from 'dinero.js';
import { withBreakpoints } from '@/modules/design-system-candidates';
import StockAlertBtn from '@/modules/reorder/stock-status/stock-alert-btn.vue';
import { useStockStatus } from '@/modules/reorder/stock-status/composables/stock-status';
import usePrice from '@/composables/use-price';
import { type ESProductHit, type ESSponsoredProductHit, DiscoveryTrackingEvents, getProductSnippetTracking } from '@bc/discovery';
import rootStoreHelpers from '@/store/helpers';
import offersStoreHelpers from '@/store/offers/helpers';
import shopStoreHelpers from '@/store/shop/helpers';
import { useRoute } from 'vue-router';
import { createProductProperty } from '@/services/analytics/properties/product-property';
import { createBrandProperty } from '@/services/analytics/properties/brand-property';

// eslint-disable-next-line dependency-cruiser/errors
import SimilarProductsButton from '@bc/discovery/ui/recommendation/similar-products-button/similar-products-button.vue';

export default defineComponent({
  name: 'ProductSnippet',
  components: {
    StockAlertBtn,
    AkCheckbox,
    ProductProperties,
    ProductSnippetImage,
    LikeButton,
    QuickAddToCart,
    Card,
    AkModal,
    AddToCartPopin,
    SimilarProductsButton,
  },
  mixins: [loginPopinMixin, sitewideOfferMixin, LinkRoutingMixin, withBreakpoints],
  props: {
    index: {
      type: Number,
      required: false,
      default: undefined,
    },
    pageNumber: {
      type: Number,
      required: false,
      default: undefined,
    },
    item: {
      type: Object as PropType<Product | ESProductHit | ESSponsoredProductHit>,
      required: true,
    },
    withQuickAddToCart: {
      type: Boolean,
      default: true,
    },
    withLinkToFP: {
      type: Boolean,
      default: false,
    },
    withFavoriteBtn: {
      type: Boolean,
      default: true,
    },
    withBrandLink: {
      type: Boolean,
      default: false,
    },
    withCheckbox: {
      type: Boolean,
      default: false,
    },
    isCheckboxChecked: {
      type: Boolean,
      default: false,
    },
    event: {
      type: Object,
      required: false,
      default: null,
    },
    productBrand: {
      type: Object as PropType<Brand | ProductHitBrand>,
      default: null,
    },
    fullWidth: {
      type: Boolean,
      default: false,
    },
    withBestSellerBadge: {
      type: Boolean,
      default: true,
    },
    withNewBadge: {
      type: Boolean,
      default: true,
    },
    withSimilarProductsDrawer: {
      type: Boolean,
      default: false,
    },
    variant: {
      type: Object as PropType<Variant>,
      default: null,
    },
    additionalLikeTrackingData: {
      type: Object as PropType<AdditionalLikeTrackingData>,
      default: undefined,
    },
    parentSectionId: {
      type: String as PropType<string>,
      default: '',
    },
    parentComponent: {
      type: String as PropType<string>,
      default: null,
    },
    isSponsored: {
      type: Boolean,
      default: false,
    },
    // Temporary prop to disable tracking while we work on RET-3307
    disableTracking: {
      type: Boolean,
      default: false,
    },
    // We introduce this prop to avoid emitting events on the event bus for the data recommendation component
    // This is a temporary solution until we refactor event handling in the product snippet component
    // Ticket here : https://ankorstore.atlassian.net/browse/RLO-1331
    disabledEventBus: {
      type: Boolean,
      default: false,
    },
  },
  emits: [
    'clicked',
    'brandClicked',
    'quickAddToCartClicked',
    'variantAddedToCart',
    'productLiked',
    'productDisliked',
    'similarProductsClicked',
    'ads-tooltip-clicked',
    'checked',
  ],
  setup(props, { emit }) {
    const { parentComponent } = toRefs(props);

    const route = useRoute();

    // we noop function if disabledEventBus is true to avoid manipulate or change old function with multiple condition to encapsulated function
    const { eventBus } = props.disabledEventBus ? { eventBus: { emit: () => {}, tryEmit: () => {} } } : useEventBus();

    const { createStockAlert, isStockAlertLoading, productVariantStockAlert } = useStockStatus();
    const { getConvertedPrice } = usePrice();
    const { user, userIsRetailer, canGetWholesalePrice, hasUnhandledCountry, retailer, productByOptionId, userCurrency } =
      rootStoreHelpers.useGetters([
        'user',
        'userIsRetailer',
        'canGetWholesalePrice',
        'hasUnhandledCountry',
        'retailer',
        'productByOptionId',
        'userCurrency',
      ]);
    const { b2rEligibility, welcomeOffer, appliedDiscount } = offersStoreHelpers.useGetters([
      'b2rEligibility',
      'welcomeOffer',
      'appliedDiscount',
    ]);
    const { storeBrand } = shopStoreHelpers.useActions(['storeBrand']);

    const isHovered = ref(false);

    const component = computed(() => parentComponent.value || `${String(route?.name)}_page`);
    const sectionId = computed(
      () =>
        props.parentSectionId ||
        getSectionId({ sectionType: SectionType.ProductSnippet, valueProposition: `product_${props.item.id}` })
    );
    const {
      trackProductBrandClicked,
      trackSponsoredProductTooltipClick,
      trackVariantAddedToCart,
      trackProductLiked,
      trackProductDisliked,
    } = getProductSnippetTracking(sectionId, ref(undefined), component);

    const handleProductClick = (event: Event) => {
      emit('clicked', event);
      storeBrand({ brand: props.item.brand });
    };

    const handleBrandClick = () => {
      emit('brandClicked');
      if (props.disableTracking) {
        return;
      }

      const busEvent = DiscoveryTrackingEvents.PRODUCT_SNIPPET_BRAND_CLICKED;
      const legacyTrackingFallback = () => {
        trackProductBrandClicked({
          product: props.item,
          position: props.index,
          page: props.pageNumber,
        });
      };
      const busEventDetail = {
        brandId: props.item.brand.id,
        position: props.index,
        page: props.pageNumber,
        component: props.isSponsored ? 'native_ads' : component.value,
        id_section: sectionId.value,
        productId: props.item.id,
        product: props.item,
        isSponsoredTile: props.isSponsored,
      };
      eventBus.tryEmit(busEvent, legacyTrackingFallback, busEventDetail);
    };

    const handleVariantAddToCart = (payload) => {
      // Get the product either from the option selected in the variants modal
      //   or the product itself as obtained via the props.
      // The first method was used to handle the event coming from the variants modal
      //   and the second one was used in the QATC component.
      // These should be the same but, I'm not sure the data is equal...
      const product = productByOptionId.value(payload.option.id) || props.item;
      const trackingData = {
        productProperty: createProductProperty({
          id: product.id,
          name: product.name,
          wholesalePrice: product.wholesale_price,
          position: props.index,
          option: payload.option,
        }),
        brandProperty: createBrandProperty(payload.brand),
        page: props.pageNumber,
        quantity: payload.quantity * product.unit_multiplier,
      };
      emit('variantAddedToCart', trackingData);

      if (props.disableTracking) {
        return;
      }
      if (product) {
        const event = DiscoveryTrackingEvents.QUICK_ADD_TO_CART_PRODUCT_ADDED;
        const legacyTrackingFallback = () => {
          trackVariantAddedToCart(trackingData);
        };
        const busEventDetail = {
          ...trackingData,
          position: props.index,
          sectionId: sectionId.value,
        };
        eventBus.tryEmit(event, legacyTrackingFallback, busEventDetail);
      }
    };

    const handleQuickAddToCartClicked = () => {
      emit('quickAddToCartClicked');
      if (props.disableTracking) {
        return;
      }
      const event = DiscoveryTrackingEvents.QUICK_ADD_TO_CART_CLICKED;
      const busEventDetail = {
        page: props.pageNumber,
        productId: props.item.id,
        position: props.index,
        sectionId: sectionId.value,
        isSponsoredTile: props.isSponsored,
        component: props.isSponsored ? 'native_ads' : component.value,
      };
      eventBus.emit(event, busEventDetail);
    };

    const handleProductLiked = () => {
      emit('productLiked');
      if (props.disableTracking) {
        return;
      }
      trackProductLiked({
        product: props.item,
        position: props.index,
        id_brand: props.additionalLikeTrackingData.id_brand,
        id_product: props.additionalLikeTrackingData.id_product,
        isSponsored: props.isSponsored,
      });
    };

    const handleProductDisliked = () => {
      emit('productDisliked');
      if (props.disableTracking) {
        return;
      }
      trackProductDisliked({ product: props.item });
    };

    const handleAdsTooltipClicked = () => {
      emit('ads-tooltip-clicked');
      if (props.isSponsored && !props.disableTracking) {
        trackSponsoredProductTooltipClick({
          product: props.item as unknown as ESSponsoredProductHit,
          page: props.pageNumber,
          position: props.index,
        });
      }
      window.location.assign('/account/brand-dashboard-ads');
    };

    const withSimilarProductsDrawerButton = computed(() => props.withSimilarProductsDrawer && userIsRetailer.value);

    return {
      isHovered,
      eventBus,
      isPreorder,
      createStockAlert,
      isStockAlertLoading,
      productVariantStockAlert,
      getConvertedPrice,
      user,
      userIsRetailer,
      canGetWholesalePrice,
      hasUnhandledCountry,
      retailer,
      productByOptionId,
      userCurrency,
      b2rEligibility,
      welcomeOffer,
      appliedDiscount,
      withSimilarProductsDrawerButton,
      handleProductClick,
      handleBrandClick,
      handleAdsTooltipClicked,
      handleVariantAddToCart,
      handleProductLiked,
      handleProductDisliked,
      handleQuickAddToCartClicked,
    };
  },
  data() {
    return {
      original_wholesale_price: { amount: 0, currency: 'EUR', precision: 0 } as DineroObject,
      wholesale_price: { amount: 0, currency: 'EUR', precision: 0 } as DineroObject,
      retail_price: { amount: 0, currency: 'EUR', precision: 0 } as DineroObject,
      discount_event_price: { amount: 0, currency: 'EUR', precision: 0 } as DineroObject,
      priceBreakdown: {} as PriceBreakdown,
      observer: null,
    };
  },
  computed: {
    hasPriceBreakdown() {
      return Object.keys(this.priceBreakdown).length !== 0;
    },
    brandDiscount(): number | null {
      return this.item.discount_rate ?? getHighestDiscount(this.item as ESProductHit);
    },
    offerDiscount(): number | null {
      const offer = this.sitewideOfferContent['product-tile-offer-badge']?.content;
      if (typeof offer === 'string') {
        return parseInt(offer.replace(/(^-)|(%$)/g, ''));
      }
      return null;
    },
    brandDiscountOrigin(): ProductDiscount {
      return getDiscount(this.brandDiscount, 'brand');
    },
    canHaveFavourite(): boolean {
      return this.user && this.userIsRetailer && this.$isEnabled('like_products_and_brands') && this.withFavoriteBtn;
    },
    canHaveQuickCart(): boolean {
      return isPreorder(this.item)
        ? this.userCurrency === 'EUR' && this.withQuickAddToCart && this.canGetWholesalePrice && !isProductOutOfStock(this.item)
        : this.withQuickAddToCart && this.canGetWholesalePrice && !isProductOutOfStock(this.item);
    },
    sectionId(): string {
      return getSectionId({ sectionType: 'product_snippet', valueProposition: `product_${this.item.id}` });
    },
    isRestockAlertBtnVisible(): boolean {
      return this.withQuickAddToCart && isProductOutOfStock(this.item);
    },
    showQuickAdd(): boolean {
      return (
        (this.isDesktop || this.isXlDesktop) &&
        this.canHaveQuickCart &&
        (!isPreorder(this.item) || (isPreorder(this.item) && this.$isEnabled('oxp-1484-r3')))
      );
    },
  },
  watch: {
    variant() {
      this.update();
    },
    appliedDiscount() {
      this.update();
    },
  },
  created() {
    this.update();
  },
  methods: {
    openQuickAddToCartModal(): void {
      (this.$refs.quickAddToCartModal as DS['AkModal']).openModal();
    },
    async update() {
      let { original_wholesale_price, wholesale_price, retail_price } = this.item;
      if (isProductMultiple(this.item) && this.variant) {
        const priceHolder = (this.variant || this.item.variants[0]).price;
        original_wholesale_price = wholesale_price = priceHolder.wholesale_price;
        retail_price = priceHolder.retail_price;
      }

      this.original_wholesale_price = await this.getConvertedPrice(original_wholesale_price);
      this.retail_price = await this.getConvertedPrice(retail_price);
      this.wholesale_price = await this.getConvertedPrice(wholesale_price);

      this.priceBreakdown = getPriceBreakdown(
        this.original_wholesale_price,
        this.retail_price,
        this.wholesale_price,
        this.brandDiscountOrigin,
        this.appliedDiscount
      );
    },
    onSetStockAlert(): void {
      this.createStockAlert(this.item.id, this.item.brand.id, this.item.variants?.[0]?.id);
    },
  },
});
</script>

<style scoped lang="scss">
@import '@css/vue-import';
.product-snippet {
  $baseClass: &;
  &__qatc {
    $elementWidth: calc(10 / 12 * 100%);
    transition: opacity 100ms;
    width: $elementWidth;
    bottom: 46%;
    position: absolute;
    left: calc(50% - (#{$elementWidth} / 2));
    opacity: 80%;
    &:not(.QATC--with-feedback) {
      @apply ds-hidden;
    }
  }
  &__cta-buttons {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    gap: 0.5rem;
  }
  &__stock-alert {
    @apply ds-hidden ds-relative ds-bottom-1/2 ds-justify-center;
    z-index: $z-index-catalog-card-items;
  }
  &__stock-alert-btn {
    @apply ds-absolute;
  }
  &__stock-alert-btn-mobile {
    @include media-breakpoint-up(xl) {
      @apply ds-hidden;
    }
  }
  &:hover {
    #{$baseClass}__image {
      opacity: 0.15;
    }
    #{$baseClass}__qatc {
      @apply ds-opacity-100;
      @include media-breakpoint-up(md) {
        @apply ds-flex;
      }
    }
    #{$baseClass}__stock-alert {
      @include media-breakpoint-up(xl) {
        @apply ds-flex;
      }
    }
  }
}

.my-list {
  #bestsellers {
    @apply ds-hidden;
  }
}
.checkbox-form {
  top: 1.25rem;
  z-index: 3;
  @apply ds-ml-2 ds-absolute ds-left-4;
  .side-label {
    @apply ds-m-0 ds-pl-0;
    &::before {
      width: 20px !important;
      height: 20px !important;
    }
    &::after {
      @apply ds-w-4 ds-h-4;
    }
  }
  input[type='checkbox']:checked + .side-label::after {
    font-size: 16px;
    top: 4px !important;
  }
}
</style>
