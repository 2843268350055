import {
  PREORDER_CALCULATE_BRANDS_TOTALS,
  PREORDER_CALCULATE_CHECKOUT,
  PREORDER_CHANGE_ITEM_QUANTITY,
  PREORDER_DELETE_ITEM,
  PREORDER_REFRESH_CHECKOUT,
  PREORDER_RESET_BULK_UPDATES,
  PREORDER_SET_CART_LOADING,
  PREORDER_SET_PAYMENT_TERMS,
  PREORDER_SET_TOTALS_LOADING,
  PREORDER_UPDATE_BULK,
} from '@/store/cart-preorder/mutation-types';
import { findIndex } from 'lodash-es';

import { CartPreorderState } from '@/store/cart-preorder';
import { PreorderCartResponseMapped } from '@/types/cart-preorder';
import { getCurrency } from '@/services/metas/currency';
import { PreorderBulkUpdate } from '@/services/api/cart-preorder';

export default {
  [PREORDER_SET_TOTALS_LOADING](state: CartPreorderState, loading = true) {
    state.isTotalsLoading = loading;
  },

  [PREORDER_RESET_BULK_UPDATES](state: CartPreorderState) {
    state.bulkUpdates = [];
  },

  [PREORDER_SET_CART_LOADING](state: CartPreorderState, value: boolean): void {
    state.isCartLoading = value;
  },

  [PREORDER_REFRESH_CHECKOUT](state: CartPreorderState, checkout: PreorderCartResponseMapped) {
    state.preorderCart = checkout;
    state.itemsCounts = {
      main: checkout.order_informations?.order_items_count_main,
    };
  },

  [PREORDER_CALCULATE_BRANDS_TOTALS](state: CartPreorderState) {
    state.preorderCart.carts.forEach((cart) => {
      // Cart amount
      cart.total_amount = {
        amount: cart.items.reduce((total, itemInCart) => total + itemInCart.amount.amount, 0),
        currency: getCurrency(),
      };
      // Cart minimum reached?
      cart.minimum.above = cart.total_amount.amount >= cart.minimum.amount.amount;
    });
  },

  [PREORDER_CALCULATE_CHECKOUT](state: CartPreorderState) {
    state.preorderCart.firstInit = false;

    state.preorderCart.total_amount = {
      amount: state.preorderCart.carts
        .filter((cart) => cart.minimum.above)
        .reduce((total, cart) => total + cart.total_amount.amount, 0),
      currency: getCurrency(),
    };

    // Billing items

    // VAT
    state.preorderCart.total_amount_vat.amount = 0;
    state.preorderCart.total_amount_with_vat.amount = 0;
  },

  [PREORDER_UPDATE_BULK](state: CartPreorderState, update: PreorderBulkUpdate) {
    const preparedUpdates = state.bulkUpdates;

    const existingIndexUpdate = findIndex(preparedUpdates, (preparedUpdate) => {
      if (preparedUpdate.preorderItemId) {
        return preparedUpdate.preorderItemId === update?.preorderItemId;
      } else if (preparedUpdate.productVariantId) {
        return preparedUpdate.productVariantId === update?.productVariantId;
      }
      return false;
    });

    if (update?.preorderItemId === null) {
      // NOTE: item is still adding to the cart
      return;
    }

    if (existingIndexUpdate === -1) {
      preparedUpdates.push(update);
    } else {
      preparedUpdates.splice(existingIndexUpdate, 1, update);
    }
  },

  [PREORDER_DELETE_ITEM](state: CartPreorderState, { brand, item }) {
    const stateCart = state.preorderCart.carts.find((cart) => {
      return cart.brand.id === brand.id;
    });

    if (!stateCart) {
      return;
    }

    stateCart.items = stateCart.items.filter((itemInCart) => itemInCart.id !== item.id);

    if (stateCart.items.length === 0) {
      // Remove empty cart
      state.preorderCart.carts = state.preorderCart.carts.filter((cart) => cart.brand.id !== brand.id);
    }
  },

  [PREORDER_CHANGE_ITEM_QUANTITY](state: CartPreorderState, { brand, item, quantity }) {
    const stateCart = state.preorderCart.carts.find((cart) => cart.brand.id === brand.uuid);

    if (!stateCart) {
      throw new Error(`Cart of brand id ${brand.id} not found in checkout object`);
    }

    const stateItem = stateCart.items.find((itemInCart) => {
      return itemInCart.id === item.id;
    });

    if (!stateItem) {
      throw new Error(`Item ${item.id} not found in cart id ${stateCart.id}`);
    }

    // Calculate displayed data
    stateItem.quantity = quantity; // Quantity
    stateItem.amount.amount = stateItem.quantity * stateItem.unit_price.amount; // Item total amount
  },

  [PREORDER_SET_PAYMENT_TERMS](state: CartPreorderState, paymentTerms: string[]) {
    state.paymentTerms = paymentTerms;
  },
};
