<template>
  <div>
    <div class="upload-file">
      <AkHeading4>
        {{ $t('brand.account.products.csv-upload-modal.upload-import.title') }}
      </AkHeading4>

      <div class="ds-text-base ds-py-2">
        <AkParagraph>
          {{ $t('brand.account.products.csv-upload-modal.upload-import.subtitle1') }}
        </AkParagraph>
        <AkParagraph class=" ds-inline">
          {{ $t('brand.account.products.csv-upload-modal.upload-import.subtitle2') }}
        </AkParagraph>
        <a
          class="ds-underline ds-inline ds-text-info-700"
          target="_blank"
          :href="$t('brand.account.products.csv-upload-modal.upload-import.learn-more-link')"
        >
          {{ $t('brand.account.products.csv-upload-modal.upload-import.learn-more') }}
        </a>
      </div>

      <div
        v-show="!file || readingFile"
        class="upload-file__drop-zone"
        :class="{ 'drag-is-over': dragIsOver }"
        @drop.prevent="fileDropped"
        @dragover.prevent="dragIsOver = true"
        @dragenter.prevent="dragIsOver = true"
        @dragleave.prevent="dragIsOver = false"
      >
        <input
          v-show="false"
          id="file-input"
          ref="file-input"
          type="file"
          accept=".csv, .xls, .xlsx, .tsv, .ods"
          @change="fileSelected($event)"
        />
        <label for="file-input">
          <div
            v-if="readingFile"
            class="ds-mt-5"
          ><LoaderIcon /></div>
          <span v-else-if="dragIsOver">
            {{ $t('Drop your files here') }}
          </span>
          <div v-else>
            <AkIcon
              symbol="download"
              size="md"
            />
            <AkParagraph class="ds-underline">{{ $t('Drag and drop the file here') }}</AkParagraph>
            <AkParagraph class="ds-text-neutral-700"> {{ $t('Choose file') }}</AkParagraph>
          </div>
        </label>
      </div>

      <div
        v-if="fileUploadLoading"
        class="ds-mt-5"
      >
        <LoaderIcon />
      </div>

      <template v-else-if="file && !readingFile">
        <div class="upload-file__filename">
          <div class="ds-flex ds-items-center">
            <AkIcon
              symbol="file-earmark"
              size="md"
            />
            <p>{{ file.name }}</p>
          </div>
          <button @click="deleteFile">
            <AkIcon
              symbol="trash"
              size="md"
            />
          </button>
        </div>
        <div
          v-if="sheets"
          class="upload-file__select-sheet"
        >
          <AkParagraph>
            {{ $t('Select the sheet with your products') }}
          </AkParagraph>
          <AkSelect
            id="select-sheet"
            size="lg"
            :options="sheetOptions"
            :placeholder="$t('Select a sheet')"
            :value="selectedSheet"
            @change="(item) => onSheetSelected(item)"
          />
        </div>
      </template>
      <AkToaster
        ref="errorToaster"
        type="error"
        :show-symbol="false"
        :duration="5000"
      >
        {{ $t('Error') }}
      </AkToaster>
    </div>
    <div class="upload-file-banner">
      <div class="upload-file-banner__content">
        <AkParagraph
          size="md"
        >
          {{ $t('brand.account.products.csv-upload-modal.upload-import.banner.title') }}
        </AkParagraph>
        <img
          class="upload-file-banner__image"
          src="/images/account/account-products/csv-logos.svg"
        />
        <AkParagraph>
          {{ $t('brand.account.products.csv-upload-modal.upload-import.banner.text1') }} <a
            class="ds-underline ds-cursor-pointer ds-text-info-700"
            target="_blank"
            :href="$t('brand.account.products.csv-upload-modal.upload-import.banner.article-link')"
          >{{ $t('brand.account.products.csv-upload-modal.upload-import.banner.article') }}</a> {{ $t('brand.account.products.csv-upload-modal.upload-import.banner.text2') }}
        </AkParagraph>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import { AkParagraph, AkSelect, AkToaster } from '@ankorstore/design-system';
import LoaderIcon from '@/components/loader-icon.vue';
import { read, utils, type WorkSheet } from 'xlsx';
import { selectOption } from '@/types/brand-onboarding';
import { uploadFile } from '@/services/api/upload-file';
import { ImportFileType } from '@/types/account/account-products/catalog-integration/product-integration';

export default defineComponent({
  name: 'CsvUploadImport',
  components: {
    LoaderIcon,
    AkParagraph,
    AkSelect,
    AkToaster,
  },
  emits: ['fileUploaded'],
  data() {
    return {
      dragIsOver: false,
      file: null,
      sheets: null,
      selectedSheet: null,
      readingFile: false,
      workbook: null,
      worksheet: null,
      fileUploadLoading: false,
      overwriteDrafts: false,
      fileKey: '',
      productsCount: 0,
    };
  },
  computed: {
    sheetOptions(): selectOption[] {
      return this.sheets?.map((sheet, index) => {
        return { value: index, label: sheet };
      });
    },
  },
  methods: {
    onSheetSelected(sheetNumber: number): void {
      this.selectedSheet = sheetNumber;
      this.emitSheetData();
    },
    isValidFileType(file: File): boolean {
      const fileType = file?.name?.split('.').pop();
      return ['csv', 'xls', 'xlsx', 'tsv', 'ods'].includes(fileType);
    },
    deleteFile(): void {
      this.file = null;
      this.sheets = null;
      this.selectedSheet = null;
      (this.$refs['file-input'] as HTMLInputElement).value = null;
      this.$emit('fileUploaded', { rows: [], overwriteDrafts: false, fileName: '', fileType: '', sheetIndex: 0, fileKey: '' });
    },
    fileDropped(event: DragEvent): void {
      this.dragIsOver = false;
      this.file = event.dataTransfer.files[0];
      this.handleFile();
    },
    fileSelected(event: Event): void {
      this.file = (event.target as HTMLInputElement).files[0];
      this.handleFile();
    },
    async handleFile(): Promise<void> {
      if (!this.isValidFileType(this.file)) {
        this.deleteFile();
        return;
      }
      this.readingFile = true;
      const uploadedFile = await uploadFile(this.file);
      if (!uploadedFile?.key) {
        (this.$refs.errorToaster as DS['AkToaster']).openToaster();
        this.readingFile = false;
        this.deleteFile();
        return;
      } else {
        this.fileKey = uploadedFile.key;
      }

      await this.readFile();
      this.emitSheetData();
    },
    readFile(): Promise<void> {
      this.readingFile = true;
      return new Promise((resolve) => {
        const reader = new FileReader();
        reader.onloadend = (e: ProgressEvent<FileReader>) => resolve(this.readSheets(e));
        reader.readAsArrayBuffer(this.file);
      });
    },
    readSheets(e: ProgressEvent<FileReader>): void {
      const data = new Uint8Array(e.target.result as ArrayBufferLike);
      const workbook = read(data, { type: 'array' });
      this.workbook = workbook;
      this.readingFile = false;
      this.selectedSheet = 0;
      if (workbook.SheetNames.length > 1) {
        this.sheets = workbook.SheetNames;
      }
    },
    emitSheetData(): void {
      const sheetName = this.workbook?.SheetNames?.[this.selectedSheet];
      const worksheet = this.workbook?.Sheets?.[sheetName];
      const rows = this.readRows(worksheet);
      const fileName = this.file?.name;
      const fileType = fileName?.split('.').pop() as ImportFileType;
      const sheetIndex = this.selectedSheet;
      const overwriteDrafts = this.overwriteDrafts;
      this.$emit('fileUploaded', { rows, overwriteDrafts, fileName, fileType, sheetIndex, fileKey: this.fileKey, productsCount: this.productsCount });
    },
    readRows(worksheet: WorkSheet): (string | number)[][] {
      if (!worksheet) {
        return null;
      }
      const rows = utils.sheet_to_json(worksheet, {
        raw: false,
        range: 0,
        header: 1,
      }) as (string | number)[][];
      this.productsCount = rows.length - 1;
      return rows.slice(0, 3);
    },
  },
});
</script>

<style scoped lang="scss">
@import '@css/vue-import';
.upload-file {
  @apply ds-text-center ds-mx-auto ds-max-w-4xl;
  &__drop-zone {
    height: 160px;
    transition: all 0.3s ease;
    @apply ds-flex ds-items-center ds-justify-center ds-relative ds-mt-5;
    label {
      @apply ds-border ds-border-dashed ds-border-neutral-500 ds-rounded-md ds-cursor-pointer ds-flex ds-flex-col ds-h-full ds-w-full ds-mb-0 ds-justify-center;
      span {
        @apply ds-text-sm ds-py-3 ds-py-2;
      }
      &.loading {
        @apply ds-opacity-50;
      }
    }
    &.drag-is-over {
      @apply ds-bg-warm-white;
    }
  }
  &__filename {
    @apply ds-mt-5 ds-p-2 ds-flex ds-justify-between ds-bg-neutral-100;
    p {
      @apply ds-mb-0 ds-mt-0.5 ds-ml-2 ds-text-base;
    }
    &.loading {
      @apply ds-opacity-50;
    }
  }
  &__select-sheet {
    p {
      @apply ds-mb-2 ds-mt-5 ds-text-left;
    }
  }
}

  .upload-file-banner {
    @apply ds-relative ds-px-9 ds-py-6 ds-bg-neutral-100 ds-mt-7;
    &:before {
      content: '';
      bottom: -100rem;
      @apply ds-absolute ds-w-screen ds-bg-neutral-100 ds-left-1/2 ds--translate-x-1/2 ds-top-0;
    }

    &__image {
      @apply ds-py-3 ds-px-5 ds-my-4 ds-mx-auto ds-h-auto ds-bg-white;
    }

    &__content {
      @apply ds-relative;
    }
  }
</style>
