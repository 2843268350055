<template>
  <div>
    <AkModal
      ref="gsheetModal"
      size="full"
      full-height
      full-height-sticky-footer
      :back-navigation="currentStepIndex > 0"
      :close-on-validation="false"
      class="gsheet-modal"
      :validate-button-text="currentStep.nextBtnText"
      :validate-button-disabled="nextButtonDisabled"
      :validate-button-loading="nextButtonLoading"
      :secondary-button-text="currentStep.prevBtnText"
      @back="handleBack"
      @cancelled="closeModal"
      @validated="handleNext"
      @secondary-button-clicked="handleBack"
    >
      <div>
        <div class="ds-px-8 ds-pt-3">
          <AkSteps
            class="ds-mt-6"
            :current-step="currentStepIndex + 1"
            :steps="steps"
          />
          <div class="gsheet-modal__content">
            <GsheetSetup
              v-show="currentStep.name === GsheetModalStepName.Setup"
              class="gsheet-modal__step-content"
              :import-scope="importScope"
              :images-import-method="imagesImportMethod"
              :catalogue-export-loading="catalogueExportLoading"
              @change-import-scope="changeImportScope"
              @change-images-import-method="changeImagesImportMethod"
            />
            <GsheetDataEntry
              v-show="currentStep.name === GsheetModalStepName.DataEntry"
              class="gsheet-modal__step-content"
              :is-images-folder-displayed="imagesImportMethod === ImagesImportMethod.Upload"
              :is-banner-displayed="hasInconsistencyWarning"
              @start-over="onStartOver"
              @updated="gsheetUpdated"
            />
          </div>
        </div>
      </div>
    </AkModal>
  </div>
</template>
<script lang="ts">
import { ref, computed, defineComponent, watch, Ref, ComputedRef } from 'vue';
import GsheetSetup from './gsheet-setup.vue';
import GsheetDataEntry from './gsheet-data-entry.vue';
import { AkSteps, AkStepsStep } from '@ankorstore/design-system';
import { GsheetModalStepName, ImagesImportMethod, ImportScope } from '@bc/brands/feature/catalog-integration/types/gsheet.types';
import { ExportType } from '@bc/brands/feature/catalog-integration/types/catalog-integration.types';
import { busEvents as catalogIntegrationBusEvents } from '../../constants';
import useI18n from '@/composables/use-i18n';
import { useEventBus } from '@bc/shared';
import { useStore } from '@/composables/use-store';
import Analytics from '@/services/analytics';
import UserClick from '@/services/analytics/events/user-click.event';
import { Brand } from '@/types/api/brand';
import { GoogleBatchPayload } from '@/types/account/account-products/catalog-integration/product-integration';
import { useLocalStorage } from '@/composables/use-storage';

interface GsheetModalStep extends AkStepsStep {
  name: GsheetModalStepName;
  title: string;
  completed: boolean;
  nextBtnText?: string;
  prevBtnText?: string;
}

export default defineComponent({
  name: 'GsheetModal',
  components: {
    GsheetSetup,
    GsheetDataEntry,
    AkSteps,
  },
  emits: ['importStarted'],
  setup(_, { emit }) {
    const localStorage = useLocalStorage();
    const i18n = useI18n();
    const currentStepIndex = ref(0);
    const nextButtonLoading = ref(false);
    const store = useStore();
    const brand: Ref<Brand> = computed(() => store.getters['brand']);

    const imagesImportMethod: Ref<ImagesImportMethod | null> = ref(localStorage.getStorageItem('imagesImportMethod') || null);

    const importSpreadsheet: Ref<string> = computed(
      () => store.getters['account/productsIntegration/getSettings']?.generated_google_spreadsheet?.data_sheet_url
    );
    const driveFolderUrl: Ref<string> = computed(
      () => store.getters['account/productsIntegration/getSettings']?.images_google_folder_url
    );
    const catalogIntegrationUUID: ComputedRef<string> = computed(() => store.getters.brand?.catalog_integration_uuid);
    const removeExistingSpreadsheet = async () => {
      await store.dispatch('account/productsIntegration/removeExistingSpreadsheet', catalogIntegrationUUID.value);
    };
    const generateNewSpreadsheet = async () => {
      await store.dispatch('account/productsIntegration/generateNewSpreadsheet', catalogIntegrationUUID.value);
    };
    const exportCatalogueToSpreadsheet = async () => {
      await store.dispatch('account/productsIntegration/exportCatalogue', ExportType.SPREADSHEET);
    };
    const importFromSpreadsheet = async (payload: GoogleBatchPayload) => {
      await store.dispatch('account/productsIntegration/importFromSpreadsheet', payload);
    };
    const fetchSettings = async () => {
      await store.dispatch('account/productsIntegration/fetchSettings', catalogIntegrationUUID.value);
    };

    const isImportInProgress: ComputedRef<boolean> = computed(
      () => store.getters['account/productsIntegration/isImportInProgress']
    );
    const isExportInProgress: ComputedRef<boolean> = computed(
      () => store.getters['account/productsIntegration/isExportInProgress']
    );

    watch(isExportInProgress, (newVal, oldVal) => {
      if (!newVal && oldVal) {
        currentStepIndex.value = currentStepIndex.value + 1;
      }
    });

    const changeImagesImportMethod = (val: ImagesImportMethod) => {
      imagesImportMethod.value = val;
      localStorage.setStorageItem('imagesImportMethod', val);
    };

    const importScope: Ref<ImportScope | null> = ref(localStorage.getStorageItem('importScope') || null);

    const changeImportScope = (val: ImportScope) => {
      importScope.value = val;
      localStorage.setStorageItem('importScope', val);
    };

    const gsheetModal: Ref<DS['AkModal']> = ref(null);
    const openModal = () => gsheetModal.value?.openModal();
    const closeModal = () => gsheetModal.value?.close();

    const redirectToStep = async () => {
      if (isExportInProgress.value || !importSpreadsheet.value) {
        currentStepIndex.value = 0;
      } else {
        await fetchSettings();
        importScope.value = localStorage.getStorageItem('importScope') || ImportScope.NewAndExistingProducts;
        imagesImportMethod.value = localStorage.getStorageItem('imagesImportMethod') || ImagesImportMethod.Upload;
        hasPossibleInconsistency.value = true;
        currentStepIndex.value = 1;
      }
    };

    const { eventBus } = useEventBus();
    eventBus.on(catalogIntegrationBusEvents.GsheetModalOpen, () => {
      redirectToStep();
      openModal();
    });
    eventBus.on(catalogIntegrationBusEvents.GsheetModalClose, () => closeModal());

    const steps: ComputedRef<GsheetModalStep[]> = computed(() => [
      {
        name: GsheetModalStepName.Setup,
        title: i18n.t('brands.catalogIntegration.gsheet.gsheetModal.setup.title'),
        completed: currentStepIndex.value > 0,
        nextBtnText: i18n.t('brands.catalogIntegration.gsheet.gsheetModal.setup.linked.validateButtonText'),
      },
      {
        name: GsheetModalStepName.DataEntry,
        title: i18n.t('brands.catalogIntegration.gsheet.gsheetModal.dataEntry.title'),
        completed: currentStepIndex.value > 1,
        nextBtnText: i18n.t('brands.catalogIntegration.gsheet.gsheetModal.dataEntry.validateButtonText'),
      },
    ]);

    const currentStep = computed(() => steps.value[currentStepIndex.value]);

    const nextButtonDisabled = computed(() => {
      if (nextButtonLoading.value || !importScope.value || !imagesImportMethod.value || isExportInProgress.value) {
        return true;
      }
      return false;
    });

    const handleBack = () => {
      switch (currentStep.value.name) {
        case GsheetModalStepName.Setup:
          closeModal();
          break;
        case GsheetModalStepName.DataEntry:
          currentStepIndex.value = currentStepIndex.value - 1;
          break;
      }
    };

    const startImportFromSpreadsheet = async () => {
      await importFromSpreadsheet({
        payload: {
          spreadsheet_url: importSpreadsheet.value,
          drive_url: driveFolderUrl.value,
        },
      });
    };

    const handleNext = async () => {
      nextButtonLoading.value = true;
      if (currentStep.value.name === GsheetModalStepName.DataEntry) {
        await startImportFromSpreadsheet();
        emit('importStarted');
        Analytics.track(
          new UserClick({
            component: 'brand_bo_catalogue_management',
            action: 'synchronise_catalogue',
            type: importScope.value === ImportScope.NewProductsOnly ? 'gsheet_new_products' : 'gsheet_update_products',
            images: imagesImportMethod.value === ImagesImportMethod.Upload ? 'from_folder' : 'from_link',
            brand_id: brand.value.id,
            brand_name: brand.value.name,
          })
        );
        closeModal();
      } else if (currentStep.value.name === GsheetModalStepName.Setup) {
        if (importScope.value === ImportScope.NewProductsOnly) {
          await removeExistingSpreadsheet();
          await generateNewSpreadsheet();
          currentStepIndex.value = currentStepIndex.value + 1;
        } else if (importScope.value === ImportScope.NewAndExistingProducts) {
          await exportCatalogueToSpreadsheet();
        }
        hasPossibleInconsistency.value = false;
        fetchSettings();
        Analytics.track(
          new UserClick({
            component: 'brand_bo_catalogue_management',
            action: 'create_your_sheet',
            type: importScope.value === ImportScope.NewProductsOnly ? 'gsheet_new_products' : 'gsheet_update_products',
            images: imagesImportMethod.value === ImagesImportMethod.Upload ? 'from_folder' : 'from_link',
            brand_id: brand.value.id,
            brand_name: brand.value.name,
          })
        );
      }
      nextButtonLoading.value = false;
    };

    const hasPossibleInconsistency = ref(true);

    const hasInconsistencyWarning: ComputedRef<boolean> = computed(() => {
      return hasPossibleInconsistency.value && !!importScope.value;
    });

    const onStartOver = async () => {
      currentStepIndex.value = 0;
      Analytics.track(
        new UserClick({
          component: 'brand_bo_catalogue_management',
          action: 'start_over_different_set_up',
          brand_id: brand.value.id,
          brand_name: brand.value.name,
        })
      );
    };

    const catalogueExportLoading = ref(false);

    const gsheetUpdated = async () => {
      exportCatalogueToSpreadsheet()
        .then(() => {
          catalogueExportLoading.value = true;
          currentStepIndex.value = 0;
        })
        .finally(() => {
          hasPossibleInconsistency.value = false;
          catalogueExportLoading.value = false;
        });
    };

    return {
      currentStepIndex,
      nextButtonLoading,
      imagesImportMethod,
      currentStep,
      nextButtonDisabled,
      handleBack,
      handleNext,
      openModal,
      closeModal,
      gsheetUpdated,
      steps,
      changeImagesImportMethod,
      changeImportScope,
      ImagesImportMethod,
      GsheetModalStepName,
      gsheetModal,
      onStartOver,
      importScope,
      removeExistingSpreadsheet,
      generateNewSpreadsheet,
      isExportInProgress,
      isImportInProgress,
      driveFolderUrl,
      redirectToStep,
      hasInconsistencyWarning,
      catalogueExportLoading,
    };
  },
});
</script>

<style lang="scss" scoped>
.gsheet-modal__content {
  @apply ds-mt-6;
}

.gsheet-modal__step-content {
  @apply ds-mx-auto ds-max-w-4xl;
}
</style>
