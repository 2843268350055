import { isEnabled } from '@/services/features';
import i18n from '@/services/i18n';
import * as api from './api';
import { memoize } from 'lodash-es';
import { RecommendationAlgoType } from '@/types/analytics/recommendation-tracking';
import http from '@/services/api/http';
import Product from '@/types/product';
import { Brand } from '@/types/api/brand';
import { Recommendations } from '@bc/discovery/feature/recommendation/types';
import { PreferredCategoriesResponse, PreferredCategory } from '@bc/discovery/feature/recommendation/types';


export const getRelatedBrands = async (brandId: number | string) => {
  const valueProposition = i18n.global.t('Brands you may like');

  if (!isEnabled('RET-2477')) {
    return {
      brands: await api.getInternalLinkingRelatedBrands(brandId),
      algorithm: 1,
      valueProposition,
    };
  }

  return {
    brands: await api.getRelatedBrands(brandId),
    algorithm: undefined,
    valueProposition,
  };
};

const getEntitiesForRecommendationAlgo = (
  data: { id: number; entities: Brand[] | Product[] }[],
  algo: RecommendationAlgoType
): Product[] | Brand[] => {
  return data?.find((product) => product.id === algo)?.entities ?? [];
};

export const getRecommendations = memoize(async (types: RecommendationAlgoType[]): Promise<Recommendations> => {
  const params: string = types.reduce((acc, t, i) => acc + `${i === 0 ? '' : '&'}types[${i}][id]=${t}`, '');
  const response = await http().get(`/api/me/recommendations?${params}`, {
    noProgressBar: true,
  });
  const recommendations = {} as Recommendations;
  if (types.includes(RecommendationAlgoType.productsYouMayLike)) {
    recommendations.products = getEntitiesForRecommendationAlgo(
      response.data?.data,
      RecommendationAlgoType.productsYouMayLike
    ) as Product[];
  }
  if (types.includes(RecommendationAlgoType.brandYouMayLike)) {
    recommendations.brands = getEntitiesForRecommendationAlgo(
      response.data?.data,
      RecommendationAlgoType.brandYouMayLike
    ) as Brand[];
  }
  if (types.includes(RecommendationAlgoType.brandsCloseToYourArea)) {
    recommendations.brandsNear = getEntitiesForRecommendationAlgo(
      response.data?.data,
      RecommendationAlgoType.brandsCloseToYourArea
    ) as Brand[];
  }
  return recommendations;
});

export const getSimilarProductsFromCrossOrSameBrands = async (
  productId,
  types: RecommendationAlgoType[]
): Promise<Pick<Recommendations, 'productsSameBrands' | 'productsCrossBrands'>> => {
  const params: string = types.reduce((acc, t, i) => acc + `${i === 0 ? '' : '&'}types[${i}][id]=${t}`, '');
  const response = await http().get(`/api/product/${productId}/recommendations?${params}`, {
    noProgressBar: true,
  });
  const recommendations = {} as Recommendations;
  if (types.includes(RecommendationAlgoType.ProductsSameBrands)) {
    recommendations.productsSameBrands = getEntitiesForRecommendationAlgo(
      response.data?.data,
      RecommendationAlgoType.ProductsSameBrands
    ) as Product[];
  }
  if (types.includes(RecommendationAlgoType.productsCrossbrands)) {
    recommendations.productsCrossBrands = getEntitiesForRecommendationAlgo(
      response.data?.data,
      RecommendationAlgoType.productsCrossbrands
    ) as Product[];
  }
  return recommendations;
};

export const getProductsPageRecommendations = memoize(getSimilarProductsFromCrossOrSameBrands);

export const getAllRecommendations = memoize(async () => {
  return await getRecommendations([
    RecommendationAlgoType.brandYouMayLike,
    RecommendationAlgoType.productsYouMayLike,
    RecommendationAlgoType.brandsCloseToYourArea,
  ]);
});

export const productsPageRecommendations = memoize(async (productId) => {
  return await getProductsPageRecommendations(productId, [
    RecommendationAlgoType.ProductsSameBrands,
    RecommendationAlgoType.productsCrossbrands,
  ]);
});

export const getProductsRecommendations = memoize(async () => {
  return await getRecommendations([RecommendationAlgoType.productsYouMayLike]);
});

export const getBrandsRecommendations = memoize(async () => {
  return await getRecommendations([RecommendationAlgoType.brandYouMayLike]);
});

export const getNewBrandsCategoryRecommendations = memoize(async () => {
  const response = await http().get(`api/me/recommendations/new-brands?types[0][id]=${RecommendationAlgoType.newBrandsCategory}`, { noProgressBar: true });
  return {
    brands: getEntitiesForRecommendationAlgo(response.data?.data, RecommendationAlgoType.newBrandsCategory) as Brand[],
  }
});

export const getProductsSameBrands = memoize(async () => {
  return await getRecommendations([RecommendationAlgoType.ProductsSameBrands]);
});

export const getProductsCrossBrands = memoize(async () => {
  return await getRecommendations([RecommendationAlgoType.productsCrossbrands]);
});

export const getCategoriesRecommendations = memoize(async (categoryId: number): Promise<Pick<Recommendations, 'products'>> => {
  const response = await http().get(`/api/me/recommendations/category/${categoryId}`, { noProgressBar: true });
  return {
    products: getEntitiesForRecommendationAlgo(response.data?.data, RecommendationAlgoType.productsCategories) as Product[],
  };
});

export const getPreferredCategoriesRecommendations = memoize(async (): Promise<PreferredCategory[]> => {
  const response = await http().get<PreferredCategoriesResponse>(`/api/me/recommendations/preferred-categories`, {
    noProgressBar: true,
  });
  return response.data?.data ?? [];
});
