<template>
  <div class="expandable-filter">
    <div
      :class="{
        'expandable-filter__header': true,
        'expandable-filter__header--open': isFilterOpen,
      }"
      data-testid="expandable-filter-header"
      @click="toggleOpen"
    >
      <div class="expandable-filter__header-name">
        <AkPill
          v-if="count"
          class="expandable-filter__header-pill"
          :content="count"
          :active="true"
          color="success-100"
          text-color="accent-700"
        />
        <FilterHeading
          class="expandable-filter__header-title"
          @click.stop="toggleOpen"
        >
          <template #badgeBefore>
            <AnkorstorePlusBadge v-if="badgeType === 'aks_plus'" />
          </template>
          <template #title>
            <span>
              {{ title }}
              <span v-if="unit"> ({{ unit }})</span>
            </span>
            <img
              v-if="badgeType === 'aks_plus'"
              class="expandable-filter__aks-plus-logo"
              src="/images/logo/ankorstore-plus.svg"
              :alt="$t('ankorstorePlus.program.name')"
            />
            <span
              v-if="hasBetaLabel"
              class="expandable-filter__beta-label"
            >
              {{ $t('retailer.filters.attributes.beta.lbl') }}
            </span>
          </template>
          <template #tooltip>
            <AksPlusTooltip
              v-if="badgeType === 'aks_plus'"
              class="expandable-filter__aks-plus-tooltip"
              :title="title"
            />
          </template>
        </FilterHeading>
      </div>
      <AkButton
        :symbol="toggleIcon"
        symbol-position="right"
        color="white"
        size="md"
      />
    </div>
    <AkExpander
      :open="isFilterOpen"
      :keep-alive="true"
      class="expandable-filter__content"
    >
      <slot />
    </AkExpander>
  </div>
</template>

<script lang="ts">
import { defineComponent, watch, ref, computed, PropType } from 'vue';
import { AkPill, AkButton, AkExpander } from '@ankorstore/design-system';
import FilterHeading from './filter-heading.vue';
import AnkorstorePlusBadge from '@/modules/promote-engage/subscription-program/UI/ankorstore-plus-badge.vue';
import { AksPlusTooltip } from '@bc/loyalty';

export default defineComponent({
  name: 'ExpandableFilter',
  components: {
    AkPill,
    AkButton,
    AkExpander,
    FilterHeading,
    AnkorstorePlusBadge,
    AksPlusTooltip,
  },
  props: {
    count: {
      type: Number,
      default: 0,
    },
    title: {
      type: String,
      default: '',
    },
    unit: {
      type: String,
      default: '',
    },
    isOpen: {
      type: Boolean,
      default: false,
    },
    badgeType: {
      type: String as PropType<'aks_plus'>,
      default: undefined,
    },
    hasBetaLabel: {
      type: Boolean,
      default: false,
    },
  },
  setup(props) {
    const isFilterOpen = ref(false);
    const toggleIcon = computed(() => {
      return isFilterOpen.value ? 'chevron-up' : 'chevron-down';
    });

    watch(
      () => props.isOpen,
      (isOpen) => {
        isFilterOpen.value = isOpen;
      },
      { immediate: true }
    );

    const toggleOpen = () => {
      isFilterOpen.value = !isFilterOpen.value;
    };

    return {
      isFilterOpen,
      toggleIcon,
      toggleOpen,
    };
  },
});
</script>

<style scoped lang="scss">
@use '@css/abstracts/design-tokens' as d;
.expandable-filter {
  // block
  height: max-content;
  border-bottom: 1px solid theme('colors.neutral.300');

  // element
  &__header {
    transition: padding 0.15s linear;
    display: flex;
    width: 100%;
    padding: 1.5rem 0;
    justify-content: space-between;
    cursor: pointer;
    &--open {
      padding-bottom: 1rem;
    }
  }

  &__header-name {
    display: flex;
    flex: 100%;
    align-items: center;
  }

  &__header-title {
    display: flex;
    flex: 100%;
    align-items: baseline;
  }

  & &__header-pill {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 0.5rem;
    aspect-ratio: 1;
    height: 1.5rem;
  }

  &__aks-plus-logo {
    display: inline-block;
    width: 1.5rem;
    height: 1.5rem;
  }

  &__aks-plus-tooltip {
    height: 100%;
  }

  &__beta-label {
    margin-left: auto;
    @include d.text('sm');
    color: theme('colors.info.700');
    font-weight: bold;
  }

  &__content {
    padding-bottom: 1.5rem;
  }
}
</style>

<style lang="scss">
.ak-tooltip--tooltip-aks-plus.ak-tooltip .v-popper__inner {
  max-width: 272px;
}

.expandable-filter .ak-expander .ak-expander__inner {
  display: block;
}
</style>
