<template>
  <AkAlert
    type="success"
    class="missing-eori-submitted"
  >
    <AkParagraph
      class="missing-eori-submitted__heading"
      size="sm"
    >
      {{ $t('Thank you for the confirmation') }}
    </AkParagraph>

    <AkParagraph class="missing-eori-submitted__text">
      {{ $t('Upon receipt of your EORI number, please add it in the “Settings” section of your account.') }}
    </AkParagraph>

    <a href="/account/settings">
      <AkButton
        class="missing-eori-submitted__cta"
        link
        @on-click="onAddEori"
      >
        {{ $t('Add my EORI number now') }}
      </AkButton>
    </a>
    <a
      :href="faqLink"
      target="_blank"
      rel="noreferrer"
    >
      <AkButton
        link
        @on-click="onNavigateToHelpDesk"
      >
        {{ $t('Need help? Feel free to reach out.') }}
      </AkButton>
    </a>
  </AkAlert>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import { AkAlert, AkButton, AkParagraph } from '@ankorstore/design-system';
import { getGlobalConfig } from '@/services/global-config';

export default defineComponent({
  name: 'BannerMissingEoriSubmitted',
  components: { AkAlert, AkButton, AkParagraph },
  emits: ['addEori', 'navigateToHelpDesk'],
  data() {
    return {
      faqLink: getGlobalConfig().language_config.contact_form_link,
    };
  },
  methods: {
    onAddEori: function () {
      this.$emit('addEori');
    },
    onNavigateToHelpDesk: function () {
      this.$emit('navigateToHelpDesk');
    },
  },
});
</script>

<style scoped lang="scss">
@import '@css/vue-import';
.missing-eori-submitted {
  button {
    @apply ds-block;
  }
  .missing-eori-submitted__heading {
    @apply ds-mb-2 ds-font-bold ds-text-success-700;
  }
  .missing-eori-submitted__text {
    @apply ds-mb-1 ds-text-sm;
  }
  .missing-eori-submitted__cta {
    @apply ds-mb-2 ds-mt-2;
  }
}
</style>
