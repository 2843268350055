import { memoize } from 'lodash-es';
import http from '@/services/api/http';
import type { AttributeDefinition, AttributeDefinitionGroup } from '../../types';
import type { Context } from '../../types/catalog';
import type { CatalogFacet } from '../../types/catalog-facet';
import type { SearchRegionsResponse } from './types';

export const getRegions = async () => {
  const {
    data: { data },
  } = await http().get('/api/search/regions');

  return data as SearchRegionsResponse;
};

export const getAttributes = memoize(
  async (categoryId: string, lang: string) => {
    const url = `/api/categories/${categoryId}/attributes?lang=${lang}`;
    const response = await http().get<{ data: (AttributeDefinition | AttributeDefinitionGroup)[] }>(url, {
      noProgressBar: true,
    });

    return response?.data?.data;
  },
  (categoryId: string, lang: string) => `${categoryId}-${lang}`
);

export const getFacets = memoize(
  async (
    context: Context,
    entityType: string,
    categoryId: number,
    lang: string
  ): Promise<Array<CatalogFacet | AttributeDefinition>> => {
    type ApiResponse = { data: CatalogFacet[] };

    const path = '/api/catalog/attributes-and-facets';
    const params = new URLSearchParams({
      lang,
      entityType,
      context,
    });

    if (Number.isInteger(categoryId)) {
      params.append('parameters[categoryId]', categoryId.toString());
    }

    const url = `${path}?${params.toString()}`;

    const response = await http().get<ApiResponse>(url, { noProgressBar: true });

    if (entityType === 'brand') {
      // Filter out non-catalog facets for brands. We do not support category specific attributes for brands (yet)
      return response?.data?.data.filter((facet) => facet.type === 'catalog_facet');
    }

    return response?.data?.data;
  },
  (context: Context, entityType: string, categoryId: number, lang: string) => `${context}-${entityType}-${categoryId}-${lang}`
);
