<script lang="ts">
import { defineComponent, PropType, toRefs, computed } from 'vue';
import CatalogProductTile from './catalog-product-tile.vue';
import BrandSnippet from '@/components/brand/brand-snippet.vue';
import PromotionTile from '@/modules/promote-engage/promotion-tile/promotion-tile.vue';
import { Config, Context } from '@bc/discovery/domain/catalog';
import { useEventBus } from '@bc/shared';
import useCatalogTrackingData from '../../composables/use-catalog-tracking-data';
import type { ESProductHit, ESBrandHit, ESSponsoredProductHit } from '@bc/discovery/domain/catalog';
import { DiscoveryTrackingEvents } from '@bc/discovery/domain/tracking';
import { PromotionTileType, type SpecialTileGlobal } from '@/modules/promote-engage/promotion-tile/types';

export default defineComponent({
  name: 'CatalogTile',
  components: { CatalogProductTile, BrandSnippet, PromotionTile },
  props: {
    config: {
      type: Object as PropType<Config>,
      required: true,
    },
    item: {
      type: Object as PropType<ESProductHit | ESBrandHit | ESSponsoredProductHit | SpecialTileGlobal>,
      required: true,
    },
    page: {
      type: Number,
      required: true,
      default: 0,
    },
    index: {
      type: Number,
      required: true,
    },
    isSponsored: {
      type: Boolean,
      default: false,
    },
  },
  setup(props) {
    const { config, item, page, index, isSponsored } = toRefs(props);
    const { eventBus } = useEventBus();
    const { trackingSectionId } = useCatalogTrackingData();

    const isSponsoredProductTile = computed(
      () => isSponsored.value || 'aardvarkServeId' in item.value || ('ad' in item.value && Boolean(item.value.ad))
    );

    const handleProductTileClicked = (event?: Event): void => {
      const payload = {
        page: page.value + 1,
        position: index.value,
        product: productHit.value,
      };
      if (isSponsoredProductTile.value) {
        eventBus.emit(DiscoveryTrackingEvents.TILE_SPONSORED_PRODUCT_CLICKED, {
          ...payload,
          eventTarget: event?.target,
        });
      } else {
        eventBus.emit(DiscoveryTrackingEvents.TILE_PRODUCT_CLICKED, payload);
      }
    };

    const handleBrandTileClicked = ({ brand }: { brand: ESBrandHit }): void => {
      eventBus.emit(DiscoveryTrackingEvents.TILE_BRAND_CLICKED, {
        page: page.value + 1,
        brandId: brand.id,
        position: index.value,
        item: brandHit.value,
      });
    };

    const handleEventTileClicked = (): void => {
      eventBus.emit(DiscoveryTrackingEvents.TILE_EVENT_CLICKED, {
        page: page.value + 1,
        position: index.value,
        item: promotionHit.value,
      });
    };

    const isPromotionHit = (
      hit: ESProductHit | ESBrandHit | ESSponsoredProductHit | SpecialTileGlobal
    ): hit is SpecialTileGlobal => 'type' in hit && hit.type === PromotionTileType.SpecialTile2;
    const isProductHit = (hit: ESProductHit | ESBrandHit | ESSponsoredProductHit | SpecialTileGlobal): hit is ESProductHit => {
      return 'brand' in hit;
    };
    const isBrandHit = (hit: ESProductHit | ESBrandHit | ESSponsoredProductHit | SpecialTileGlobal): hit is ESBrandHit => {
      return 'brand_tags' in hit;
    };

    const promotionHit = computed(() => {
      if (isPromotionHit(item.value)) {
        return item.value;
      }
      return undefined;
    });
    const productHit = computed(() => {
      if (isProductHit(item.value)) {
        return item.value;
      }
      return undefined;
    });
    const brandHit = computed(() => {
      if (isBrandHit(item.value)) {
        return item.value;
      }
      return undefined;
    });

    const hasBrandLink = computed(() => isProductHit(item.value) && config.value.context !== Context.BRAND);
    const additionalLikeTrackingData = computed(() => {
      const additionalData = {
        id_section: trackingSectionId.value,
        component: isSponsoredProductTile.value ? 'native_ads' : undefined,
      };
      if (isSponsoredProductTile.value) {
        additionalData['page'] = page.value + 1;
        additionalData['position'] = index.value;
        additionalData['itemType'] = 'product';
        additionalData['productId'] = item.value.id;
        additionalData['component'] = 'native_ads';
      }
      return additionalData;
    });

    return {
      handleProductTileClicked,
      handleBrandTileClicked,
      handleEventTileClicked,
      promotionHit,
      productHit,
      brandHit,
      additionalLikeTrackingData,
      hasBrandLink,
      isSponsoredProductTile,
    };
  },
});
</script>

<template>
  <div class="ds-relative">
    <PromotionTile
      v-if="promotionHit"
      :tile="promotionHit"
      @special-tile-1-clicked="handleEventTileClicked"
      @on-special-tile-2-clicked="handleEventTileClicked"
    />
    <CatalogProductTile
      v-else-if="productHit"
      :config="config"
      :item="productHit"
      :page="page"
      :index="index"
      :is-sponsored="isSponsoredProductTile"
      @clicked="handleProductTileClicked"
    />
    <BrandSnippet
      v-else-if="brandHit"
      :brand="brandHit"
      @clicked="handleBrandTileClicked"
    />
  </div>
</template>
