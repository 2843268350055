<template>
  <div class="popin-container">
    <div class="popin-container__header">
      <AkParagraph class="popin-container__title">
        {{ i18n.t('My lists') }}
      </AkParagraph>
    </div>
    <div
      v-if="getStatus === 'pending'"
      class="content-loader"
    >
      <LoaderIcon />
    </div>
    <div v-else>
      <div
        v-for="list in getWishlist"
        :key="list.id"
        class="popin-container__content"
      >
        <p
          v-if="list.id !== 1 && !list.editable"
          :class="{
            'ds-pointer-events-none': saving,
            'ds-bg-success-100 ds-text-success-700': selectedListId === list.id,
          }"
          class="list list--not-editable"
          data-testid="wishlistListsListTitle"
          @click="moveToList(list.id)"
        >
          {{ list.name }}
          <span class="list__size">({{ list.products.length }})</span
          ><span class="list__action">
            <AkIcon
              v-if="selectedListId === list.id"
              symbol="check"
              size="md"
            />
            <AkIcon
              v-else
              symbol="plus"
              size="md"
            />
          </span>
        </p>
        <div
          v-if="list.editable"
          :class="'list_' + list.id"
          class="list list--editable"
        >
          <WishlitListEditor
            :title="getTitle(list)"
            data-testid="wishlistListsListTitleInput"
            @set-title="setRename($event, list)"
          />
          <div class="error-input-message">
            {{ $t('The name of the list cannot be empty') }}
          </div>
        </div>
      </div>
      <div class="popin-container__content">
        <div
          v-if="!editable"
          class="list-footer"
        >
          <AkButton
            link
            class=""
            :class="{
              'ds-pointer-events-none': saving,
            }"
            data-testid="wishlistListsListCreateList"
            symbol="plus"
            @on-click="createList()"
          >
            {{ i18n.t('Create a new list') }}
          </AkButton>
        </div>
        <div
          v-else
          class="list-footer"
        >
          <span>{{ i18n.t('Press enter to validate name') }}</span>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import List from '@/types/wishList';
import Analytics from '@/services/analytics';
import UserClick from '@/services/analytics/events/user-click.event';
import LoaderIcon from '@/components/loader-icon.vue';
import { AkButton, AkIcon, AkParagraph } from '@ankorstore/design-system';
import WishlitListEditor from '@/components/wishlist/wishlist-list-editor.vue';

import { defineComponent, ref, computed, onMounted } from 'vue';
import { useStore } from '@/composables/use-store';
import useI18n from '@/composables/use-i18n';

export default defineComponent({
  name: 'WishlistLists',
  components: {
    AkButton,
    AkIcon,
    AkParagraph,
    LoaderIcon,
    WishlitListEditor,
  },
  props: {
    productId: {
      required: true,
      default: null,
      type: Number,
    },
  },
  emits: ['close'],
  setup(props, { emit }) {
    const i18n = useI18n();

    const store = useStore();

    const loading = ref(true);

    const editable = ref(false);

    const saving = ref(false);

    const inputValue = ref('');

    const selectedListId = ref('');

    const getWishlist = computed(() => store.getters['wishlist/getWishlist']);

    const getStatus = computed(() => store.getters['wishlist/getStatus']);

    const getDefaultList = computed(() => store.getters['wishlist/getDefaultList']);

    function moveToList(listId) {
      saving.value = true;
      selectedListId.value = listId;
      Analytics.track(new UserClick({ component: 'modal', action: 'list_choice' }));

      store
        .dispatch('wishlist/moveProductToAnotherlistDirectly', {
          from: getDefaultList.value.id,
          to: listId,
          productId: props.productId,
        })
        .then(() => emit('close'))
        .finally(() => (saving.value = false));
    }

    function createList() {
      editable.value = true;
      store.dispatch('wishlist/createNewList', {
        name: i18n.t('new list n°{number}', {
          number: getWishlist.value.length + 1,
        }),
      });
    }

    async function setRename(event, list) {
      if (event.target.value === '') {
        document.querySelector('.error-input-message').classList.remove('ds-hidden');
        document.querySelector('.input-create-list').classList.add('error-input-border');
        return;
      }

      Analytics.track(new UserClick({ component: 'modal', action: 'create_list' }));

      document.querySelector('.error-input-message').classList.add('ds-hidden');
      document.querySelector('.input-create-list').classList.remove('error-input-border');

      await store.dispatch('wishlist/renameWishList', {
        ...list,
        name: event.target.value,
        editable: false,
      });
      editable.value = false;
    }

    function getTitle(list: List): string {
      if (list.editable) {
        return list.name;
      }
      return list.name + ' (' + list.products.length + ')';
    }

    function onTitleEnter(event: KeyboardEvent) {
      const target = event.target as HTMLInputElement;
      target.blur();
    }

    onMounted(() => {
      store.dispatch('wishlist/fetchWishlist');
    });

    return {
      editable,
      getDefaultList,
      getStatus,
      getWishlist,
      i18n,
      inputValue,
      loading,
      saving,
      selectedListId,
      createList,
      getTitle,
      moveToList,
      onTitleEnter,
      setRename,
    };
  },
});
</script>

<style scoped lang="scss">
@import '@css/vue-import';

.list {
  @apply ds-flex ds-items-center;
  &--not-editable {
    @apply ds-cursor-pointer ds-p-3 ds-border-solid ds-border-b ds-border-neutral-300 ds-m-0;
  }
  &--editable {
    @apply ds-p-3 ds-border-b ds-border-neutral-300;
  }
  &__size {
    @apply ds-ml-2;
  }
  &__action {
    @apply ds-ml-auto;
  }
}
.list:hover {
  @apply ds-bg-warm-white;
}
.modal-body .close-button {
  top: -25px;
}
.popin-container {
  @apply ds-p-5;
  &__header {
    @apply ds-w-full;
  }
  &__title {
    @apply ds-border-b ds-border-solid ds-border-neutral-300 ds-pb-2 ds-text-justify;
  }
  &__content {
    @apply ds-w-full;
  }
}
.list-footer {
  padding: 18px 1px 8px;
  @apply ds-m-0;
}
.input-create-list {
  outline: none;
  padding: 10px 12px;
  @apply ds-border ds-border-solid ds-border-warm-white;
  border-radius: 0;
}
.error-input-message {
  @apply ds-text-error-700 ds-mt-2 ds-hidden;
}
.error-input-border {
  @apply ds-border-error-700;
}
</style>
