<template>
  <form
    class="retailer-form"
    data-akt="register-categories-interests-form"
    @submit.prevent="submit"
  >
    <div
      v-if="!loadingQuestions && categories"
      class="retailer-form__container"
    >
      <div
        v-for="question in questions"
        :key="question.alias"
        class="retailer-form__content"
      >
        <div
          class="retailer-form__question"
          data-testid="register-categories-form"
        >
          <div
            v-for="(category, index) in categories.tree.children"
            :key="category.id"
            class="checkbox-form"
            :class="{ 'ds-pt-3': index !== 0 }"
          >
            <AkExpandFilter
              :title="category.name"
              class="rounded-filter"
              :with-pill="true"
              :options="parseOptions(category.children)"
              @change="setSelectedCategories($event, category, question)"
            />
          </div>
        </div>

        <span
          v-if="question['alias'] in errors"
          class="error-message"
        >{{ errors[question['alias']][0] }}</span>
      </div>
      <div
        v-if="
          ((questions.length && checkboxesDirtyState[questions[0]['alias']]) || formSubmitted) &&
            Object.entries(answers).length === 0
        "
        class="error-message retailer-form__error"
        data-testid="categories-required-error"
      >
        {{ $t("Please select the kind of products you're interested in to get better recommendations!") }}
      </div>
      <AkParagraph
        v-if="'global' in errors"
        size="sm"
        class="global-error"
        data-testid="global-error"
      >
        {{ errors.global }}
      </AkParagraph>
      <AkButton
        v-if="!sending"
        class="button--extended ds-mt-3"
        size="xl"
        data-testid="validates-categories"
        type="submit"
      >
        {{ $t('Submit') }}
      </AkButton>
      <LoaderIcon v-else />
    </div>
    <div
      v-else
      class="retailer-form__loader"
    >
      <LoaderIcon />
    </div>
  </form>
</template>

<script lang="ts">
import Analytics from '@/services/analytics';
import { getProductCategories } from '@/services/product-category/product-category';
import { SignUpEventNames } from '@/services/analytics/events/sign-up/common/event-names';
import SignUpEvent from '@/services/analytics/events/sign-up/sign-up-event';
import { ProductCategories, ProductCategory } from '@/types/product-category';
import { defineComponent } from 'vue';
import {
  getQualificationQuestions,
  patchQualificationQuestions,
  QualificationQuestionsPatchParams,
} from '@/services/api/qualification-questions';
import { mapGetters } from 'vuex';
import LoaderIcon from '@/components/loader-icon.vue';

interface ListOption extends ProductCategory {
  text: string;
  value: string;
}

interface ExpandItem {
  option: string;
  isChecked: boolean;
}

interface Question {
  alias: string;
  label: string;
  required: boolean;
}

const STEP = 2;

export default defineComponent({
  name: 'CategoriesForm',
  components: { LoaderIcon },
  emits: ['success'],
  data() {
    return {
      errors: {},
      checkboxesDirtyState: {},
      disableDefaultText: {},
      extraContent: {},
      answers: {},
      questions: [],
      loadingQuestions: true,
      sending: false,
      categories: {} as ProductCategories,
      formSubmitted: false,
    };
  },
  computed: {
    ...mapGetters('signUp', ['isAnkorstart']),
  },
  async mounted() {
    Analytics.track(
      new SignUpEvent(SignUpEventNames.ActivityPreferencesViewedEvent, {
        type: 'Element Viewed',
        is_opening_soon: this.isAnkorstart,
      })
    );
  },
  async created() {
    this.categories = await getProductCategories();
    await this.fetchQuestions();
  },
  methods: {
    fetchQuestions() {
      getQualificationQuestions(STEP)
        .then(({ data }) => {
          this.questions = data;
        })
        .catch((errors) => {
          this.errors = errors;
          this.sending = false;
        })
        .finally(() => {
          this.loadingQuestions = false;
        });
    },
    submit() {
      this.formSubmitted = true;
      this.errors = {};
      if (!this.isFormValid()) {
        return;
      }
      this.sending = true;

      Analytics.track(
        new SignUpEvent(SignUpEventNames.ActivityPreferencesSubmittedEvent, {
          type: 'Click or Form Submit',
          is_opening_soon: this.isAnkorstart,
        })
      );
      const params = {
        responses: [],
      };
      // for multiple choice questions the answer_id corresponds to the answer id in the questions payload
      const answersResponse = [];
      Object.keys(this.answers).forEach((categoryId) => {
        this.answers[categoryId]?.forEach((answer) => {
          answersResponse.push({
            answer_id: this.questions[0].answers?.[0]?.id,
            content: answer?.toString(),
          });
        });
        answersResponse.push({
          answer_id: this.questions[0].answers?.[0]?.id,
          content: categoryId?.toString(),
        });
      });
      params.responses.push({
        answers: answersResponse,
      });

      this.retailerQualificationPatch(params);
    },
    retailerQualificationPatch(params: QualificationQuestionsPatchParams) {
      patchQualificationQuestions(STEP, params)
        .then(({ data }) => {
          this.$emit('success', { ...data, model: this.answers });
        })
        .catch((errors) => {
          this.errors = errors;
          this.sending = false;
        });
    },
    isFormValid() {
      const requiredQuestions = this.questions.filter((question) => question.required);
      if (requiredQuestions.length !== 0 && Object.keys(this.answers).length !== 0) {
        return true;
      } else if (requiredQuestions.length !== 0 && Object.keys(this.answers).length === 0) {
        Analytics.track(
          new SignUpEvent(SignUpEventNames.ActivityPreferencesMandatoryEvent, {
            type: 'Element Viewed',
            is_opening_soon: this.isAnkorstart,
          })
        );
        return false;
      }
      return true;
    },
    parseOptions(categories: ProductCategory[]): ListOption[] {
      return categories.map((category: ProductCategory) => ({
        ...category,
        text: category.name,
        value: category.id.toString(),
      }));
    },
    setSelectedCategories(event: ExpandItem, category: ProductCategory, question: Question): void {
      let newValues = [];

      if (event.isChecked) {
        newValues = this.answers[category.id] ? [...this.answers[category.id], event.option] : [event.option];
      } else {
        newValues = this.answers[category.id].filter((answer) => answer !== event.option);
      }

      if (newValues.length > 0) {
        this.answers = {
          ...this.answers,
          [category.id]: newValues,
        };
      } else {
        delete this.answers[category.id];
      }

      this.checkboxesDirtyState[question['alias']] = true;
    },
  },
});
</script>

<style scoped lang="scss">
@import '@css/vue-import';

.retailer-form {
  @apply ds-flex;
}

.retailer-form__container {
  @apply ds-flex-grow ds-flex ds-flex-col ds-mt-0 md:ds-mt-4.5;
}

.retailer-form__content {
  @apply ds-mb-5;
}

.retailer-form__question {
  @apply ds-flex ds-flex-col ds-p-0;
}

.retailer-form__submit {
  @apply ds-sticky ds-text-center ds-top-3/4 ds-justify-center;
}

.retailer-form__loader {
  @apply ds-flex-grow;
}

.retailer-form__error {
  @apply ds-pb-2;
}

.checkbox-container {
  @apply ds-flex ds-flex-col;
}

.global-error {
  @apply ds-text-error-700;
  min-height: 16px;
}

.error-message {
  @apply ds-text-sm;
}

.ak-expandfilter__dropdown {
  @apply ds-ml-0;
}

.ak-expandfilter__pill {
  @apply ds-ml-auto;
}

.header {
  @apply ds-mt-4.5 ds-mb-4;
}
</style>
<style lang="scss">
.rounded-filter {
  .ak-expandfilter__title {
    @apply ds-rounded-md ds-border-neutral-500;
  }
  .ak-expandfilter__menu {
    @apply ds-rounded-md ds-border-neutral-500;
    @apply md:ds-absolute md:ds-overflow-y-scroll;
    @media screen and (min-width: 1024px) {
      max-height: 250px;
    }
  }
  .ak-expandfilter__title i {
    @apply ds-text-neutral-700;
  }
}

.checkbox-form {
  @apply ds-relative;
}
</style>
