<template>
  <a
    v-if="content"
    class="home-hero"
    :class="{ 'home-hero--filled': isNewLayout }"
    :style="backgroundImageStyle"
    :href="ctaLink"
  >
    <picture>
      <source
        :srcset="foregroundImageMobile"
        :media="isNewLayout ? '(max-width: 640px)' : '(max-width: 1023px)'"
      >
      <img
        :src="foregroundImage"
        :alt="foregroundImageAlt"
        class="home-hero__foreground"
      >
    </picture>
  </a>
</template>
<script lang="ts">
import { defineComponent, ref, computed, onMounted } from 'vue';
import { Hero } from '@/components/home-hero/types';

export default defineComponent({
  name: 'HomeHero',
  props: {
    contentJson: {
      type: String,
      default: '',
    },
    isNewLayout: {
      type: Boolean,
      default: false,
    },
  },
  setup(props) {
    const content = ref<Hero | null>(null);

    const backgroundImage = computed((): string => {
      return content.value?.background_image?.url;
    });

    const backgroundImageHeight = computed((): number => {
      return content.value?.background_image?.dimensions?.height;
    });

    const backgroundImageMobileHeight = computed((): number => {
      return content.value?.background_image?.mobile?.dimensions?.height;
    });

    const backgroundImageMobile = computed((): string => {
      return content.value?.background_image?.mobile?.url;
    });

    const backgroundImageStyle = computed(() => {
      return {
        '--hero-background-image': backgroundImage.value ? `url(${backgroundImage.value})` : '',
        '--hero-background-image-height': backgroundImageHeight.value ? `${backgroundImageHeight.value}px` : 'auto',
        '--hero-background-image-mobile-height': backgroundImageMobileHeight.value
          ? `${backgroundImageMobileHeight.value}px`
          : 'auto',
        '--hero-background-image-mobile': backgroundImageMobile.value ? `url(${backgroundImageMobile.value})` : '',
      };
    });

    const ctaLink = computed((): string => {
      return content.value?.cta_url || '';
    });

    const foregroundImage = computed((): string => {
      return content.value?.foreground_image?.url || '';
    });

    const foregroundImageMobile = computed((): string => {
      return content.value?.foreground_image?.mobile?.url || '';
    });

    const foregroundImageAlt = computed((): string => {
      return content.value?.foreground_image?.alt || '';
    });

    onMounted(() => {
      if (props.contentJson.length) {
        content.value = JSON.parse(props.contentJson);
      }
    });

    return {
      backgroundImageStyle,
      content,
      ctaLink,
      foregroundImage,
      foregroundImageAlt,
      foregroundImageMobile,
    };
  },
});
</script>
<style lang="scss" scoped>
@import '@css/vue-import';

.home-hero {
  height: var(--hero-background-image-mobile-height);
  background-image: var(--hero-background-image-mobile, none);

  @include media-breakpoint-up(lg) {
    height: var(--hero-background-image-height);
    background-image: var(--hero-background-image, none);
  }

  @apply ds-flex ds-items-center ds-justify-center ds-bg-cover ds-rounded-md ds-overflow-hidden;

  &--filled {
    @apply ds-h-auto ds-bg-center ds-mt-4 sm:ds-mt-6;
    max-height: 540px;
    background-image: var(--hero-background-image-mobile, none);

    @include media-breakpoint-up(sm) {
      @apply ds-h-auto;
      max-height: 450px;
      background-image: var(--hero-background-image, none);
    }

    picture {
      @apply ds-max-h-[540px];
      img {
        @apply ds-max-h-[540px];
      }
    }
  }

  &__foreground {
    @apply ds-h-full ds-object-contain;
  }
}
</style>
<style lang="scss">
@import '@css/vue-import';

.hero-container {
  /* We need important to overwrite the styles are coming from blade */
  min-height: var(--hero-background-image-mobile-height) !important;

  @include media-breakpoint-up(lg) {
    min-height: var(--hero-background-image-height) !important;
  }
}
</style>
