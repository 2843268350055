<template>
  <div
    :class="[
      'ds-flex',
      'ds-flex-row',
      'ds-w-full',
      'md:ds-max-w-md',
      'ds-overflow-hidden',
      'ds-rounded-md',
      'ds-border',
      'ds-border-solid',
      'ds-border-neutral-300',
      'ds-cursor-pointer',
    ]"
    data-testid="brand-suggestions"
    @click="handleClick"
  >
    <img
      class="ds-overflow-hidden"
      :style="{ maxWidth: `${imageMaxWidth}px`, maxHeight: `${imageMaxWidth}px` }"
      :src="brandImageSource"
      :alt="brandName"
      data-testid="brand-suggestion-snippet-image"
    >
    <div
      :class="[
        'ds-flex',
        'ds-items-center',
        'ds-justify-between',
        'ds-flex-grow',
        'ds-overflow-hidden',
        'ds-px-2',
        'md:ds-px-4',
        'ds-bg-warm-white',
      ]"
    >
      <div :class="['ds-flex', 'ds-flex-col', 'ds-flex-1', 'ds-justify-center', 'ds-overflow-hidden', 'ds-mr-4']">
        <AkParagraph
          :class="{
            'ds-overflow-hidden': true,
            'ds-text-ellipsis': true,
            'ds-whitespace-nowrap': Boolean(productCount),
          }"
          data-testid="brand-suggestion-snippet-name-label"
          weight="bold"
        >
          {{ suggestedBrand.name }}
        </AkParagraph>
        <AkParagraph
          v-if="productCount"
          class="ds-text-neutral-700"
          size="sm"
          data-testid="brand-suggestion-snippet-product-number-label"
        >
          {{ $t('retailer.search.suggestion.brand.products.lbl', { productCount }) }}
        </AkParagraph>
      </div>
      <AkButton
        v-if="!isMobile"
        size="lg"
        link
        data-testid="brand-suggestion-snippet-cta"
      >
        {{ $t('retailer.search.suggestion.brand.cta') }}
      </AkButton>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent, PropType, computed, ref, toRefs } from 'vue';
import { useRouter } from 'vue-router';
import { BrandElastic } from '@bc/discovery/domain/search';
import { useBreakpoints } from '@/modules/design-system-candidates';
import { imgCdnUrlFill } from '@bc/shared';

export default defineComponent({
  name: 'BrandSuggestionSnippet',
  props: {
    suggestedBrand: {
      type: Object as PropType<Omit<BrandElastic, 'id' | 'type'>>,
      required: true,
    },
  },
  emits: ['click'],
  setup(props, { emit }) {
    const { suggestedBrand } = toRefs(props);
    const { isMobile } = useBreakpoints();
    const router = useRouter();
    const brandName = computed(() => suggestedBrand.value.name);
    const imageMaxWidth = computed(() => (isMobile.value ? 70 : 78));
    const brandImageSource = computed(() => imgCdnUrlFill(suggestedBrand.value.images.rounded, `${imageMaxWidth.value}`));
    // Currently, there's no way to get the total number of listed products for a brand.
    // But this component already has everything needed to display it in place.
    // Ideally we would want this datum to be present in the suggestedBrand object so we don't need to do another request.
    const productCount = ref();
    const handleClick = () => {
      emit('click', { brandId: suggestedBrand.value.reference });
      router.push({ path: suggestedBrand.value.link });
    };
    return { brandImageSource, brandName, productCount, handleClick, isMobile, imageMaxWidth };
  },
});
</script>
