<template>
  <ak-closable>
    <div
      v-if="!closed"
      class="banner-redirect-language"
    >
      <div>
        <div class="ds-flex-1 lg:ds-flex-none">
          <RedirectLanguageLink
            class="ak-link"
            :href="url"
            :user-cookie-name="userCookieName"
            :admin-cookie-name="adminCookieName"
            :cookie-domain="cookieDomain"
          >
            {{ message }}
          </RedirectLanguageLink>
        </div>
        <span
          class="banner-redirect-language__close"
          @click="onClose"
        >
          <AkIcon symbol="x" />
        </span>
      </div>
    </div>
  </ak-closable>
</template>

<script lang="ts">
import { defineComponent, ref } from 'vue';
import RedirectLanguageLink from '@/components/core/redirect-language-link.vue';

export default defineComponent({
  name: 'BannerRedirectLanguage',
  components: {
    RedirectLanguageLink,
  },
  props: {
    url: {
      type: String,
      required: true,
    },
    message: {
      type: String,
      required: true,
    },
    userCookieName: {
      type: String,
      required: false,
      default: '',
    },
    adminCookieName: {
      type: String,
      required: false,
      default: '',
    },
    cookieDomain: {
      type: String,
      required: false,
      default: '',
    },
  },
  setup() {
    const closed = ref(false);

    function onClose() {
      closed.value = true;
      document.querySelector('.ak-global-container')?.classList.remove('banner-redirect-language');
    }

    return {
      closed,
      onClose,
    };
  },
});
</script>

<style scoped lang="scss">
@import '@css/vue-import';
.banner-redirect-language {
  @apply ds-bg-info-100 ds-py-1 lg:ds-py-2;
  @apply ds-text-xs lg:ds-text-sm ds-flex;
  height: $header-banner-redirect-language-height;

  > div {
    @include container;
    @apply ds-flex ds-items-center lg:ds-justify-center;
  }

  &__close {
    @apply ds-px-2 ds--mr-2;
    @apply ds-cursor-pointer ds-opacity-50 hover:ds-opacity-100 ds-transition;
  }
}
</style>
