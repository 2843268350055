import { StatsContactFacet } from './stats.contact.facet';

export enum WidgetTypes {
  RangeWidget = 'range-widget',
  ToggleWidget = 'toggle-widget',
  DropdownWidget = 'dropdown-widget',
  ListWidget = 'list-widget',
  MultiSelectWidget = 'multi-select-widget',
}

export interface FilterItem {
  id: string;
  type: 'widget';
  attributes: FilterWidgets;
}

export type FilterWidgets = RangeWidget | ToggleWidget | DropdownWidget | ListWidget;
export type FilterWidgetsComponents =
  | RangeWidgetComponent
  | ToggleWidgetComponent
  | DropdownWidgetComponent
  | ListWidgetComponent;

export interface ListWidget {
  type: WidgetTypes.ListWidget;
  attributeName: string;
  label: string;
  multiple: boolean;
  values: {
    value: string | number;
    label: string;
  }[];
}

export interface ListWidgetComponent extends ListWidget {}

export interface DropdownWidget {
  type: WidgetTypes.DropdownWidget;
  attributeName: string;
  label: string;
  multiple: boolean;
  placeholder: string;
  values: {
    value: string;
    label: string;
  }[];
}

export type DropdownWidgetComponent = Omit<DropdownWidget, 'type'> & {
  type: WidgetTypes.DropdownWidget | WidgetTypes.MultiSelectWidget;
};

export interface ToggleWidget {
  type: WidgetTypes.ToggleWidget;
  attributeName: string;
  label: string;
}

export interface ToggleWidgetComponent extends ToggleWidget {
  value: boolean;
}

export interface RangeWidget {
  type: WidgetTypes.RangeWidget;
  attributeName: string;
  label: string;
  separatorLabel: string;
  minLabel: string;
  maxLabel: string;
}

export interface RangeWidgetComponent extends RangeWidget {
  attributes: StatsContactFacet['data'];
}
