import Analytics from '@/services/analytics';
import UserClick from '@/services/analytics/events/user-click.event';
import ViewTile from '@/services/analytics/events/view-tile.event';
import Product from '@/types/product';
import type { IdPosition } from '@bc/shared';
import { memoize } from 'lodash-es';
import { ESProductHit } from '@bc/discovery/domain/catalog';
import { getCustomTrackMergeFn, getSectionId, SectionType, SectionItemType, SectionCreatedEvent } from '@bc/shared';

const matchHypens = /-/g;

export const mergeViewEvents = getCustomTrackMergeFn(['position', 'id_product']);

// RecommendedProducts

export type TrackProductTileClickPayload = {
  productId: string;
  position: number;
};
export type TrackProductTileViewPayload = {
  productId: number;
  position: number;
};
export type TrackRecommendedProductsSectionCreatedPayload = {
  products: IdPosition[];
};

export const getRecommendedProductsTracking = memoize(() => {
  const valueProposition = 'loved_by_retailers_like_you';
  const sectionType = SectionType.RecoCarousel;
  const sectionId = getSectionId({
    valueProposition: valueProposition,
    sectionType: sectionType,
  });
  return {
    trackRecommendedProductClick: (payload: TrackProductTileClickPayload) => {
      Analytics.track(
        new UserClick({
          id_section: sectionId,
          component: sectionType,
          action: 'click_loved_by_retailers_like_you',
          itemtype: SectionItemType.Product,
          product_id: payload.productId,
          position: payload.position,
        })
      );
    },
    trackRecommendedProductView: (payload: TrackProductTileViewPayload) => {
      Analytics.trackMergeBuffer(
        new ViewTile({
          id_section: sectionId,
          id_product: [
            {
              id: payload.productId,
              position: payload.position,
            },
          ],
          position: payload.position,
          value_proposition: valueProposition,
          section_type: sectionType,
        }),
        mergeViewEvents
      );
    },
    trackRecommendedProductSectionCreated: (payload: TrackRecommendedProductsSectionCreatedPayload) => {
      Analytics.track(
        new SectionCreatedEvent({
          id_section: sectionId,
          section_type: sectionType,
          value_proposition: valueProposition,
          itemtype: SectionItemType.Product,
          id_product: payload.products,
        })
      );
    },
  };
});

// RecommendedCategories

export type TrackRecommendedCategoryProductSectionCreatedPayload = {
  categorySlugEn: string;
  products: IdPosition[];
};

export type TrackRecommendedCategoryProductTileViewPayload = {
  position: number;
  productId: number;
  categorySlugEn: string;
};

export type TrackRecommendedCategoryProductTileClickPayload = {
  position: number;
  categorySlugEn: string;
  itemType: SectionItemType.Product | SectionItemType.Brand;
  product_id?: string;
  brand_id?: string;
};

export const getRecommendedCategoriesTracking = memoize(() => {
  const getFormattedSlug = memoize((categorySlug: string) => categorySlug.replace(matchHypens, '_'));
  const getValueProposition = (categorySlugEn: string) => `${getFormattedSlug(categorySlugEn)}_you_may_like`;
  const getClickAction = (categorySlugEn: string) => `click_${getFormattedSlug(categorySlugEn)}_you_may_like`;
  const sectionType = SectionType.RecoCarousel;
  const sectionId = memoize((categoryName: string) =>
    getSectionId({
      valueProposition: getValueProposition(categoryName),
      sectionType: sectionType,
    })
  );
  return {
    trackRecommendedCategoriesProductSectionCreated: (payload: TrackRecommendedCategoryProductSectionCreatedPayload) => {
      Analytics.track(
        new SectionCreatedEvent({
          id_section: sectionId(payload.categorySlugEn),
          section_type: sectionType,
          value_proposition: getValueProposition(payload.categorySlugEn),
          itemtype: SectionItemType.Product,
          id_product: payload.products,
        })
      );
    },
    trackRecommendedCategoriesProductViewed: (payload: TrackRecommendedCategoryProductTileViewPayload) => {
      Analytics.trackMergeBuffer(
        new ViewTile({
          id_section: sectionId(payload.categorySlugEn),
          id_product: [
            {
              id: payload.productId,
              position: payload.position,
            },
          ],
          itemtype: SectionItemType.Product,
          position: payload.position,
          value_proposition: getValueProposition(payload.categorySlugEn),
          section_type: sectionType,
        }),
        mergeViewEvents
      );
    },
    trackRecommendedCategoriesProductClick: (payload: TrackRecommendedCategoryProductTileClickPayload) => {
      const clickData = {
        id_section: sectionId(payload.categorySlugEn),
        component: sectionType,
        action: getClickAction(payload.categorySlugEn),
        itemtype: payload.itemType,
        position: payload.position,
      };
      if (payload.product_id) {
        clickData['product_id'] = payload.product_id;
      } else if (payload.brand_id) {
        clickData['brand_id'] = payload.brand_id;
      }
      Analytics.track(new UserClick(clickData));
    },
  };
});

// SimilarProducts

export type TrackSimilarProductsSectionCreatedPayload = {
  products: Product[];
};

export type TrackSimilarProductsButtonClickedPayload = {
  productId: number;
  sectionId: string;
  component: string;
  pageNumber: number;
  position: number;
};

export type TrackSimilarProductsTileClickedPayload = {
  productId: number;
  brandId?: number;
  position: number;
  action: string;
};

export type TrackQuickAddToCartClickedPayload = {
  productId: number;
  position: number;
};

export const getSimilarProductsRecommendationTracking = memoize((trackingData: { productId: number }) => {
  const valueProposition = `similar_products_drawer_${trackingData.productId}`;
  const sectionType = SectionType.RecoCarousel;
  const sectionId = getSectionId({
    valueProposition,
    sectionType,
  });

  return {
    drawerSectionId: sectionId,
    trackSimilarProductSectionCreated: (payload: TrackSimilarProductsSectionCreatedPayload) => {
      const id_product: IdPosition[] = payload.products.map((product, index) => ({ id: product.id, position: index }));
      Analytics.track(
        new SectionCreatedEvent({
          id_section: sectionId,
          section_type: sectionType,
          value_proposition: valueProposition,
          itemtype: SectionItemType.Product,
          id_product,
        })
      );
    },
    trackShowSimilarProductsButtonClicked: (payload: TrackSimilarProductsButtonClickedPayload) => {
      const clickData = {
        id_section: payload.sectionId,
        component: payload.component,
        action: 'similar_product_icon_clicked',
        itemtype: SectionItemType.Product,
        page_number: payload.pageNumber,
        position: payload.position,
        id_product: trackingData.productId,
      };
      Analytics.track(new UserClick(clickData));
    },
    trackSimilarProductTileClick: (payload: TrackSimilarProductsTileClickedPayload) => {
      Analytics.track(
        new UserClick({
          ...payload,
          id_section: sectionId,
          component: sectionType,
          itemtype: SectionItemType.Product,
        })
      );
    },
    trackQuickAddToCartClicked: (payload: TrackQuickAddToCartClickedPayload) => {
      Analytics.track(
        new UserClick({
          ...payload,
          id_section: sectionId,
          component: sectionType,
          action: 'quick_add_to_cart_clicked',
          itemtype: SectionItemType.Product,
        })
      );
    },
  };
});

type GetProductTrackingParams = {
  valueProposition: string;
  component: string;
  sectionType: SectionType;
};
export const getProductCarouselTracking = ({ valueProposition, component, sectionType }: GetProductTrackingParams) => {
  const sectionId = getSectionId({
    valueProposition,
    sectionType,
  });

  return {
    sectionId,
    trackSectionCreated: (payload: { products: ESProductHit[] }) => {
      Analytics.track(
        new SectionCreatedEvent({
          id_section: sectionId,
          section_type: sectionType,
          value_proposition: valueProposition,
          itemtype: SectionItemType.Product,
          id_product: payload.products.map((product, index) => ({ id: product.id, position: index })),
        })
      );
    },
    trackCtaClicked: () => {
      Analytics.track(
        new UserClick({
          id_section: sectionId,
          component,
          action: 'cta_clicked',
          itemtype: 'collection',
          value_proposition: valueProposition,
        })
      );
    },
    trackProductClicked: (payload: { product: ESProductHit; position: number }) => {
      Analytics.track(
        new UserClick({
          id_section: sectionId,
          component,
          action: 'product_clicked',
          itemtype: SectionItemType.Product,
          value_proposition: valueProposition,
          productId: payload.product.id,
          position: payload.position,
        })
      );
    },
    trackBrandClicked: (payload: { product: ESProductHit; position: number }) => {
      Analytics.track(
        new UserClick({
          id_section: sectionId,
          component,
          action: 'brand_clicked',
          itemtype: SectionItemType.Product,
          value_proposition: valueProposition,
          productId: payload.product.id,
          brandId: payload.product.brand.id,
          position: payload.position,
        })
      );
    },
  };
};
