<script setup lang="ts">
import { computed, ref, onMounted, onBeforeUnmount } from 'vue';
import { PrismicRichText } from '@prismicio/vue';
import { AkParagraph } from '@ankorstore/design-system';

import usePrismic from '@/composables/use-prismic';

import { isEnabled } from '@/services/features';
import { getGlobalConfig } from '@/services/global-config';

import type { Topline } from '@/types/prismic/topline';

import Analytics from '@/services/analytics';
import UserClick from '@/services/analytics/events/user-click.event';

const { client } = usePrismic();

const locale = getGlobalConfig().language_config.prismic_locale;

const isVisible = ref(true);

const content = ref<Topline | null>(null);

const shouldShowTopline = computed(() => {
  return isEnabled('RLO-1509') && !!content.value && content.value.description.length > 0;
});

const linkStyle = computed(() => {
  const hoverColor = !!content.value && !!content.value.text_color ? content.value.text_color : '#8b0be0';
  return {
    color: content.value.text_color,
    '--hover-color': hoverColor,
  };
});

const shouldShowLink = computed(() => {
  return !!content.value && !!content.value.link_url && !!content.value.link_label;
});

function trackUserClick() {
  Analytics.track(
    new UserClick({
      component: 'top_banner',
      action: 'click_hyperlink',
      clicked_value: content.value.link_url,
    })
  );
}
const handleScroll = () => {
  isVisible.value = window.scrollY <= 56;
};

onMounted(async () => {
  if (isEnabled('RLO-1509')) {
    window.addEventListener('scroll', handleScroll);
    try {
      const response = await client.getSingle('topline', { lang: locale });
      content.value = response.data as Topline;
    } catch (e) {
      return;
    }
  }
});

onBeforeUnmount(() => {
  if (isEnabled('RLO-1509')) {
    window.removeEventListener('scroll', handleScroll);
  }
});
</script>

<template>
  <div
    v-if="shouldShowTopline"
    class="banner-top-line"
    :class="{ 'banner-top-line--hidden': !isVisible }"
    data-testid="bannerTopLine"
  >
    <div
      class="banner-top-line__content"
      :style="{ backgroundColor: content.background_color }"
      data-testid="bannerTopLineContent"
    >
      <AkParagraph
        v-if="content.description"
        :style="{ color: content.text_color }"
        data-testid="bannerTopLineDescription"
        class="banner-top-line__description"
        size="sm"
      >
        <PrismicRichText
          :field="content.description"
          wrapper="span"
        />
        <a
          v-if="shouldShowLink"
          :href="content.link_url"
          :style="linkStyle"
          class="banner-top-line__link"
          data-testid="bannerTopLineLink"
          @click="trackUserClick"
        >
          {{ content.link_label }}
        </a>
      </AkParagraph>
    </div>
  </div>
</template>

<style lang="scss">
.banner-top-line {
  @apply ds-opacity-100 ds-h-auto ds-transition-all ds-duration-300 ds-ease-in-out ds-order-2 xl:ds-order-1 ds-w-full;
  &--hidden {
    @apply ds-opacity-0 ds-h-0 xl:ds-opacity-100 xl:ds-h-auto;
  }
  &__content {
    @apply ds-bg-discount-100 ds-text-discount-700 ds-inline-flex ds-flex-wrap ds-text-center ds-items-center ds-justify-center ds-py-2 ds-px-4 ds-w-full;
  }
  &__description {
    b,
    strong {
      @apply ds-font-bold;
    }
    span {
      p {
        @apply ds-inline;
      }
    }
  }
  &__link {
    @apply ds-underline;
    &:hover {
      color: var(--hover-color);
    }
  }
}
</style>
