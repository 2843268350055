import { CartPreorderState } from '@/store/cart-preorder/index';
import { CountryIsoCode } from '@/types/country';
import { isEnabled } from '@/services/features';
import { PreorderCartResponseMapped } from '@/types/cart-preorder';

export default {
  checkoutId: (state: CartPreorderState) => state.preorderCart.id,
  checkoutTotals: (state: CartPreorderState) => ({
    totalAmountWithVat: state.preorderCart.total_amount_with_vat,
    totalAmountVat: state.preorderCart.total_amount_vat,
    totalAmount: state.preorderCart.total_amount,
    grandTotalAmountWithVat: state.preorderCart.grand_total_amount_with_vat,
  }),
  getCurrentCart: (state: CartPreorderState): PreorderCartResponseMapped => state.preorderCart,
  getUpdates: (state: CartPreorderState) => state.bulkUpdates,
  isCartLoading: (state: CartPreorderState) => state.isCartLoading,
  totalCountArticles: (state: CartPreorderState) => state.itemsCounts.main,
  checkPreorderCartIsEmpty: (state: CartPreorderState) => state.preorderCart.carts.length === 0,
  cartByBrandId: (state: CartPreorderState) => (id: string) => {
    const cart = state.preorderCart.carts.find((c) => c.brand.id === id);

    if (cart) {
      return cart;
    }

    return null;
  },
  hasPreorderFrom: (state: CartPreorderState) => (country: CountryIsoCode) => {
    return state.preorderCart.brandsCountry.includes(country);
  },
  itemByVariantId: (state: CartPreorderState) => (id: string) => {
    for (const cart of state?.preorderCart?.carts ?? []) {
      const item = cart?.items.find((product) => product.variant.id === id);

      if (item) {
        return item;
      }
    }

    return null;
  },
  paymentTerms: (state: CartPreorderState) => state.paymentTerms,
  newMovEnabled: () => isEnabled('OXP-2047'),
};
