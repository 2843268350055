import {
  BROWSE_TO_RESULT,
  DO_RESET_RESULTS,
  FETCH_ALL,
  FETCH_CATEGORY_SUGGESTIONS,
  FETCH_RESULTS_ELASTIC,
  FETCH_POPULAR_SEARCHES,
  HANDLE_CLICK_ALL_RESULTS,
  HANDLE_ITEM_CLICK,
  HANDLE_ITEM_CLICK_REMOVAL,
  HANDLE_R2B_CLICK,
  INIT_RESULTS,
  SAVE_RECENT_SEARCH,
  SET_RECENT_SEARCH_FOR_QUERY,
  TRACK_CLICK,
} from './action-types';
import {
  REMOVE_RECENT_SEARCH,
  RESET_ARROW_POSITION,
  SET_HISTORICAL_FROM_LOCALSTORAGE,
  SET_INITIAL_RESULTS,
  SET_ORIGINAL_USER_INPUT,
  SET_RECENT_SEARCH,
  SET_RECENT_SEARCH_HISTORY,
  SET_RESULTS_ELASTIC,
  SET_RESULTS_IDS,
  SET_RESULTS_VISIBILITY,
  SET_SUGGESTED_CATEGORIES,
  SET_POPULAR_SEARCHES,
} from './mutation-types';
import Analytics from '@/services/analytics';
import UserClick from '@/services/analytics/events/user-click.event';
import type { Router } from 'vue-router';
import { CategoryResult, ItemResult, RESULT_TYPE, SearchBarEvent, SearchService } from '@bc/discovery/domain/search';
import {
  HISTORICAL_SEARCH_KEY,
  MAX_NUM_RECENT_SEARCHES,
  MAX_NUM_RECENT_SEARCHES_WITH_QUERY,
  PREFILTERED_PREFIX,
  R2B_LINK_HREF,
  R2B_LINK_ID,
  RECENT_SEARCH_GROUP,
} from './index';
import SearchBarResultItemClickEvent from '@/services/analytics/events/search-bar-result-item-click-event';
import { getCategoryId } from '@/utilities/algolia';
import { escape, isString } from 'lodash-es';
import { captureRetailerCoreDiscoveryException } from '@/sentry/helper';

const isInteger = (value: unknown): value is number => Number.isInteger(value);

function getBrandIdForTrackClick(item: object): string | number {
  if ('id' in item && isInteger(item.id)) {
    return item.id;
  }

  if ('id' in item && isString(item.id)) {
    return item.id.replace('brand-', '');
  }

  if ('reference' in item && (isString(item.reference) || isInteger(item.reference))) {
    return item.reference;
  }

  return '';
}

const searchActions = {
  [INIT_RESULTS]: ({ commit, getters, rootGetters }) => {
    const r2bLink = rootGetters.user &&
      rootGetters['offers/r2bWalletCreditOffer'] && {
        id: R2B_LINK_ID,
        link: R2B_LINK_HREF,
      };
    commit(SET_INITIAL_RESULTS, { r2bLink });
    commit(SET_RESULTS_IDS, getters.results);
    commit(SET_HISTORICAL_FROM_LOCALSTORAGE, JSON.parse(localStorage.getItem(HISTORICAL_SEARCH_KEY)) ?? []);
  },
  [FETCH_CATEGORY_SUGGESTIONS]: async ({ commit, rootState }, { query }: { query: string }) => {
    let categories = [];
    try {
      categories = await SearchService.fetchCategories({
        query,
        country: rootState.country,
      });
    } catch (e) {
      captureRetailerCoreDiscoveryException(e, [
        { label: 'component', value: 'search-bar/actions' },
        { label: 'action', value: FETCH_CATEGORY_SUGGESTIONS },
      ]);
    } finally {
      commit(SET_SUGGESTED_CATEGORIES, { categories });
    }
  },
  [FETCH_POPULAR_SEARCHES]: async ({ commit, rootState }, { query }: { query: string }) => {
    let popularSearches = [];
    try {
      popularSearches = await SearchService.fetchPopularSearches({
        query,
        country: rootState.country,
      });
    } catch (e) {
      captureRetailerCoreDiscoveryException(e, [
        { label: 'component', value: 'search-bar/actions' },
        { label: 'action', value: FETCH_POPULAR_SEARCHES },
      ]);
    } finally {
      commit(SET_POPULAR_SEARCHES, { popularSearches, query });
    }
  },
  [FETCH_ALL]: async ({ commit, rootState }, { query }: { query: string }) => {
    let response = {};
    try {
      response = await SearchService.fetchAll({
        query,
        country: rootState.country,
      });
    } catch (e) {
      captureRetailerCoreDiscoveryException(e, [
        { label: 'component', value: 'search-bar/actions' },
        { label: 'action', value: FETCH_ALL },
      ]);
    } finally {
      commit(SET_RESULTS_ELASTIC, response);
    }
  },
  [FETCH_RESULTS_ELASTIC]: async ({ commit, dispatch, getters }, { query }: { query: string }) => {
    try {
      if (!query) {
        dispatch(INIT_RESULTS);
        await dispatch(FETCH_POPULAR_SEARCHES, { query });
      } else {
        await Promise.allSettled([dispatch(FETCH_ALL, { query })]);
      }
      commit(SET_RESULTS_IDS, getters.results);
    } catch (e) {
      dispatch(INIT_RESULTS);
    }
  },
  [DO_RESET_RESULTS]: ({ commit, dispatch }, searchInput: string) => {
    commit(RESET_ARROW_POSITION);
    commit(SET_ORIGINAL_USER_INPUT, searchInput);
    dispatch(INIT_RESULTS);
  },
  [TRACK_CLICK]: ({ getters }, { item }: { item: ItemResult }) => {
    let options: { action: SearchBarEvent; brandId?: string | number; categoryId?: string | number };

    if (item.type === RESULT_TYPE.TRENDING || item.type === RESULT_TYPE.POPULAR) {
      const isSuggestionPrefiltered = `${item.id}`.includes(PREFILTERED_PREFIX);
      let action = 'click_trending_searches';

      if (getters.originalUserInput) {
        action = isSuggestionPrefiltered ? 'click_suggestion_searches_prefilter_category' : 'click_suggestion_searches';
      }
      options = {
        action: action as SearchBarEvent,
        ...(isSuggestionPrefiltered && {
          categoryId: getCategoryId({ item, level: 1 }),
        }),
      };
    } else if (item.type === RESULT_TYPE.BRANDS) {
      options = {
        action: 'click_brand' as SearchBarEvent,
        brandId: getBrandIdForTrackClick(item),
      };
    } else if (item.type === RESULT_TYPE.CATEGORIES) {
      options = {
        action: 'click_suggestion_categories',
        categoryId: (item as CategoryResult).reference,
      };
    } else if (item.type === RESULT_TYPE.BOUTIQUE) {
      options = {
        action: 'click_event',
      };
    }

    Analytics.track(
      new SearchBarResultItemClickEvent({
        action: 'click_recent_searches',
        query: getters.currentSelectionName as string,
        ...options,
      })
    );
  },
  [HANDLE_R2B_CLICK]: async (
    { dispatch },
    { router, href, searchQuery }: { router: Router; href: string; searchQuery: string }
  ) => {
    Analytics.track(
      new UserClick({
        component: 'search-autocomplete',
        action: 'go_to_r2b_page',
      })
    );
    dispatch(DO_RESET_RESULTS);
    await dispatch(BROWSE_TO_RESULT, { router, href, searchQuery });
  },
  [HANDLE_CLICK_ALL_RESULTS]: async (
    { dispatch, getters },
    { router, searchInput }: { router: Router; searchInput?: string }
  ) => {
    Analytics.track(
      new SearchBarResultItemClickEvent({
        query: getters.originalUserInput,
        action: 'click_search',
      })
    );

    dispatch(BROWSE_TO_RESULT, {
      href: `/search?q=${encodeURIComponent(searchInput)}`,
      router,
      searchQuery: searchInput,
    });

    dispatch(SAVE_RECENT_SEARCH, { searchQuery: searchInput });
  },
  [HANDLE_ITEM_CLICK]: async ({ dispatch }, { item, router }: { item: ItemResult; router: Router }) => {
    dispatch(TRACK_CLICK, { item });

    if (item.type !== RESULT_TYPE.BRANDS) {
      dispatch(SAVE_RECENT_SEARCH, { searchQuery: item.name });
    }
    if ('link' in item) {
      await dispatch(BROWSE_TO_RESULT, { href: item.link, router, searchQuery: item.name });
    }
  },
  [BROWSE_TO_RESULT]: async (
    { commit },
    { router, href, searchQuery }: { router: Router; href: string; searchQuery: string }
  ) => {
    const HeaderMobile = document.querySelector('#headerMobile');
    if (HeaderMobile?.classList.contains('opened')) {
      HeaderMobile.classList.remove('opened');
      HeaderMobile.classList.add('closed');
      document.querySelector('.burgerMenuChange').classList.toggle('change');
    }

    commit(SET_RESULTS_VISIBILITY, false);

    if (router?.currentRoute?.value.query.q === searchQuery) {
      return;
    }

    if (router && router?.currentRoute?.value?.path !== '/register' && router?.currentRoute?.value?.path !== '/login') {
      await router.push(href);
    } else {
      window.location.href = href;
    }
  },
  [SAVE_RECENT_SEARCH]: ({ commit }, { searchQuery }: { searchQuery: string }) => {
    const encodedSearchQuery = escape(searchQuery);
    encodedSearchQuery?.trim() &&
      commit(SET_RECENT_SEARCH, {
        name: encodedSearchQuery.trim(),
        link: `/search?q=${encodeURIComponent(searchQuery)}`,
        id: `recent-${searchQuery}`,
        group: RECENT_SEARCH_GROUP,
      });
  },
  [HANDLE_ITEM_CLICK_REMOVAL]: ({ commit, getters }, { item }: { item: ItemResult }) => {
    commit(REMOVE_RECENT_SEARCH, item);
    commit(SET_RESULTS_IDS, getters.results);
  },
  [SET_RECENT_SEARCH_FOR_QUERY]: ({ commit, getters }, searchQuery: string) => {
    try {
      const regexp = new RegExp(`^${searchQuery}`);
      const { getRecentSearches, results } = getters;

      commit(
        SET_RECENT_SEARCH_HISTORY,
        getRecentSearches
          .filter((element) => regexp.test(element.name))
          .slice(0, searchQuery ? MAX_NUM_RECENT_SEARCHES_WITH_QUERY : MAX_NUM_RECENT_SEARCHES) ?? []
      );
      commit(SET_RESULTS_IDS, results);
    } catch {
      //
    }
  },
};

export default searchActions;
export type SearchActions = typeof searchActions;
