import http from '@/services/api/http';
import { deserialize } from '@/services/utils/jsonapi-parser';
import { BrandDiscount, BrandDiscountContext } from '@/types/api/brand-discount';
import { Cart2, PurchasingLists, PurchasableAggregate } from '@/modules/cart-2/types';
import { getPersonalDiscount } from '@/services/api/brand-discount';
import { Amount } from '@/types/amount';

import { jsonApiConfigs } from '@/services/api/configs/jsonapi';
import { CartAddress } from '../types/cart-address';
import { isEnabled } from '@/services/features';

interface PaymentSummary {
  isShippable: boolean;
  grandTotal: Amount;
  billingAddress: CartAddress;
  shippingAddress: CartAddress;
}

export const getCart = (cartId: string): Promise<Cart2> =>
  http()
    .get(`/api/internal/v1/cart/carts/${cartId}`, {
      ...jsonApiConfigs,
      params: {
        include: 'list.itemAggregates.brand,list.itemAggregates.items.purchasableItem',
      },
    })
    .then(({ data }) => deserialize(data))
    .then(({ data }) => data['list'].data as Cart2);

export const getCartSummary = (cartId: string, included: string[] = []) =>
  http()
    .get(`/api/internal/v1/cart/cart-summary/${cartId}`, {
      ...jsonApiConfigs,
      params: { include: included.join() },
    })
    .then(({ data }) => deserialize(data));

export const getPaymentSummary = async (cartId: string): Promise<PaymentSummary> =>
  (({
    data: {
      meta: { isShippable },
      summary: { grandTotal },
      billingAddress: { data: billingAddress },
      shippingAddress: { data: shippingAddress },
    },
  }) => ({ isShippable, grandTotal, billingAddress, shippingAddress }))(
    await getCartSummary(cartId, ['billingAddress', 'shippingAddress'])
  );

export const getPersonalBrandDiscount = async (brandIds: number[]): Promise<BrandDiscount[]> => {
  return await getPersonalDiscount(BrandDiscountContext.cartPage, brandIds);
};

export const updateItemsQuantities = async (purchasingListId: string, items: Record<string, number>): Promise<Cart2> => {
  const response = await http().patch(
    `api/internal/v1/cart/purchasing-lists/${purchasingListId}/-actions/content-update`,
    {
      data: {
        attributes: {
          items: Object.entries(items).map(([itemId, quantity]) => ({ variantId: itemId, quantity })),
          mergeQuantities: false,
        },
      },
    },
    {
      params: {
        include: 'itemAggregates.brand,itemAggregates.items.purchasableItem',
      },
      ...jsonApiConfigs,
    }
  );

  const formattedResponse = deserialize(response.data);
  return formattedResponse.data as Cart2;
};

export const updateBrandSelection = async (purchasingListId: string, aggregates: PurchasableAggregate[]): Promise<Cart2> => {
  const payload = aggregates.map((aggregate) => ({
    aggregateId: aggregate.id,
    selected: aggregate.selected,
  }));
  const response = await http().patch(
    `api/internal/v1/cart/purchasing-lists/${purchasingListId}/-actions/selection-update`,
    {
      data: {
        attributes: {
          items: payload,
        },
      },
    },
    {
      params: {
        include: 'itemAggregates.brand,itemAggregates.items.purchasableItem',
      },
      ...jsonApiConfigs,
    }
  );
  const formattedResponse = deserialize(response.data);
  return formattedResponse.data as Cart2;
};

// No longer used (maybe in the future if the savedForLater is no more fetched in the cart)

// export const getCounts = async (retailerId): Promise<CartCount> => {
//   const response = await http().get(`api/internal/v1/cart/item-counter/${retailerId}`, {
//     ...jsonApiConfigs,
//   });
//   return deserialize(response.data?.data?.attributes);
// };

export const getSavedForLater = async (purchasingListId: string): Promise<Cart2> => {
  const response = await http().get(`api/internal/v1/cart/purchasing-lists/${purchasingListId}`, {
    ...jsonApiConfigs,
    params: {
      include: 'itemAggregates.brand,itemAggregates.items.purchasableItem',
    },
  });

  const formattedResponse = deserialize(response.data);
  return formattedResponse?.data as Cart2;
};

export const getAvailableCredit = (retailerUuid: string): Promise<{ regularOrderCredit: Amount; preOrderCredit: Amount }> => {
  try {
    return http()
      .get(`/api/internal/v1/payment/retailer-available-credit/${retailerUuid}`, {
        ...jsonApiConfigs,
      })
      .then(({ data }) => deserialize(data))
      .then(({ data }) => {
        // OXP-1940: Activate new response from GET retailer-available-credit, for safety reasons we need to check if the new response is enabled
        if (isEnabled('OXP-1940')) {
          return {
            regularOrderCredit: data['orders'],
            preOrderCredit: data['pre-orders'],
          };
        } else {
          const { amount, currency } = data;
          return {
            regularOrderCredit: { amount, currency } as Amount,
            preOrderCredit: null,
          };
        }
      });
  } catch (e) {
    return Promise.reject(e);
  }
};

export const moveBrandBetweenLists = async (params, items): Promise<PurchasingLists> => {
  const { destinationListId, sourceListId } = params;
  const response = await http().patch(
    'api/internal/v1/cart/purchasing-lists/-actions/cross-list-operation',
    {
      data: {
        attributes: {
          destinationListId,
          sourceListId,
          operation: 'move',
          items,
          mergeQuantities: false,
        },
      },
    },
    {
      ...jsonApiConfigs,
      params: {
        include:
          'sourceList.itemAggregates.brand,sourceList.itemAggregates.items.purchasableItem,destinationList.itemAggregates.brand,destinationList.itemAggregates.items.purchasableItem',
      },
    }
  );
  const formattedResponse = deserialize(response.data);
  return formattedResponse?.data as PurchasingLists;
};
