import http from '@/services/api/http';
import { deserialize } from '@/services/utils/jsonapi-parser';
import { handleAdsHttpError } from '@bc/advertisement/domain/handle-http-error';
import {
  AdInfo,
  AdsCampaignStatusAction,
  CampaignInfo,
  CampaignInfoApiResponse,
  CampaignListMeta,
  CampaignSetupPayload,
  CampaignSetupResponse,
} from '@bc/advertisement/domain/types/ads-campaign.type';

export const getAdsCampaignList = async (
  brandUuid: string,
  page: number,
  pageLimit: number
): Promise<{ data: CampaignInfo[]; meta: CampaignListMeta } | null> => {
  try {
    const queryParams = {
      'withMeta[adsCount]': true,
      'filter[brand]': brandUuid,
      'page[offset]': page,
      'page[limit]': pageLimit,
    };

    const data = await http().get(`/api/aardvark/v1/ad-campaigns`, { params: queryParams });
    const dataDeserialized = deserialize(data.data);

    return { data: dataDeserialized.data, meta: dataDeserialized.meta };
  } catch (error) {
    if (http().isAxiosError(error)) {
      handleAdsHttpError(error, 'ads-campaign-list');
    }
    return Promise.resolve({ data: [], meta: { page: { total: 0 } } });
  }
};

export const getCampaignInfo = async (campaignUuid: string): Promise<CampaignInfoApiResponse> => {
  try {
    const data = await http().get(`/api/aardvark/v1/ad-campaigns/${campaignUuid}`);

    return deserialize(data.data).data;
  } catch (error) {
    if (http().isAxiosError(error)) {
      handleAdsHttpError(error, 'ads-campaign-info');
    }
    return Promise.reject(error);
  }
};

export const getCampaignAds = async (campaignUuid: string): Promise<AdInfo[]> => {
  const queryParams = {
    'filter[campaign]': campaignUuid,
    'page[limit]': 100,
    include: 'upstream-ad-documents',
  };

  try {
    const data = await http().get(`/api/aardvark/v1/ads`, {
      params: queryParams,
    });

    return deserialize(data.data).data;
  } catch (error) {
    if (http().isAxiosError(error)) {
      handleAdsHttpError(error, 'ads-campaign-list');
    }
    return Promise.reject(error);
  }
};

export const createAdsCampaign = async (campaignPayload: CampaignSetupPayload): Promise<CampaignSetupResponse> => {
  try {
    const data = await http().post(`/api/aardvark/v1/ad-campaigns/setup`, campaignPayload);

    return deserialize(data.data).data;
  } catch (error) {
    if (http().isAxiosError(error)) {
      handleAdsHttpError(error, 'create-ads-campaign');
    }
    return Promise.reject(error);
  }
};

export const editAdsCampaign = async (
  adsCampaignId: string,
  campaignPayload: CampaignSetupPayload
): Promise<CampaignSetupResponse> => {
  try {
    const data = await http().patch(`/api/aardvark/v1/ad-campaigns/${adsCampaignId}/setup`, campaignPayload);

    return deserialize(data.data).data;
  } catch (error) {
    if (http().isAxiosError(error)) {
      handleAdsHttpError(error, 'edit-ads-campaign');
    }
    return Promise.reject(error);
  }
};

export const changeAdsCampaignStatus = async (
  adsCampaignUuid: string,
  action: AdsCampaignStatusAction
): Promise<CampaignSetupResponse> => {
  try {
    const data = await http().post(`/api/aardvark/v1/ad-campaigns/${adsCampaignUuid}/-actions/${action}`);

    return deserialize(data.data).data;
  } catch (error) {
    if (http().isAxiosError(error)) {
      handleAdsHttpError(error, `${action}-ads-campaign`);
    }
    return Promise.reject(error);
  }
};
