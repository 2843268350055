<template>
  <LikeProductButton
    v-if="user && $isEnabled('like_products_and_brands')"
    class="like-button"
    :class="NativeAdClickOrigin.AddToFavourites"
    ga-on="click"
    :ga-event-category="type"
    :ga-event-action="isLiked ? 'unlike' : 'like'"
    :ga-event-label="type + '/' + id"
    :title="isLiked ? $t('Unlike') : $t('Like')"
    :is-selected="isLiked"
    :is-focused="isFocused"
    @change="onChange"
  />
</template>

<script lang="ts">
import { defineComponent, PropType } from 'vue';

// eslint-disable-next-line dependency-cruiser/errors
import LikeProductButton from '@bc/discovery/ui/product/like-product-button.vue';
import { AdditionalLikeTrackingData } from '@/types/analytics/recommendation-tracking';
import { NativeAdClickOrigin } from '@bc/advertisement';
import rootStoreHelpers from '@/store/helpers';

export default defineComponent({
  name: 'LikeButton',
  components: { LikeProductButton },
  props: {
    type: {
      validator: function (value: string) {
        // Must be one of these values
        return ['product', 'brand'].indexOf(value) !== -1;
      },
      required: true,
    },
    id: {
      type: Number,
      required: true,
    },
    additionalTrackingData: {
      type: Object as PropType<AdditionalLikeTrackingData>,
      default: null,
    },
    withTooltip: {
      type: Boolean,
      default: false,
    },
    isFocused: {
      type: Boolean,
      default: false,
    },
    // Temporary prop to disable tracking while we work on RET-3307
    disableTracking: {
      type: Boolean,
      default: false,
    },
  },
  emits: ['unlike-item', 'like-product', 'like-brand', 'unlike-product', 'unlike-brand'],
  setup() {
    const { user, brandIsLiked, productIsLiked } = rootStoreHelpers.useGetters(['user', 'brandIsLiked', 'productIsLiked']);
    const { likeProduct, unLikeProduct, likeBrand, unLikeBrand } = rootStoreHelpers.useActions([
      'likeProduct',
      'unLikeProduct',
      'likeBrand',
      'unLikeBrand',
    ]);
    return { NativeAdClickOrigin, user, brandIsLiked, productIsLiked, likeProduct, unLikeProduct, likeBrand, unLikeBrand };
  },
  computed: {
    isLiked(): boolean {
      return this.type === 'product' ? this.productIsLiked(this.id) : this.brandIsLiked(this.id);
    },
  },
  methods: {
    unLike() {
      this.$parent.$emit('unlike-item', this.id);
      const trackingData = {
        id: this.id,
        disableTracking: this.disableTracking,
      };
      if (this.additionalTrackingData && 'sectionId' in this.additionalTrackingData) {
        trackingData['sectionId'] = this.additionalTrackingData.sectionId;
      }
      if (this.type === 'product') {
        this.$emit('unlike-product');
        this.unLikeProduct(trackingData);
      } else {
        this.$emit('unlike-brand');
        this.unLikeBrand(trackingData);
      }
    },
    like() {
      if (this.type === 'product') {
        this.$emit('like-product');
        this.likeProduct({
          id: this.id,
          additionalTrackingData: this.additionalTrackingData,
          disableTracking: this.disableTracking,
        });
      } else {
        this.$emit('like-brand');
        this.likeBrand({ id: this.id, disableTracking: this.disableTracking });
      }
    },
    onChange() {
      this.isLiked ? this.unLike() : this.like();
    },
  },
});
</script>
