<!-- eslint-disable @intlify/vue-i18n/no-dynamic-keys -->
<template>
  <div
    v-if="content.perks.length > 0"
    class="benefits-ankorstore-plus"
    :class="'benefits-ankorstore-plus--' + context"
    data-testid="benefitsAnkorstorePlus"
  >
    <div class="benefits-ankorstore-plus__header">
      <div class="benefit">
        <div class="benefit__icon">
          <AnkorstorePlusBadge
            size="md"
            no-padding
          />
        </div>
        <div class="benefit__content">
          <div class="benefit__top">
            <div class="benefit__left">
              <i18n-t
                class="benefit__title"
                :keypath="content.title"
                tag="p"
              >
                <template #logo>
                  <AnkorstorePlusTitle />
                </template>
              </i18n-t>
            </div>
            <div class="benefit__right">
              <AkButton
                v-if="shouldShowButton"
                data-testid="joinButtonTop"
                outlined
                @click="signIn"
              >
                {{ content.btnLabel }}
              </AkButton>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="benefits-ankorstore-plus__content">
      <template
        v-for="perk in content.perks"
        :key="perk.index"
      >
        <div class="benefit">
          <div class="benefit__icon">
            <AkIcon
              class="icon"
              size="sm"
              :symbol="perk.icon"
            />
          </div>
          <div class="benefit__content">
            <div class="benefit__top">
              <div class="benefit__left">
                <AkParagraph size="sm">
                  {{ perk.description }}
                </AkParagraph>
              </div>
              <div
                v-if="perk.value"
                class="benefit__right"
              >
                <template
                  v-for="value in perk.value"
                  :key="value.index"
                >
                  <AkParagraph
                    class="value"
                    size="sm"
                    :class="{ 'value--striked': value.type === 'strike' }"
                    :weight="value.type === 'bold' ? 'bold' : 'normal'"
                  >
                    {{ value.label }}
                  </AkParagraph>
                </template>
              </div>
            </div>
          </div>
        </div>
      </template>
    </div>
  </div>
</template>
<script lang="ts">
import { defineComponent, PropType, computed } from 'vue';
import { AkParagraph } from '@ankorstore/design-system';
import AnkorstorePlusBadge from '@/modules/promote-engage/subscription-program/UI/ankorstore-plus-badge.vue';
import AnkorstorePlusTitle from '@/modules/promote-engage/subscription-program/UI/ankorstore-plus-title.vue';

import { useBreakpoints } from '@/modules/design-system-candidates';
import { useRouter } from '@/composables/use-router';
import Analytics from '@/services/analytics';
import UserClick from '@/services/analytics/events/user-click.event';

import { BenefitsAnkorsorePlus } from '@/types/touchpoints/benefits-ankorsore-plus';
import { onMounted } from 'vue';
import { SectionCreatedEvent } from '@/services/analytics/events/section_created.event';

export default defineComponent({
  name: 'BenefitsAnkorstorePlus',
  components: { AkParagraph, AnkorstorePlusBadge, AnkorstorePlusTitle },
  props: {
    isOptin: {
      type: Boolean,
      default: false,
    },
    content: {
      type: Object as PropType<BenefitsAnkorsorePlus>,
      required: true,
    },
    context: {
      type: String,
      required: false,
      default: 'product',
      validator: (value: string) => ['product', 'brand'].includes(value),
    },
  },
  setup(props) {
    const { isMobile } = useBreakpoints();

    const router = useRouter();

    const shouldShowButton = computed((): boolean => {
      return !props.isOptin && !!props.content.btnLabel;
    });

    function signIn(): void {
      Analytics.track(
        new UserClick({
          component: 'ankorstore_plus_touchpoint',
          action: props.context === 'brand' ? 'click_join_plus_brand_page' : 'click_join_plus_product_page',
        })
      );
      router.push({ name: 'ankorstore-plus-signup' });
    }

    onMounted(() => {
      if (props.context === 'product') {
        Analytics.track(
          new SectionCreatedEvent({
            section: 'ankorstore_plus_touchpoint',
            nb_perks: props.content.perks.length,
          })
        );
      }
    });

    return {
      isMobile,
      shouldShowButton,
      signIn,
    };
  },
});
</script>
<style lang="scss" scoped>
.benefits-ankorstore-plus {
  @apply ds-bg-white ds-overflow-hidden;
  border-radius: 12px;
  &__header {
    @apply ds-bg-accent-alt;
    .benefit {
      @apply ds-flex-row;
      &__top {
        @apply ds-items-start md:ds-items-center;
      }
      &__icon {
        @apply ds-bg-transparent;
      }
    }
  }
  &__content {
    @apply ds-border-b ds-border-l ds-border-r ds-border-solid ds-border-neutral-300;
    border-bottom-left-radius: 12px;
    border-bottom-right-radius: 12px;
  }
}

.benefit {
  @apply ds-flex ds-gap-2 ds-px-5 ds-py-4 ds-items-start ds-border-b ds-border-solid ds-border-neutral-300;
  &:last-of-type {
    @apply ds-border-b-0;
  }
  &--footer {
    @apply ds-justify-end ds-py-3;
  }
  &__title {
    @apply ds-font-bold ds-text-base;
  }
  &__content {
    @apply ds-flex ds-flex-col ds-gap-2 ds-flex-1;
  }
  &__top {
    @apply ds-flex ds-gap-x-5 ds-gap-y-2 ds-items-baseline lg:ds-items-center;
    min-height: 32px;
  }
  &__right {
    @apply ds-flex ds-gap-4 ds-ml-auto ds-text-right ds-items-center;
  }
  &__left {
    @apply ds-w-7/12 md:ds-flex-1;
  }
  &__icon {
    @apply ds-bg-neutral-100 ds-rounded-full ds-w-6 ds-h-6 ds-flex ds-items-center ds-justify-center;
  }
}

.collapser {
  font-size: 20px;
}
.icon {
  @apply ds-text-2xl;
}

.value {
  &--striked {
    @apply ds-line-through ds-text-neutral-900;
  }
}
</style>
<style lang="scss">
.benefits-ankorstore-plus {
  &__content {
    .benefit {
      .benefit__icon {
        i {
          @apply ds-text-xl;
        }
      }
    }
  }
  &__header {
    .benefit__icon {
      a {
        @apply ds-flex;
      }
    }
  }
}
</style>
