<template>
  <div class="ds-flex ds-flex-col ds-gap-4">
    <AisCurrentRefinements
      :included-attributes="selectedFilterAttributes"
      :transform-items="transformSelectedRefinements"
      :class-names="{ 'ais-CurrentRefinements--noRefinement': 'ds-hidden' }"
      class="ds-flex ds-gap-2 ds-flex-wrap"
      data-testid="filterApplied"
    >
      <template #default="{ items }">
        <template
          v-for="(item, itemIdx) in items"
          :key="itemIdx"
        >
          <AkButton
            v-for="(refinement, refinementIdx) in item.refinements"
            :key="refinementIdx"
            symbol="x"
            symbol-position="right"
            color="success-alt"
            style="height: 1.5rem"
            @click="item.refine(refinement)"
          >
            <span class="ds-font-semibold"> {{ refinement.label }}</span>
          </AkButton>
        </template>
      </template>
    </AisCurrentRefinements>
    <AisClearRefinements :included-attributes="selectedFilterAttributes">
      <template #default="{ canRefine, refine }">
        <AkButton
          v-if="canRefine"
          style="height: auto"
          :link="true"
          data-testid="clearFilterApplied"
          @click="refine"
        >
          {{ $t('retailer.catalog.filterSidebar.selectedFilters.cta.clear') }}
        </AkButton>
        <AkParagraph
          v-else
          size="sm"
          class="ds-text-neutral-700"
        >
          {{ $t('retailer.catalog.filterSidebar.selectedFilters.lbl.empty') }}
        </AkParagraph>
      </template>
    </AisClearRefinements>
  </div>
</template>

<script lang="ts">
export default { name: 'SelectedFilters' };
</script>
<script setup lang="ts">
import { computed } from 'vue';
import { AisCurrentRefinements, AisClearRefinements } from 'vue-instantsearch/vue3/es';
import { Facet, FacetGroup, FacetsType } from '@bc/discovery/domain/catalog';
import { Item } from '@bc/discovery/ui/filters';
import { LabelOverrides } from '../../types/label-overrides';
import { getLabel } from '@bc/discovery/feature/catalog';
import { Refinement } from '../../types/refinement';
import { AkButton, AkParagraph } from '@ankorstore/design-system';
import { formatPrice } from '@/utilities/price';
import useCurrency from '@/composables/use-currency';
import useGlobals from '@/composables/use-globals';

const props = withDefaults(
  defineProps<{
    facets: (Facet | FacetGroup)[];
    labelOverrides?: LabelOverrides;
  }>(),
  {
    labelOverrides: () => ({}),
  }
);

const selectedFilterAttributes = computed(() => {
  const mapFacet = (facet: Facet | FacetGroup) => {
    if ('facets' in facet) {
      return facet.facets.map(mapFacet);
    }
    return facet.name;
  };
  return props.facets.filter((facet) => 'facets' in facet || facet.type !== 'hierarchical').flatMap(mapFacet);
});
const { $currency } = useCurrency();
const { lang } = useGlobals();
const createLabel = (facet: Facet, item: Item) => {
  if ('attributeType' in facet && ['integer', 'number'].includes(facet.attributeType)) {
    return `${facet.title}: ${item.label} ${facet.unit}`;
  }

  if (facet.type === FacetsType.priceRange) {
    // The label is formatted by AIS as '> 1000', where the number is in cents.
    // We need to format it and add the currency symbol.
    return `${item.label.replace(/(\d+)/, (price: string) =>
      formatPrice({ amount: Number(price), currency: $currency.value }, '$0', lang)
    )}`;
  }

  if (facet.type === FacetsType.numeric) {
    return `${item.label} ${facet.unit}`;
  }

  if (facet.type === FacetsType.boolean) {
    return facet.title;
  }

  return getLabel(props.labelOverrides, facet, item.label);
};
const customiseLabelsByFacet = (item: Refinement) => {
  const findFacet = (facets: (Facet | FacetGroup)[]): Facet | undefined => {
    for (const facet of facets) {
      if ('facets' in facet) {
        const found = findFacet(facet.facets);
        if (found) {
          return found;
        }
      } else if (facet.name === item.attribute) {
        return facet;
      }
    }
    return undefined;
  };

  const facet = findFacet(props.facets);
  if (facet) {
    item.refinements.forEach((refinement) => {
      refinement.label = createLabel(facet, refinement);
    });
  }
  return item;
};
const transformSelectedRefinements = (items: Refinement[]) => {
  items.forEach((item) => customiseLabelsByFacet(item));
  return items;
};
</script>
