<script lang="ts">
import BrandLogo from '@/components/brand/brand-logo.vue';
import { defineComponent, PropType, computed } from 'vue';
import { AkParagraph } from '@ankorstore/design-system';
import { isEnabled } from '@/services/features';
import { useStore } from '@/composables/use-store';
import { Retailer } from '@/types/api/retailer';
import { FeatureFlag } from '@/services/features';

export default defineComponent({
  name: 'BrandSnippetContent',
  components: {
    AkParagraph,
    BrandLogo,
  },
  props: {
    logo: {
      type: String,
      required: true,
    },
    name: {
      type: String,
      required: true,
    },
    featured: {
      type: Boolean,
      required: false,
      default: false,
    },
    brandTags: {
      type: Array as PropType<string[]>,
      required: false,
      default: () => [],
    },
    lastOrderDate: {
      type: Date,
      required: false,
      default: null,
    },
    userIsRetailer: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  setup(props) {
    const store = useStore();

    const ankorstorePlus = computed(() => {
      return store.getters['offers/ankorstorePlus'];
    });

    const retailer = computed((): Retailer | null => {
      return store.getters['retailer'];
    });

    const shouldShowFirstOrder = computed(() => {
      return (
        isEnabled(FeatureFlag.EnableNewBusinessModel) &&
        !props.lastOrderDate &&
        !ankorstorePlus.value?.optIn &&
        props.userIsRetailer &&
        retailer.value?.is_eu_8
      );
    });

    return {
      shouldShowFirstOrder,
      isEnabled,
    };
  },
});
</script>

<template>
  <figcaption
    :class="{ 'is-featured': featured }"
    class="brand-content ds-relative"
  >
    <div class="brand-content__name-container">
      <div class="brand-content__name">
        <AkParagraph
          size="sm"
          weight="bold"
          class="brand-name"
        >
          {{ name }}
        </AkParagraph>
      </div>
    </div>
    <div
      v-if="$slots.badges"
      class="ds-flex ds-gap-1 ds-flex-wrap ds-mt-1"
      :class="{ 'ds-flex-col': isMobile }"
    >
      <slot name="badges"></slot>
    </div>
    <BrandLogo
      v-if="isEnabled('rcb-467')"
      class="brand-logo"
      :logo="logo"
      :alt="name"
    />
    <AkParagraph
      v-if="shouldShowFirstOrder"
      class="brand-content__first-order"
      data-testid="firstOrderInfo"
      size="xs"
      weight="bold"
    >
      {{ $t('brandSnippetContent.firstOrder') }}
    </AkParagraph>
  </figcaption>
</template>

<style lang="scss" scoped>
@import '@css/vue-import';

.badge-container {
  &--reliable {
    margin-top: -2px;
  }
  &__badge {
    @apply ds-inline-block;
    &--reliable {
      @apply ds-bg-primary ds-text-accent-alt ds-rounded-xl ds-flex ds-justify-center ds-items-center;
      width: 14px;
      height: 14px;
      padding: 2px;
    }
  }
}

.award-icon {
  font-size: 10px;
}

.brand-logo {
  @apply ds-absolute;

  top: -24px;
  right: 16px;
  @include media-breakpoint-up(sm) {
    top: -36px;
  }
}

.brand-snippet figure figcaption {
  @apply ds-bg-warm-white;
  font-size: 14px;
  line-height: 16px;
  padding: 20px 10px;
  &.is-featured {
    padding: 12px 10px;
  }

  &.brand-content {
    @apply ds-bg-white ds-text-left;
    padding: 20px 16px;
    .brand-name {
      @apply ds-text-primary ds-mb-0;
      display: -webkit-box;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
    }
  }
}

.brand-content {
  &__name-container {
    @apply ds-overflow-hidden ds-m-0 ds-pr-7 md:ds-pr-8 md:ds-text-base;
  }
  &__name {
    @apply ds-flex ds-gap-1;
  }
  &__first-order {
    @apply ds-text-info-700 ds-normal-case ds-mt-1;
  }
}
</style>
