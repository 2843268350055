<template>
  <div class="faq">
    <AkAccountHeading1
      v-if="isMobile"
      class="faq__title"
      :class="['faq__title--' + alignement]"
    >
      <template v-if="faq && faq.overridetitle">
        {{ faq.overridetitle }}
      </template>
      <template v-else>
        {{ $t('faq.title') }}
      </template>
    </AkAccountHeading1>
    <AkAccountHeading2
      v-else
      class="faq__title"
      :class="['faq__title--' + alignement]"
    >
      <template v-if="faq && faq.overridetitle">
        {{ faq.overridetitle }}
      </template>
      <template v-else>
        {{ $t('faq.title') }}
      </template>
    </AkAccountHeading2>
    <FaqContent
      v-if="notEmpty"
      :content="faq"
      :collapser="collapser"
      :size="size"
      :context="context"
      @click-event="clickEvent"
    />
    <div
      v-if="showMore"
      class="faq__section"
    >
      <div class="faq__title--decorative">
        <AkParagraph size="lg">
          {{ $t('faq.more') }}
        </AkParagraph>
      </div>
      <AkButton
        size="xl"
        class="faq__button faq__button--centered"
        data-testid="faqRedirectionBtn"
        @on-click="goToLink(faq.faq_link)"
      >
        {{ $t('faq.cta') }}
      </AkButton>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent, ref, computed, onMounted } from 'vue';
import { AkParagraph, AkButton, AkAccountHeading1, AkAccountHeading2 } from '@ankorstore/design-system';
import FaqContent from '@/modules/shared/components/faq/faq-content.vue';

import usePrismic from '@/composables/use-prismic';
import { captureLoyaltyException } from '@/sentry/helper';
import { useBreakpoints } from '@/modules/design-system-candidates';
import { getGlobalConfig } from '@/services/global-config';

import { FaqInterface } from '@/types/faq';

export default defineComponent({
  name: 'FaqContainer',
  components: {
    AkParagraph,
    AkButton,
    AkAccountHeading1,
    AkAccountHeading2,
    FaqContent,
  },
  props: {
    faqName: {
      type: String,
      required: true,
    },
    collapser: {
      type: Boolean,
      required: false,
      default: false,
    },
    alignement: {
      type: String as () => 'left' | 'center' | 'right',
      required: false,
      default: 'left',
    },
    showMore: {
      type: Boolean,
      required: false,
      default: true,
    },
    size: {
      type: String as () => 'small' | 'base' | 'large',
      required: false,
      default: 'base',
    },
    context: {
      type: String as () => 'home' | 'default',
      required: false,
      default: 'default',
    },
    defaultContent: {
      type: Object as () => FaqInterface,
      required: false,
      default: null,
    },
  },
  emits: ['click-event'],
  setup(props, { emit }) {
    const faq = ref<FaqInterface | null>(null);

    const { isMobile } = useBreakpoints();
    const { client } = usePrismic();
    const locale = getGlobalConfig().language_config.prismic_locale;

    const notEmpty = computed(() => {
      return !!faq.value;
    });

    function goToLink(url): void {
      window.open(url, '_blank');
    }

    function clickEvent(): void {
      emit('click-event');
    }

    async function getFaq(type: string, name: string): Promise<void> {
      try {
        const response = await client.getByUID(type, name, {
          lang: locale,
        });
        faq.value = response.data as FaqInterface;
      } catch (error) {
        captureLoyaltyException(error);
      }
    }

    onMounted(async () => {
      if (props.defaultContent) {
        faq.value = props.defaultContent;
        return;
      } else {
        await getFaq('faq_section', props.faqName);
      }
    });

    return {
      isMobile,
      faq,
      notEmpty,
      clickEvent,
      getFaq,
      goToLink,
    };
  },
});
</script>

<style lang="scss" scoped>
.faq {
  @apply ds-mt-6 md:ds-mt-7 ds-pt-4 ds-mb-6;

  &__title {
    @apply ds-mb-2 md:ds-mb-5;
    &--decorative {
      @apply ds-relative ds-text-center ds-mb-5;
      p {
        @apply ds-px-3 ds-bg-warm-white ds-inline-block ds-mx-auto ds-relative;
        z-index: 2;
      }
      &:after {
        @apply ds-block ds-absolute ds-top-1/2 ds-w-full ds-bg-neutral-700;
        content: '';
        height: 1px;
        z-index: 1;
      }
    }
    &--left {
      @apply ds-text-left;
    }
    &--center {
      @apply ds-text-center;
    }
    &--right {
      @apply ds-text-right;
    }
  }
  &__button {
    @apply ds-text-base;
    &--centered {
      @apply ds-mx-auto;
    }
  }
  .faq__button.faq__button--centered {
    @apply ds-block;
  }
}
</style>
