import http from '@/services/api/http';
import { parseISO } from 'date-fns';
import { captureLoyaltyException } from '@/sentry/helper';
import { Amount } from '@/types/amount';

// This part need to be removed after the migration to the generated client API

export enum OfferProgramType {
  Loyalty = 'loyalty',
  AnkorstorePlus = 'ankorstore_plus',
}

export enum OfferType {
  Discount = 'discount',
  ShippingFees = 'shipping_fees',
  FreeTrial = 'free_trial',
  ExtendedPaymentTerms = 'extended_payment_terms',
  HeavyBulkFees = 'heavy_bulk_fees',
}

export interface OfferProgramEligibilityOffer {
  type: OfferType;
  pmsId: number;
  OfferProgramType: OfferProgramType;
  discount?: number;
  duration?: number;
  durationType?: string;
}

export interface OfferProgramSubscription {
  price: Amount;
  status: string;
  currentPeriodEnd?: Date;
  currentPeriodStart?: Date;
  billingDate?: Date;
  subscribedAt?: Date;
  canceledAt?: Date;
}

export interface OfferProgramEligibility {
  id: string;
  name: string;
  type: string;
  startDate: Date;
  endDate: Date;
  optIn: boolean;
  display: boolean;
  offers: OfferProgramEligibilityOffer[];
  subscription: OfferProgramSubscription | null;
  savings?: OfferSavingsData;
  potential_savings?: OfferPotentialSavingsData;
  freeTrialEligible?: boolean;
}

export interface OfferSavingsData {
  discount: Amount;
  nb_net_90_checkouts: number;
  shipping_fees: Amount;
  total_amount: Amount;
  nb_brands_repeat_offer?: number;
  nb_brands_48H?: number;
}

export interface OfferPotentialSavingsData {
  total_amount: Amount;
  shipping_fees: Amount;
  nb_brands_repeat_offer?: number;
  net90: boolean;
  nb_brands_48H?: number;
}

export interface OfferProgramOptIn {
  redirectUrl: string;
}

export interface OfferProgramOptOutApiResponse {
  data: {
    meta?: {
      redirect_url: string;
    };
    data?: {
      type?: string;
      id?: string;
      attributes?: {
        name?: string;
        type?: string;
        start_date?: string;
        end_date?: string;
        optin?: boolean;
      };
      links?: {
        self?: string;
      };
    };
  };
}

export interface OfferProgramOptInApiResponse {
  data: {
    meta?: {
      redirect_url: string;
    };
    data?: {
      // This interface and al
      type?: string;
      id?: string;
      attributes?: {
        name?: string;
        type?: string;
        start_date?: string;
        end_date?: string;
        optin?: boolean;
      };
      links?: {
        self?: string;
      };
    };
  };
}

export interface OfferProgramEligibilityApiResponse {
  data: {
    data: [
      {
        id: string;
        attributes: {
          name: string;
          type: string;
          start_date: string;
          end_date: string;
          optin: boolean;
          display: boolean;
          offers: OfferProgramEligibilityOffer[];
          subscription: OfferProgramSubscription;
          savings: OfferSavingsData;
          potential_savings: OfferPotentialSavingsData;
          free_trial_eligible: boolean;
        };
      },
    ];
  };
}

export const offerProgrammesV1 = async (): Promise<OfferProgramEligibility[]> => {
  try {
    const {
      data: { data },
    }: OfferProgramEligibilityApiResponse = await http().get('/api/internal/v1/offer-programmes/eligibles');

    const response = data.map(
      ({
        id,
        attributes: {
          name,
          type,
          start_date,
          end_date,
          optin,
          display,
          offers,
          subscription,
          savings,
          potential_savings,
          free_trial_eligible,
        },
      }) => ({
        id,
        name,
        type,
        startDate: parseISO(start_date),
        endDate: parseISO(end_date),
        optIn: optin,
        display,
        offers,
        subscription,
        savings,
        potential_savings,
        freeTrialEligible: free_trial_eligible,
      })
    );

    return [...response];
  } catch (e) {
    if (e?.response?.status !== 503) {
      captureLoyaltyException(e);
    }

    return null;
  }
};

export const offerProgramOptIn = async (id: string): Promise<OfferProgramOptIn> => {
  try {
    const { data }: OfferProgramOptInApiResponse = await http().post(`/api/internal/v1/offer-programmes/${id}/optins`);

    return {
      redirectUrl: data?.meta?.redirect_url,
    };
  } catch (e) {
    captureLoyaltyException(e);

    return null;
  }
};

export const offerProgramOptOut = async (id: string): Promise<OfferProgramOptIn> => {
  try {
    const { data }: OfferProgramOptOutApiResponse = await http().post(`/api/internal/v1/offer-programmes/${id}/optouts`);

    return {
      redirectUrl: data?.meta?.redirect_url,
    };
  } catch (e) {
    captureLoyaltyException(e);

    return null;
  }
};
