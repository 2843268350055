import { AxiosError } from 'axios';
import { captureMonetizationException } from '@/sentry/helper';

type HttpError = {
  isAxiosError: true;
  message: '';
  name: '';
  toJSON: () => object;
};

export function handleAdsHttpError(error: AxiosError<HttpError>, label: string): void {
  captureMonetizationException(error, [{ label, value: error.response?.data?.message }]);
}
