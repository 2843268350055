import { Mutation } from '@/store/mutation-types';

import account from '@/store/account';
import legacyAccount from '@/store/legacy-account';
import cart, { CART_STORE_ID } from '@/store/cart';
import cartPreorder, { CART_PREORDER_STORE_ID } from '@/store/cart-preorder';
import offers, { OFFERS_STORE_ID } from '@/store/offers';
import shop, { SHOP_STORE_ID } from '@/store/shop';
import env from '@/store/env';
import conversations from '@/store/conversations';
import orderRetailerRejection from '@/store/order-retailer-rejection';
import salesforce from '@/store/salesforce';
import global from '@/store/global';
import wishlist from '@/store/wishlist';
import headerBadges from '@/store/headerBadges';
import wallet from '@/store/wallet';
import { SearchStore, SEARCH_STORE_ID } from '@bc/discovery';
import { BrandDashboardStore as brandDashboardStore } from '@bc/advertisement';

import drawer from '@/store/drawer';
import reviews from '@/store/reviews';
import discountInfo from '@/store/discount-info';
import signUp from '@/store/sign-up';

import ProductUnlikedEvent from '@/services/analytics/events/product-unliked-event';
import Analytics from '@/services/analytics';
import BrandUnlikedEvent from '@/services/analytics/events/brand-unliked-event';
import Category, { Categories } from '@/types/category';
import Dinero, { Currency } from 'dinero.js';
import { seeYouSoonOfferMinimumBrands } from '@/mixins/promocode';
import Product from '@/types/product';
import { CurrencyRates, State } from './state';
import { getInitialState } from '@/services/initial-state';
import { mutations as mutationsModule } from './mutations';
import { Retailer } from '@/types/api/retailer';
import UserClick from '@/services/analytics/events/user-click.event';
import { Brand } from '@/types/api/brand';
import http from '@/services/api/http';
import { AdditionalLikeTrackingData } from '@/types/analytics/recommendation-tracking';
import { UserType } from '@/types/brand-onboarding';
import { User } from '@/types/user';
import { InvitedStatusValue } from '@/types/r2b';
import { createStore } from 'vuex';
import { getCurrency } from '@/services/metas/currency';

const state: State = {
  categories: {
    entities: {},
    order: [],
  },
  products: {},
  staff: false,
  isSignupShown: false,
  user: null,
  retailer: null,
  stripe: null,
  progressBar: 'hide',
  previousScrollY: 0,
  likes: {
    productIds: null,
    brandIds: null,
  },
  offers: {
    events: [],
  },
  country: null,
  brand: null,
  currency: null,
  currenciesRates: null,
  isEligibleLiftDayForFreeShipping: false,
  isEligiblePreshowForFreeShipping: false,
  showRequestedInformationForm: false,
  measurementUnits: {
    fetching: false,
    unitList: [],
  },
  ...getInitialState(),
};
if (state.user) {
  const lastProductLikesDate = parseInt(localStorage.getItem(state.user.id + '/lastProductLikesDate'), 10);
  const productLikes = localStorage.getItem(state.user.id + '/productLikes');
  if (lastProductLikesDate && productLikes && Date.now() - lastProductLikesDate < 86400) {
    state.likes.productIds = JSON.parse(productLikes);
  }

  const lastBrandLikesDate = parseInt(localStorage.getItem(state.user.id + '/lastBrandLikesDate'), 10);
  const brandLikes = localStorage.getItem(state.user.id + '/brandLikes');
  if (lastBrandLikesDate && brandLikes && Date.now() - lastBrandLikesDate < 86400) {
    state.likes.brandIds = JSON.parse(brandLikes);
  }
}

state.staff = document.cookie.split(';').some((item) => item.trim().includes('ankorStaff=1'));

export const actions = {
  clearBrandPendingAction({ dispatch, state }, categorie: string) {
    return dispatch('updateBrand', {
      brand: {
        ...state.brand,
        pending_actions: {
          ...Object.fromEntries(Object.entries(state.brand.pending_actions).filter(([key]) => key !== categorie)),
        },
      },
    });
  },
  updateBrand({ commit }, { brand }) {
    commit('REPLACE_BRAND', brand);
  },
  async likeProduct(
    { commit, getters, dispatch },
    {
      id,
      additionalTrackingData,
      disableTracking,
    }: { id: number; additionalTrackingData?: AdditionalLikeTrackingData; disableTracking?: boolean }
  ) {
    if (!getters.user) {
      commit(Mutation.REPLACE_PRODUCT_LIKES, []);
      return;
    }

    commit(Mutation.ADD_PRODUCT_LIKE, id);

    try {
      await dispatch('wishlist/products/addLikedProductToDefaultWishlist', { products: [id] });
    } catch {
      await dispatch('fetchProductLikes');
    }

    if (disableTracking) {
      return;
    }

    let trackingData = {
      id,
      component: null,
      action: 'product_liked',
      item_type: 'product',
    };
    if (additionalTrackingData) {
      trackingData = { ...trackingData, ...additionalTrackingData };
    }

    await Analytics.track(new UserClick(trackingData));
  },
  async unLikeProduct(
    { commit, getters, dispatch },
    { id, sectionId, disableTracking }: { id: number; sectionId?: string; disableTracking?: boolean }
  ) {
    if (!getters.user) {
      commit(Mutation.REPLACE_PRODUCT_LIKES, []);
      return;
    }

    commit(Mutation.REMOVE_PRODUCT_LIKE, id);

    try {
      await dispatch('wishlist/products/removeUnlikedProductFromWishlist', { productId: id });
    } catch {
      await dispatch('fetchProductLikes');
    }

    const product = getters.productById(id);
    if (disableTracking || !product) {
      return;
    }
    await Analytics.track(
      new ProductUnlikedEvent(
        {
          id,
          name: product.name,
          wholesalePrice: product.wholesale_price,
        },
        sectionId
      )
    );
  },
  fetchProductLikes({ commit, getters }) {
    if (!getters.user || !getters.userIsRetailer) {
      commit(Mutation.REPLACE_PRODUCT_LIKES, []);
      return Promise.resolve();
    }
    return http()
      .get(`/api/wishlists/all/products`, {
        noProgressBar: true,
      })
      .then(({ data }) => {
        commit(Mutation.REPLACE_PRODUCT_LIKES, data.data);
      });
  },
  async likeBrand(
    { commit, getters },
    { id, sectionId, disableTracking }: { id: number; sectionId?: string; disableTracking?: boolean }
  ): Promise<void> | null {
    if (!getters.user) {
      commit(Mutation.REPLACE_BRAND_LIKES, []);
      return null;
    }
    await http().put(`/api/likes/brand/${id}`);
    commit(Mutation.ADD_BRAND_LIKE, id);

    if (disableTracking) {
      return;
    }

    const trackingData = {
      id,
      component: null,
      action: 'brand_liked',
    };
    if (sectionId) {
      trackingData['id_section'] = sectionId;
    }
    await Analytics.track(new UserClick(trackingData));
  },
  async unLikeBrand(
    { commit, getters },
    { id, sectionId, disableTracking }: { id: number; sectionId?: string; disableTracking?: boolean }
  ) {
    if (!getters.user) {
      commit(Mutation.REPLACE_BRAND_LIKES, []);
      return;
    }
    await http().delete(`/api/likes/brand/${id}`);
    commit(Mutation.REMOVE_BRAND_LIKE, id);

    if (disableTracking) {
      return;
    }
    await Analytics.track(new BrandUnlikedEvent({ id }, sectionId));
  },
  fetchBrandLikes({ commit, getters }) {
    if (!getters.user) {
      commit(Mutation.REPLACE_BRAND_LIKES, []);
      return;
    }
    http()
      .get(`/api/likes/brand`)
      .then(({ data }) => {
        commit(Mutation.REPLACE_BRAND_LIKES, data.data);
      });
  },
  async fetchProduct({ commit }, { product_id }): Promise<Product> {
    const {
      data: { data: product },
    } = await http().get(`/api/products/${product_id}`);

    commit(Mutation.STORE_PRODUCT, {
      ...product,
      refreshed: true,
    });

    return product;
  },
  async storeProductsFromAlgolia({ commit, getters }, { products }) {
    products = await Promise.all(
      products.map(async (product) => {
        product.original_wholesale_price.amount = Math.round(product.original_wholesale_price.amount);
        product.wholesale_price.amount = Math.round(product.wholesale_price.amount);
        product.retail_price.amount = Math.round(product.retail_price.amount);
        const wholeSalePriceConverted = await Dinero(product.wholesale_price).convert(getCurrency(), {
          endpoint: new Promise((resolve) =>
            resolve(getters.getCurrencyRate(product.original_wholesale_price.currency, getCurrency()))
          ),
        });
        const originalWholeSalePriceConverted = await Dinero(product.original_wholesale_price).convert(getCurrency(), {
          endpoint: new Promise((resolve) => resolve(getters.getCurrencyRate(product.wholesale_price.currency, getCurrency()))),
        });
        const retailPriceConverted = await Dinero(product.retail_price).convert(getCurrency(), {
          endpoint: new Promise((resolve) => resolve(getters.getCurrencyRate(product.retail_price.currency, getCurrency()))),
        });
        return {
          ...product,
          wholesale_price: wholeSalePriceConverted.toObject(),
          original_wholesale_price: originalWholeSalePriceConverted.toObject(),
          retail_price: retailPriceConverted.toObject(),
        };
      })
    );

    commit(
      Mutation.STORE_PRODUCTS,
      products.map((product) => ({ ...product, refreshed: false }))
    );
  },
  replaceRetailer({ commit }, retailer) {
    commit(Mutation.REPLACE_RETAILER, retailer);
  },
  replaceBrand({ commit }, { brand }) {
    commit(Mutation.REPLACE_BRAND, brand);
  },
  showProgressBar({ commit }) {
    commit('SET_PROGRESS_BAR_STATE', 'loading');
  },
  endProgressBar({ commit }) {
    commit('SET_PROGRESS_BAR_STATE', 'ending');
  },
  hideProgressBar({ commit }) {
    commit('SET_PROGRESS_BAR_STATE', 'hide');
  },
  async toggleMonthlyFollowup({ commit }, monthly_follow_up: boolean): Promise<void> {
    try {
      commit('SET_BRAND_MONTHLY_FOLLOWUP', monthly_follow_up);
      await http().post(`/api/me/brand/referral/monthly-follow-up`, {
        monthly_follow_up,
      });
      return Promise.resolve();
    } catch (_error) {
      commit('SET_BRAND_MONTHLY_FOLLOWUP', !monthly_follow_up);
      return Promise.reject();
    }
  },
  hideRequestedInformation({ commit }) {
    commit('HIDE_REQUESTED_INFORMATION');
  },
  showSignup({ commit }) {
    commit('SHOW_SIGNUP');
  },
};

export const getters = {
  getCurrencyRate:
    (state: State) =>
    (from: Currency, to: Currency): CurrencyRates => ({
      date: state.currenciesRates.date,
      rates: {
        [to]: from !== to ? state.currenciesRates.rates[from][to] : 1,
      },
    }),
  isStaff: (state: State): boolean => state.staff,
  isSignupShown: (state: State): boolean => state.isSignupShown,
  retailer: (state: State): Retailer => state.retailer,
  brand: (state: State): Brand => state.brand,
  user: (state: State): User => state.user,
  isUserAuthenticated: (state: State): boolean => Boolean(state.user),
  getUserType: (state: State): UserType => {
    if (!state.user) {
      return 'guest';
    }
    if (state.brand) {
      return 'brand';
    }
    return 'retailer';
  },
  shouldApplyCountryRestrictions: (_state, getters) =>
    // we apply country restrictions only if
    // 1. the user is not logged-in
    (!getters.user ||
      // 2. or the user is a retailer in an opened-to-buy country
      (getters.userIsRetailer && getters.retailer.country.opened_to_retailers)) &&
    // 3 and is not a beloved colleague
    !getters.isStaff,
  stripe: (state: State): any => state.stripe,
  hasPendingActions:
    (state: State) =>
    (categories: any[]): boolean => {
      let pendingActions = {};

      if (state.brand) {
        pendingActions = state.brand.pending_actions;
      } else if (state.retailer) {
        pendingActions = state.retailer.pending_actions;
      }

      if (categories.length === 0) {
        // If the category list is empty, we're check for any action categories
        return Object.keys(pendingActions).length > 0;
      }

      // else, the function wll return true as soon as one given category matches in the pending actions
      return categories.some((category) => {
        return Object.prototype.hasOwnProperty.call(pendingActions, category);
      });
    },
  productLikesAreInitialized: (state: State): boolean => state.likes.productIds !== null,
  brandLikesAreInitialized: (state: State): boolean => state.likes.brandIds !== null,
  userIsRetailer: (state: State): boolean => !!state.retailer,
  userIsBrand: (state: State): boolean => !!state.brand,
  userIsCandidateBrand: (state: State): boolean =>
    !!state.user?.business?.brand?.candidate_brand &&
    state.user?.business?.brand?.candidate_brand.status != InvitedStatusValue.Accepted,
  canGetWholesalePrice: (state: State): boolean => Boolean(state.retailer?.can_get_wholesale_price),
  hasUnhandledCountry: (state: State): boolean => state.retailer && !state.retailer.country.opened_to_retailers,
  productIsLiked:
    (state: State) =>
    (productId: number): boolean =>
      state.likes.productIds && state.likes.productIds.includes(productId),
  getLikedBrands: (state: State): number[] => state.likes.brandIds,
  brandIsLiked:
    (state: State) =>
    (brandId: number): boolean =>
      state.likes.brandIds && state.likes.brandIds.includes(brandId),
  productById:
    (state: State) =>
    (id: number): Product =>
      state.products[id],
  productByOptionId:
    (state: State) =>
    (id: number): any =>
      Object.values(state.products).find((product: any) => product.options.find((option) => id == option.id)),
  productByVariantUuid:
    (state: State) =>
    (uuid: string): any =>
      Object.values(state.products).find((product: any) => product.variants.find((variant) => uuid == variant.uuid)),
  userCountry: (state: State): string => (state.user ? state.user.business.country.iso_code : state.country),
  userCreationDate: (state: State): string => state.user?.created_at,
  userCurrency: (state: State): any => state.currency,
  currencyRates: (state: State): CurrencyRates => state.currenciesRates,
  getSiblingCategories:
    (state: State) =>
    (parentId: number | null): Category[] =>
      state.categories.order.map((id) => state.categories.entities[id]).filter((category) => category.parent_id === parentId),
  hasHokodoCompany: (state): boolean => state.retailer.hokodo_company !== null,

  getEligiblesEvents: (state: State): any[] => state.brand.business_events.filter(({ is_eligible }) => is_eligible),
  getNextEligibleEvent: (state: State) => state.brand.business_events.find(({ is_eligible }) => is_eligible),
  retailerNeedsMoreBrandsToUseSeeYouSoonOffer: (_state, _getters, _rootState, rootGetters) => {
    return (
      rootGetters.retailer?.see_you_soon_promocode_active && rootGetters['cart/aboveCarts']?.length < seeYouSoonOfferMinimumBrands
    );
  },
  isEligibleLiftDayForFreeShipping: (state: State): boolean => state.isEligibleLiftDayForFreeShipping,
  isEligiblePreshowForFreeShipping: (state: State): boolean => state.isEligiblePreshowForFreeShipping,
  hasRetailerAlreadyOrdered: (state: State): boolean => state.retailer?.has_already_ordered,
  hasBrandCreatedAnyPreorders: (state: State): boolean => {
    return state.brand && state.brand?.first_preorder_product_available_at !== null;
  },
};

export const mutations = {
  [Mutation.REPLACE_PRODUCT_LIKES](state, likes): void {
    state.likes.productIds = likes;
  },
  [Mutation.ADD_PRODUCT_LIKE](state, id): void {
    state.likes.productIds.push(id);
  },
  [Mutation.REMOVE_PRODUCT_LIKE](state, id): void {
    state.likes.productIds = state.likes.productIds.filter((value) => id != value);
  },
  [Mutation.REPLACE_BRAND_LIKES](state, likes): void {
    state.likes.brandIds = likes;
  },
  [Mutation.ADD_BRAND_LIKE](state, id): void {
    state.likes.brandIds.push(id);
  },
  [Mutation.REMOVE_BRAND_LIKE](state, id): void {
    state.likes.brandIds = state.likes.brandIds.filter((value) => id != value);
  },
  [Mutation.STORE_PRODUCT](state, product): void {
    // https://vuejs.org/v2/guide/list.html#Caveats
    // Vue.set(state.products, product.id, product);
    state.products[product.id] = product;
  },
  [Mutation.STORE_PRODUCTS](state, products): void {
    state.products = {
      ...state.products,
      ...Object.fromEntries(
        products
          .filter(
            // Do not updated a refreshed product with a non-refreshed
            (product) => product.refreshed || !state.products[product.id]?.refreshed
          )
          .map((product) => [product.id, product])
      ),
    };
  },
  [Mutation.REPLACE_RETAILER](state, retailer): void {
    state.retailer = retailer;
  },
  [Mutation.REPLACE_BRAND](state, brand): void {
    state.brand = brand;
  },
  SET_PROGRESS_BAR_STATE(state, value): void {
    state.progressBar = value;
  },
  [Mutation.SET_CATEGORIES](state, { categories, order }: { categories: Categories; order: string[] }): void {
    state.categories.entities = { ...categories };
    state.categories.order = [...order];
  },
  SET_BRAND_MONTHLY_FOLLOWUP(state, isEnabled: boolean) {
    state.brand.monthly_follow_up = isEnabled;
  },
  [Mutation.HIDE_REQUESTED_INFORMATION](state): void {
    state.showRequestedInformationForm = false;
  },
  [Mutation.SHOW_SIGNUP](state): void {
    state.isSignupShown = true;
  },
  ...mutationsModule,
};

const store = createStore({
  state,
  strict: process.env.NODE_ENV !== 'production',
  modules: {
    headerBadges,
    account,
    legacyAccount,
    [CART_STORE_ID]: cart,
    [CART_PREORDER_STORE_ID]: cartPreorder,
    [OFFERS_STORE_ID]: offers,
    env,
    [SHOP_STORE_ID]: shop,
    conversations,
    orderRetailerRejection,
    salesforce,
    global,
    wishlist,
    wallet,
    [SEARCH_STORE_ID]: SearchStore,
    brandDashboardStore,
    drawer,
    reviews,
    discountInfo,
    signUp,
  },
  mutations,
  getters,
  actions,
});

store.subscribe((mutation, state) => {
  if (
    Mutation.REPLACE_PRODUCT_LIKES === mutation.type ||
    Mutation.ADD_PRODUCT_LIKE === mutation.type ||
    Mutation.REMOVE_PRODUCT_LIKE === mutation.type
  ) {
    if (!state.user) {
      return;
    }
    localStorage.setItem(state.user.id + '/lastProductLikesDate', Date.now().toString());
    localStorage.setItem(state.user.id + '/productLikes', JSON.stringify(state.likes.productIds));
  }
  if (
    Mutation.REPLACE_BRAND_LIKES === mutation.type ||
    Mutation.ADD_BRAND_LIKE === mutation.type ||
    Mutation.REMOVE_BRAND_LIKE === mutation.type
  ) {
    if (!state.user) {
      return;
    }
    localStorage.setItem(state.user.id + '/lastBrandLikesDate', Date.now().toString());
    localStorage.setItem(state.user.id + '/brandLikes', JSON.stringify(state.likes.brandIds));
  }
});

export default store;
export type RootState = State;
export type RootGetters = typeof getters;
export type RootActions = typeof actions;
export type RootMutations = typeof mutations;
