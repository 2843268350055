import { Ref, ComputedRef } from 'vue';
import Analytics from '@/services/analytics';
import UserClick from '@/services/analytics/events/user-click.event';
import ViewTile from '@/services/analytics/events/view-tile.event';
import { AdsClickEvent, getNativeAdClickOrigin, trackProductClickWithAardvarkService } from '@bc/advertisement';
import ProductUnlikedEvent from '@/services/analytics/events/product-unliked-event';
import type { ESProductHit, ESSponsoredProductHit } from '../catalog';
import type { ProductProperty } from '@/services/analytics/properties/product-property';
import type { BrandProperty } from '@/services/analytics/properties/brand-property';
import { ProductQuickAddToCartEvent } from '@/services/analytics/events/product-quick-added-to-cart.event';
import { getCustomTrackMergeFn, IdAlgo, SectionType } from '@bc/shared/tracking';
import { SnippetItemType } from './enums';
import type Product from '@/types/product';

type ESAnyProductHit = Product | ESProductHit | ESSponsoredProductHit;

enum ProductSnippetActions {
  CLICKED = 'product_clicked',
  BRAND_CLICKED = 'brand_clicked',
  LIKED = 'product_liked',
  ADS_TOOLTIP_CLICKED = 'ads_tooltip_clicked',
  QATC_CLICKED = 'quick_add_to_cart_clicked',
}

type ProductLocation = {
  position?: number;
  page?: number;
};

const getAdId = (product: ESAnyProductHit) => {
  if ('aardvarkServeId' in product || 'ad' in product) {
    return product.aardvarkServeId || product.ad?.metadata?.ad_id;
  }
  return undefined;
};

export const getProductSnippetTracking = (
  sectionId: Ref<string> | ComputedRef<string>,
  idAlgo: Ref<IdAlgo> | ComputedRef<IdAlgo>,
  component?: Ref<string> | ComputedRef<string>
) => {
  const mergeViewEvents = getCustomTrackMergeFn(['position', 'id_product']);

  const trackProductClicked = async (
    payload: {
      product: ESAnyProductHit;
      eventTarget?: Element;
    } & ProductLocation
  ) => {
    const adId = getAdId(payload.product);
    await Analytics.track(
      new UserClick({
        action: ProductSnippetActions.CLICKED,
        component: adId ? 'native_ads' : component?.value,
        id_section: sectionId.value,
        id_algo: idAlgo.value,
        itemType: SnippetItemType.PRODUCT,
        page_number: payload.page,
        position: payload.position,
        productId: payload.product.id,
      })
    );
    if (adId) {
      await trackProductClickWithAardvarkService(adId, getNativeAdClickOrigin(payload.eventTarget));
    }
  };

  const trackProductBrandClicked = async (
    payload: {
      product: ESAnyProductHit;
    } & ProductLocation
  ) => {
    const adId = getAdId(payload.product);
    await Analytics.track(
      new UserClick({
        action: ProductSnippetActions.BRAND_CLICKED,
        brandId: payload.product.brand.id,
        brandTags: payload.product.brand.brand_tags,
        component: adId ? 'native_ads' : component?.value,
        id_section: sectionId.value,
        id_algo: idAlgo.value,
        itemType: SnippetItemType.PRODUCT,
        page_number: payload.page,
        position: payload.position,
        productId: payload.product.id,
      })
    );
  };

  const trackProductLiked = async (
    payload: {
      product: ESAnyProductHit;
      id_product?: string;
      id_brand?: string;
      isSponsored?: boolean;
    } & ProductLocation
  ) => {
    const adId = getAdId(payload.product);
    await Analytics.track(
      new UserClick({
        action: ProductSnippetActions.LIKED,
        component: adId ? 'native_ads' : component?.value,
        id: payload.product.id,
        id_brand: payload.id_brand,
        id_product: payload.id_product,
        id_section: sectionId.value,
        itemType: SnippetItemType.PRODUCT,
        position: payload.position,
        id_algo: idAlgo.value,
      })
    );
  };

  const trackProductDisliked = async (payload: { product: ESAnyProductHit }) => {
    await Analytics.track(
      new ProductUnlikedEvent(
        {
          id: payload.product.id,
          name: payload.product.name as string,
          wholesalePrice: payload.product.wholesale_price,
        },
        sectionId.value
      )
    );
  };

  const trackQuickAddToCartClicked = async (payload: { product: ESAnyProductHit } & ProductLocation) => {
    const adId = getAdId(payload.product);
    await Analytics.track(
      new UserClick({
        action: ProductSnippetActions.QATC_CLICKED,
        itemType: 'product',
        component: adId ? 'native_ads' : component?.value,
        id_section: sectionId.value,
        id_algo: idAlgo.value,
        page_number: payload.page,
        productId: payload.product.id,
        position: payload.position,
      })
    );
  };

  const trackVariantAddedToCart = async (
    payload: {
      productProperty: ProductProperty;
      brandProperty: BrandProperty;
      quantity: number;
    } & ProductLocation
  ) => {
    await Analytics.track(
      new ProductQuickAddToCartEvent(
        payload.productProperty,
        payload.brandProperty,
        payload.page,
        payload.quantity,
        sectionId.value,
        idAlgo.value
      )
    );
  };

  const trackProductViewed = (
    payload: {
      product: ESAnyProductHit;
      valueProposition: string;
      sectionType: SectionType;
    } & ProductLocation
  ) => {
    Analytics.trackMergeBuffer(
      new ViewTile({
        id_section: sectionId.value,
        id_product: [
          {
            id: payload.product.id,
            position: payload.position,
          },
        ],
        position: payload.position,
        value_proposition: payload.valueProposition,
        section_type: payload.sectionType,
      }),
      mergeViewEvents
    );
  };

  const trackSponsoredProductTooltipClick = async (
    payload: {
      product: ESSponsoredProductHit;
    } & ProductLocation
  ) => {
    await Analytics.track(
      new AdsClickEvent({
        sectionId: sectionId.value,
        component: 'native_ads',
        itemtype: SnippetItemType.PRODUCT,
        action: ProductSnippetActions.ADS_TOOLTIP_CLICKED,
        productId: payload.product.id,
        page_number: payload.page,
        position: payload.position,
      })
    );
  };

  return {
    trackProductClicked,
    trackProductBrandClicked,
    trackProductLiked,
    trackProductDisliked,
    trackQuickAddToCartClicked,
    trackVariantAddedToCart,
    trackProductViewed,
    trackSponsoredProductTooltipClick,
  };
};
