import { CART_STORE_ID } from './constants';
import type { CartState } from '@/store/cart';
import type { CartGetters } from '@/store/cart/getters';
import type { CartActions } from '@/store/cart/actions';
import type { CartMutations } from '@/store/cart/mutations';

import { createNamespacedHelpers } from 'vuex-composition-helpers';

const { useGetters, useActions, useMutations } = createNamespacedHelpers<CartState, CartGetters, CartActions, CartMutations>(
  CART_STORE_ID
);

export default {
  useGetters,
  useActions,
  useMutations,
};
