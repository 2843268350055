<template>
  <div
    v-if="!isCheckoutPage"
    class="search-autocomplete"
    :class="['ds-relative', 'ds-flex', 'ds-flex-col', 'ds-text-sm']"
    data-testid="searchBar"
  >
    <div
      class="search-autocomplete__bar"
      :class="[
        'ds-fixed',
        'sm:ds-static',
        'ds-z-50',
        'ds-flex',
        'ds-items-center',
        'ds-w-full',
        'ds-h-7',
        'md:ds-p-4',
        'sm:ds-border',
        'md:ds-border-neutral-600',
        'md:ds-border-solid',
        'md:ds-rounded-md',
        'ds-shadow-md',
        'md:ds-shadow-none',
        'ds-transition-colors',
        'ds-bg-white',
        'md:ds-bg-neutral-100',
        'md:focus-within:ds-bg-white',
      ]"
    >
      <button
        data-testid="searchAutocompleteBackButton"
        :class="['ds-py-2', 'ds-px-2', 'ds-cursor-pointer', 'md:ds-px-0', 'md:ds-hidden', 'ds-rotate-180']"
        @click="$emit('close')"
      >
        <AkIcon
          size="md"
          symbol="arrow-right"
          :class="['ds-text-neutral-700']"
        />
      </button>
      <input
        ref="search-autocomplete__input"
        v-model="searchInput"
        v-click-outside="handleClickOutside"
        data-testid="SearchBoxInput"
        type="search"
        autocomplete="off"
        :placeholder="$t('Search for a product, brands or a theme')"
        :class="[
          'ds-py-0',
          'ds-flex-1',
          'ds-border-l',
          'ds-border-neutral-300',
          'ds-border-solid',
          'ds-p-2',
          'ds-bg-white',
          'md:ds-order-2',
          'md:ds-border-none',
          'focus:ds-outline-none',
          'sm:ds-bg-transparent',
        ]"
        @input="onChange"
        @keydown.down="onArrowDown"
        @keydown.up="onArrowUp"
        @keydown.enter="onEnter"
        @keydown.esc="onEsc"
        @focus="onFocus"
        @blur="onBlur"
      >
      <button
        data-testid="searchAutocompleteClearButton"
        :class="['ds-py-2', 'ds-px-2', 'ds-cursor-pointer', 'md:ds-px-0', 'md:ds-order-3', 'md:ds-border-none']"
        @click="clearSearch"
      >
        <AkIcon
          v-if="searchInput"
          size="md"
          symbol="x"
          :class="['ds-text-neutral-700']"
        />
      </button>
      <button
        data-testid="searchAutocompleteSearchButton"
        :class="[
          'ds-py-2',
          'ds-px-2',
          'ds-cursor-pointer',
          'md:ds-px-0',
          'ds-border-l',
          'ds-border-neutral-300',
          'ds-border-solid',
          'md:ds-order-1',
          'md:ds-border-none',
        ]"
        @click="onClickSeeAllResults"
      >
        <AkIcon
          size="md"
          symbol="search"
          :class="['ds-text-neutral-700']"
        />
      </button>
    </div>
    <SearchAutocompleteSuggestions
      v-if="shouldDisplaySuggestions"
      :key="`search-autocomplete-suggestions-${suggestionsKey}`"
      :class="['ds-pt-8', 'sm:ds-pt-4', 'md:ds-pt-0', 'xl:ds-absolute', 'xl:ds-top-7', 'md:ds-mt-0.5']"
      :search-input="searchInput"
      :current-selection-id="currentSelectionId"
      @click-see-all-results="onClickSeeAllResults"
      @click-r2b-link="onClickR2BLink"
    />
    <div
      v-if="shouldDisplaySuggestions && isDesktop"
      data-testid="searchAutocompleteOverlay"
      :class="[
        'ds-hidden',
        'ds-w-full',
        'ds-h-full',
        'ds-top-0',
        'ds-left-0',
        'ds-right-0',
        'ds-bottom-0',
        'lg:ds-block',
        'lg:ds-bg-primary',
        'lg:ds-opacity-50',
        'lg:ds-z-10',
        'lg:ds-fixed',
      ]"
      @click="resetCurrentResults"
    />
  </div>
</template>

<script lang="ts">
import { ClickOutside } from '@ankorstore/vue-click-outside';
import { mapActions, mapGetters, mapMutations } from 'vuex';
import SearchAutocompleteSuggestions from './search-autocomplete-suggestions.vue';
import {
  RESET_ARROW_POSITION,
  SET_ARROW_POSITION_DOWN,
  SET_ARROW_POSITION_UP,
  SET_ORIGINAL_USER_INPUT,
  SET_RESULTS_VISIBILITY,
} from '../store/mutation-types';
import {
  BROWSE_TO_RESULT,
  DO_RESET_RESULTS,
  FETCH_RESULTS_ELASTIC,
  HANDLE_CLICK_ALL_RESULTS,
  HANDLE_ITEM_CLICK,
  HANDLE_R2B_CLICK,
  INIT_RESULTS,
  SAVE_RECENT_SEARCH,
  SET_RECENT_SEARCH_FOR_QUERY,
} from '../store/action-types';
import { R2B_LINK_HREF, R2B_LINK_ID, SHOW_ALL_ID } from '../store';
import { withBreakpoints } from '@/modules/design-system-candidates';
import { debounce } from 'lodash-es';
import { getSearchClient } from '@bc/discovery/domain/search';
import { defineComponent } from 'vue';
import { AkIcon } from '@ankorstore/design-system';
import useIsCheckout from '@/composables/use-enclosed-checkout';

let unsubscribe;
export default defineComponent({
  name: 'SearchAutoComplete',
  components: {
    SearchAutocompleteSuggestions,
    AkIcon,
  },
  directives: {
    ClickOutside,
  },
  mixins: [withBreakpoints],
  props: {
    searchquery: {
      type: String,
      required: false,
      default: '',
    },
    prefix: {
      type: String,
      required: false,
      default: '',
    },
  },
  emits: ['close'],
  setup() {
    const { isCheckoutPage } = useIsCheckout();

    return { isCheckoutPage };
  },
  data() {
    return {
      algoliaClient: null,
      searchInput: this.searchquery,
      isFocused: false,
      suggestionsKey: 0,
    };
  },
  computed: {
    ...mapGetters({
      arrowPosition: 'searchBar/arrowPosition',
      resultsIds: 'searchBar/resultsIds',
      isOpen: 'searchBar/isOpen',
      originalUserInput: 'searchBar/originalUserInput',
      currentSelectionId: 'searchBar/currentSelectionId',
      currentSelectionName: 'searchBar/currentSelectionName',
      currentSelectedItem: 'searchBar/currentSelectedItem',
      hasSuggestions: 'searchBar/hasSuggestions',
    }),
    shouldDisplaySuggestions(): boolean {
      return (!this.isDesktop || this.isOpen) && this.hasSuggestions;
    },
  },
  watch: {
    arrowPosition(nextPosition, oldPosition) {
      if (nextPosition !== oldPosition) {
        this.updateCurrentQuery();
      }
    },
  },
  created(): void {
    this.algoliaClient = getSearchClient();
    this[SET_ORIGINAL_USER_INPUT](this.searchquery);
    this[INIT_RESULTS]();
  },
  mounted(): void {
    unsubscribe = this.$store.subscribeAction({
      after: (action) => action.type === `searchBar/${BROWSE_TO_RESULT}` && this.hideModal(),
    });
    !this.isDesktop && this.triggerFocus();
  },
  unmounted(): void {
    unsubscribe && unsubscribe();
  },
  methods: {
    ...mapActions({
      [INIT_RESULTS]: `searchBar/${INIT_RESULTS}`,
      [FETCH_RESULTS_ELASTIC]: `searchBar/${FETCH_RESULTS_ELASTIC}`,
      [DO_RESET_RESULTS]: `searchBar/${DO_RESET_RESULTS}`,
      [BROWSE_TO_RESULT]: `searchBar/${BROWSE_TO_RESULT}`,
      [HANDLE_ITEM_CLICK]: `searchBar/${HANDLE_ITEM_CLICK}`,
      [HANDLE_CLICK_ALL_RESULTS]: `searchBar/${HANDLE_CLICK_ALL_RESULTS}`,
      [HANDLE_R2B_CLICK]: `searchBar/${HANDLE_R2B_CLICK}`,
      [SAVE_RECENT_SEARCH]: `searchBar/${SAVE_RECENT_SEARCH}`,
      [SET_RECENT_SEARCH_FOR_QUERY]: `searchBar/${SET_RECENT_SEARCH_FOR_QUERY}`,
    }),
    ...mapMutations({
      [RESET_ARROW_POSITION]: `searchBar/${RESET_ARROW_POSITION}`,
      [SET_ARROW_POSITION_UP]: `searchBar/${SET_ARROW_POSITION_UP}`,
      [SET_ARROW_POSITION_DOWN]: `searchBar/${SET_ARROW_POSITION_DOWN}`,
      [SET_ORIGINAL_USER_INPUT]: `searchBar/${SET_ORIGINAL_USER_INPUT}`,
      [SET_RESULTS_VISIBILITY]: `searchBar/${SET_RESULTS_VISIBILITY}`,
    }),
    resetCurrentResults() {
      this[RESET_ARROW_POSITION]();
      this[DO_RESET_RESULTS](this.searchInput);
      this[SET_RESULTS_VISIBILITY](false);
    },
    hideModal(): void {
      this.$emit('close');
    },
    async doSearch() {
      this[RESET_ARROW_POSITION]();

      await this[FETCH_RESULTS_ELASTIC]({ query: this.searchInput });

      this[SET_RESULTS_VISIBILITY](this.isFocused);
    },
    clearSearch(): void {
      this.searchInput = '';
      this[DO_RESET_RESULTS]();
      this.triggerBlur();
    },
    triggerBlur() {
      this.$nextTick(() => {
        (this.$refs['search-autocomplete__input'] as HTMLInputElement).blur();
      });
    },
    triggerFocus() {
      this.$nextTick(() => {
        (this.$refs['search-autocomplete__input'] as HTMLInputElement).focus();
      });
    },
    onFocus(): void {
      this.doSearch();
      this.isFocused = true;
      this[SET_RECENT_SEARCH_FOR_QUERY](this.searchInput);
    },
    onBlur(): void {
      if (this.isDesktop) {
        this.isFocused = false;
      }
    },
    onChange(e: Event): void {
      this.searchInput = (e.target as HTMLInputElement).value;
      if (!this.searchInput) {
        this[DO_RESET_RESULTS]();
      }
      this[SET_RECENT_SEARCH_FOR_QUERY](this.searchInput);
      this[SET_ORIGINAL_USER_INPUT](this.searchInput);
      this.debouncedSearch();
    },
    debouncedSearch: debounce(async function (this: Vue) {
      await this.doSearch();
      this.suggestionsKey += 1;
    }, 500),
    updateCurrentQuery() {
      this.searchInput =
        this.currentSelectedItem?.link === R2B_LINK_HREF
          ? this.originalUserInput
          : this.currentSelectionName || this.originalUserInput;
    },
    onArrowDown(): void {
      this[SET_ARROW_POSITION_DOWN]();
    },
    onArrowUp(): void {
      this[SET_ARROW_POSITION_UP]();
    },
    onResultItemClick() {
      this[HANDLE_ITEM_CLICK]({ item: this.currentSelectedItem, router: this.$router });
    },
    onClickSeeAllResults(): void {
      this.originalUserInput && this[HANDLE_CLICK_ALL_RESULTS]({ router: this.$router, searchInput: this.searchInput });
    },
    onClickR2BLink(): void {
      this[HANDLE_R2B_CLICK]({ router: this.$router, href: R2B_LINK_HREF, searchQuery: this.searchInput });
    },
    handleClickOutside() {
      this.isDesktop && this.resetCurrentResults();
    },
    onEnter(): void {
      if (this.currentSelectedItem) {
        switch (this.currentSelectionId) {
          case R2B_LINK_ID: {
            this.onClickR2BLink();
            break;
          }
          case SHOW_ALL_ID: {
            this.onClickSeeAllResults();
            break;
          }
          default: {
            this.onResultItemClick();
          }
        }
      } else {
        this.onClickSeeAllResults();
      }
      this[SAVE_RECENT_SEARCH]({ searchQuery: this.searchInput });
      this.triggerBlur();
    },
    onEsc(): void {
      this.triggerBlur();
      this.searchInput = this.originalUserInput;
      this[DO_RESET_RESULTS](this.searchInput);
      this[SET_RESULTS_VISIBILITY](false);
    },
  },
});
</script>
