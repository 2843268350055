import { SHOP_STORE_ID } from '@/store/shop/constants';
import type { ShopState, ShopGetters, ShopActions, ShopMutations } from '@/store/shop';

import { createNamespacedHelpers } from 'vuex-composition-helpers';

const { useGetters, useActions, useMutations } = createNamespacedHelpers<ShopState, ShopGetters, ShopActions, ShopMutations>(
  SHOP_STORE_ID
);

export default {
  useGetters,
  useActions,
  useMutations,
};
