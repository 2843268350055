import { CART_PREORDER_STORE_ID } from '@/store/cart-preorder/constants';
import type {
  CartPreorderState,
  CartPreorderGetters,
  CartPreorderActions,
  CartPreorderMutations,
} from '@/store/cart-preorder/index';

import { createNamespacedHelpers } from 'vuex-composition-helpers';

const { useGetters, useActions, useMutations } = createNamespacedHelpers<
  CartPreorderState,
  CartPreorderGetters,
  CartPreorderActions,
  CartPreorderMutations
>(CART_PREORDER_STORE_ID);

export default {
  useGetters,
  useActions,
  useMutations,
};
