<script lang="ts">
import { defineComponent } from 'vue';
import { formatDate } from '@/services/date-locale';

export default defineComponent({
  name: 'BrandMetaBadges',
  props: {
    brand: {
      type: Object,
      required: true,
    },
    showLastOrdered: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  computed: {
    lastOrderedDate(): string {
      return formatDate(this.brand.last_ordered, 'MMM dd, yyyy');
    },
  },
});
</script>

<template>
  <div
    v-if="brand.last_ordered && showLastOrdered"
    class="meta-badge__last-order"
  >
    <span class="meta-badge__last-order__title">
      {{ $t('brand.brandSnippet.brandMetaBadges.lastOrdered.title') }}
    </span>
    <span class="meta-badge__last-order__date">
      {{ lastOrderedDate }}
    </span>
  </div>
  <span
    v-if="brand.has_new_products"
    class="meta-badge ds-text-info-700"
  >
    {{ $t('New Items!') }}</span>
</template>

<style lang="scss" scoped>
@import '@css/components/_brand_meta_badge';
.meta-badge__last-order {
  @apply ds-w-full ds-font-semibold ds-text-xs ds-lowercase;
  &:first-letter {
    @apply ds-uppercase;
  }
  &__title {
    @apply ds-text-neutral-700;
  }
  &__date {
    @apply ds-capitalize;
  }
}
</style>
