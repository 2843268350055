<template>
  <div class="product-quantity">
    <ProductCounter
      class="product-quantity__counter ds-mt"
      :quantity-multiplied="quantityMultiplied"
      :quantity-available="quantityAvailable"
      :is-increase-disabled="isIncreaseDisabled"
      :is-decrease-disabled="isDecreaseDisabled"
      @increase="increaseQuantity"
      @decrease="decreaseQuantity"
    />
    <ProductCounterButton
      :is-loading="isLoading"
      :is-disabled="hasEnoughStock"
      @update-cart="handlerCartActions"
    />
    <AkModal
      v-if="isEnabled('oxp-1484-r3')"
      ref="preorderArrivedPopup"
      size="md"
      data-testid="preorderArrivedPopup"
      @cancelled="handlePopupClose"
    >
      <PreorderArrivedPopup @popup-close="handlePopupClose" />
    </AkModal>
  </div>
</template>

<script lang="ts">
import { computed, defineComponent, ref, watch, PropType, toRefs } from 'vue';
import ProductCounter from '@/modules/reorder/product-card/product-quantity/product-counter.vue';
import ProductCounterButton from '@/modules/reorder/product-card/product-quantity/product-counter-button.vue';
import PreorderArrivedPopup from '@/components/popins/preorder-arrived-popup.vue';
import Product from '@/types/product';
import { ESProductHit } from '@bc/discovery';
import Analytics from '@/services/analytics';
import {
  ProductQuickAddToCartEvent,
  ReorderProductCardActions,
  ReorderProductCardTrackingData,
} from '@/modules/reorder/product-card/tracking';
import UserClick from '@/services/analytics/events/user-click.event';
import { captureException } from '@/sentry';
import { OrderedBrand } from '@/services/reorder/reorder';
import { isPreorder } from '@/services/product-variants';
import { isEnabled } from '@/services/features';
import { useStore } from '@/composables/use-store';
import { useCart } from '@/modules/cart-2/composables/use-cart';
import Cookie from '@/utilities/cookies/cookies';
import { COOKIE_NAME } from '@/types/cookies';
import { createProductProperty } from '@/services/analytics/properties/product-property';
import cartStoreHelpers from '@/store/cart/helpers';

export default defineComponent({
  name: 'ProductQuantity',
  components: {
    ProductCounter,
    ProductCounterButton,
    PreorderArrivedPopup,
  },
  props: {
    product: {
      type: Object as PropType<Product | ESProductHit>,
      default: null,
    },
    trackingData: {
      type: Object as PropType<ReorderProductCardTrackingData>,
      default: () => null,
    },
    brand: {
      type: Object as PropType<OrderedBrand>,
      default: null,
    },
    purchasingListUpdate: {
      type: Boolean,
      default: false,
    },
  },
  emits: ['cart-action', 'updated-purchasing-list'],
  setup(props, { emit }) {
    const { product } = toRefs(props);
    const store = useStore();

    const isLocalLoading = ref(false);
    const quantityAvailable = Number(product.value?.options[0]?.stock?.available_quantity);
    const isAlwaysInStock = product.value?.options[0]?.stock?.is_always_in_stock;
    const variant = product.value?.variants[0];
    const option = product?.value?.options?.find(({ sku }) => sku === variant.sku);
    const quantityMultiplied = ref(product.value?.unit_multiplier);
    const quantity = ref(1);
    const preorderArrivedPopup = ref(null);

    const { itemByOptionId, isCartLoading, isTotalsLoading } = cartStoreHelpers.useGetters([
      'itemByOptionId',
      'isCartLoading',
      'isTotalsLoading',
    ]);

    const { addToCart, updateItemQuantity } = cartStoreHelpers.useActions(['addToCart', 'updateItemQuantity']);

    const { updateItemQuantity: updatePurchasingListItemQuantity } = useCart();

    const isIncreaseDisabled = computed(() => {
      return isAlwaysInStock
        ? false
        : product.value?.unit_multiplier >
            quantityAvailable - ((isItemAlreadyInCart.value?.multiplied_quantity || 0) + quantityMultiplied.value);
    });

    const hasEnoughStock = computed(() => {
      return isAlwaysInStock
        ? false
        : (isItemAlreadyInCart.value?.multiplied_quantity || 0) > quantityAvailable - product.value?.unit_multiplier;
    });

    const isDecreaseDisabled = computed(() => {
      return quantityMultiplied.value <= product.value?.unit_multiplier;
    });

    const productFromStore = computed(() => store.getters['productById'](product.value.id));

    const isItemAlreadyInCart = computed(() => {
      return itemByOptionId.value(option?.id);
    });

    const isItemAlreadyInPreorderCart = computed(() => {
      return store.getters['cartPreorder/itemByVariantId'](productFromStore.value?.variants[0]?.uuid);
    });

    const isPreorderCartLoading = computed(() => {
      return store.getters['cartPreorder/isCartLoading'];
    });

    const isLoading = computed(() => {
      return (
        (isCartLoading.value && isLocalLoading.value) ||
        (isPreorder(product.value) && isEnabled('oxp-1484-r3') && isPreorderCartLoading.value && isLocalLoading.value) ||
        (props.purchasingListUpdate && isLocalLoading.value)
      );
    });

    watch(
      () => isLoading.value,
      (newValue) => {
        if (!newValue) {
          setTimeout(() => {
            emit('cart-action', quantityMultiplied.value);
            quantityMultiplied.value = Number(product.value?.unit_multiplier);
            quantity.value = 1;
            isLocalLoading.value = false;
          }, 100);
        }
      }
    );

    const increaseQuantity = () => {
      quantity.value++;
      quantityMultiplied.value += product.value?.unit_multiplier;
    };

    const decreaseQuantity = () => {
      quantity.value--;
      quantityMultiplied.value -= product.value?.unit_multiplier;
    };

    const handleAddToCart = async () => {
      try {
        if (isPreorder(product.value) && isEnabled('oxp-1484-r3')) {
          await store.dispatch('cartPreorder/addToCart', {
            brand: product.value?.brand,
            variant: productFromStore.value?.variants[0],
            quantity: quantity.value,
          });
          if (!Cookie.get(COOKIE_NAME.PREORDER_ARRIVED_POPUP_SNOOZED)) {
            (preorderArrivedPopup.value as DS['AkModal']).openModal();
          }
        } else if (props.purchasingListUpdate) {
          isLocalLoading.value = true;
          await updatePurchasingListItemQuantity(product.value.variants[0].uuid, quantity.value);
          emit('updated-purchasing-list', quantityMultiplied.value);
          isLocalLoading.value = false;
        } else {
          await addToCart({ brand: product.value?.brand, option, variant, quantity: quantity.value, noTrack: true });
        }
        if (props.trackingData) {
          await Analytics.track(
            new ProductQuickAddToCartEvent(
              createProductProperty({
                id: product.value.id,
                name: product.value.name as string,
                wholesalePrice: product.value.wholesale_price,
                position: props.trackingData.index,
                option: { id: option.id, name: option.name as string },
              }),
              props.trackingData.index,
              props.trackingData.sectionId,
              props.brand
            )
          );
        }
      } catch (e) {
        captureException(e);
      }
    };

    const handleUpdateCart = async () => {
      const item =
        isPreorder(product.value) && isEnabled('oxp-1484-r3')
          ? store.getters['cartPreorder/itemByVariantId'](productFromStore.value?.variants[0]?.uuid)
          : itemByOptionId.value(option?.id);
      const quantityAlreadyInCart = item.quantity + quantity.value;

      if (isPreorder(product.value) && isEnabled('oxp-1484-r3')) {
        await store.dispatch('cartPreorder/updateItemQuantity', {
          brand: product.value?.brand,
          item: item,
          quantity: quantityAlreadyInCart,
        });
      } else {
        await updateItemQuantity({
          brand: product.value?.brand,
          item: item,
          quantity: quantityAlreadyInCart,
        });
      }
    };

    const handlerCartActions = async () => {
      isLocalLoading.value = true;
      if (props.trackingData) {
        trackAddToCartClicked();
      }
      if (
        isPreorder(product.value) &&
        isEnabled('oxp-1484-r3') &&
        (!productFromStore.value || !productFromStore.value?.variants[0]?.uuid)
      ) {
        await refreshedProduct();
      }
      if (
        isItemAlreadyInCart.value ||
        (isPreorder(product.value) && isEnabled('oxp-1484-r3') && isItemAlreadyInPreorderCart.value)
      ) {
        await handleUpdateCart();
      } else {
        await handleAddToCart();
      }
    };

    function trackAddToCartClicked() {
      Analytics.track(
        new UserClick({
          component: 'reco_carousel',
          action: ReorderProductCardActions.QUICK_ADD_TO_CART_CLICKED,
          id_section: props.trackingData.sectionId,
          itemtype: 'product',
          productId: product.value.id,
          position: props.trackingData.index,
          lb_value_proposition: props.trackingData.lbValueProposition,
        })
      );
    }

    const refreshedProduct = async () => {
      await store.dispatch('fetchProduct', { product_id: product.value.id });
    };

    const handlePopupClose = () => {
      Cookie.set(COOKIE_NAME.PREORDER_ARRIVED_POPUP_SNOOZED, { expires: 365 });
      (preorderArrivedPopup.value as DS['AkModal']).close();
    };

    return {
      quantity,
      quantityMultiplied,
      increaseQuantity,
      decreaseQuantity,
      quantityAvailable,
      isIncreaseDisabled,
      isDecreaseDisabled,
      handleAddToCart,
      handlerCartActions,
      isCartLoading,
      isTotalsLoading,
      isItemAlreadyInCart,
      hasEnoughStock,
      isLoading,
      isEnabled,
      preorderArrivedPopup,
      handlePopupClose,
    };
  },
});
</script>

<style lang="scss" scoped>
.product-quantity {
  @apply ds-flex ds-justify-center ds-items-center ds-flex-col sm:ds-flex-row;
  &__counter {
    @apply ds-mr-0 sm:ds-mr-2;
  }
}
</style>
