<template>
  <BannerLayout
    :gap="gap"
    :background-color="backgroundColor"
    :background-images="backgroundImages"
    :left-block-size="leftBlockSize"
    :type-left-block="typeLeftBlock"
  >
    <template #header>
      <slot />
    </template>

    <template #default>
      <AkCarousel
        :gap-size="gap"
        :breakpoints="breakpoints"
        :arrows="isXlTablet || isDesktop || isXlDesktop"
        :padding-left="gap"
        :padding-right="gap * 2.5"
        :pagination="false"
        :per-page="perPage"
        :per-move="perMove"
        :class="[`ds-pt-${carouselPadding}`, `ds-py-${carouselPadding}`]"
      >
        <AkCarouselItem
          v-for="(item, index) in items"
          :key="index"
        >
          <slot
            name="item"
            :item="item"
            :index="index"
          />
        </AkCarouselItem>
      </AkCarousel>
      <div
        v-if="isMobile || isXlMobile || (isTablet && !isXlTablet) && slots.mobileCta"
        :class="['ds-flex ds-justify-center', 'ds-w-full', 'ds-my-5']"
      >
        <slot name="mobile-cta" />
      </div>
    </template>
  </BannerLayout>
</template>

<script lang="ts">
export default { name: 'BannerCarousel' };
</script>
<script setup lang="ts" generic="TItem">
import { PropType } from 'vue';
import useBreakpoints from '@/modules/design-system-candidates/breakpoints/use-breakpoints';
import { AkCarousel, AkCarouselItem } from '@ankorstore/design-system';
import BannerLayout from './banner-layout.vue';

import { useSlots } from 'vue'
const slots = useSlots()

defineProps({
  items: {
    type: Array as PropType<TItem[]>,
    required: true,
  },
  breakpoints: {
    type: Object as PropType<{ [key: number]: { [prop: string]: unknown } }>,
    required: false,
    default: () => ({
      1279: { perPage: 2 },
      1023: { perPage: 2 },
      375: { perPage: 1 },
    }),
  },
  carouselPadding: {
    type: Number,
    required: false,
    default: 7,
  },
  gap: {
    type: Number,
    required: false,
    default: 24,
  },
  backgroundColor: {
    type: String,
    default: '#EFF4F5',
  },
  backgroundImages: {
    type: Array as PropType<{ url: string }[]>,
    required: false,
    default: () => [],
  },
  leftBlockSize: {
    type: String as PropType<'column' | 'fixed'>,
    required: false,
    default: 'column',
  },
  perPage: {
    type: Number,
    default: 3,
  },
  perMove: {
    type: Number,
    default: 3,
  },
  typeLeftBlock: {
    type: String as PropType<'recommendations' | 'discovery'>,
    required: false,
    default: 'discovery',
  },
});

const { isXlTablet, isDesktop, isXlDesktop } = useBreakpoints();
</script>
