<template>
  <div class="campaigns-template-editor">
    <AkHeading4 class="ds-mb-4">
      {{ $t('account.brands.createCampaigns.templateEditor.title') }}
    </AkHeading4>
    <div class="ds-flex ds-gap-5 ds-mb-4">
      <div class="ds-flex-1 ds-relative">
        <AkInput
          v-bind="$attrs"
          data-testid="campaignSubject"
          name="campaignSubject"
          type="text"
          class="ds-mb-4"
          :model-value="form.subject"
          :label="$t('Subject')"
          :placeholder="$t('Discover our new products!')"
          :label-info="$t('account.brands.createCampaigns.subject.tooltip')"
          :errors="errors?.fields?.subject?.[0]"
          :disabled="disabled"
          :required="true"
          :maxlength="75"
          @update:model-value="emit('update:subject', $event)"
        />
        <AkParagraph
          v-if="!!form.subject"
          size="xs"
          data-testid="campaignsCharCounter"
          class="campaigns-template-editor__char-counter"
        >
          {{ charCounter }}
        </AkParagraph>
      </div>
    </div>

    <div class="editmail__container">
      <div class="editmail__constructor">
        <div class="editmail__content">
          <CreateCampaignsEmailHeader :images="brand.images" />
        </div>
        <div
          class="editmail__editor editmail__editor--menu"
          :class="{ 'error-border': errors?.fields?.body || errors.templateBody }"
        >
          <RichTextEditor
            ref="editorBody"
            :placeholder="placeholder.body"
            :original-content="form.body"
            :disabled="disabled"
            identifier="body"
            height="500"
            @content-updated="emit('update:body', $event)"
          />
        </div>

        <div
          v-if="errors?.fields?.body"
          class="error"
        >
          {{ errors?.fields?.body?.[0] }}
        </div>
        <AkParagraph
          v-if="errors.templateBody"
          class="error error-msg"
          role="alert"
        >
          {{ $t('This field is required') }}
        </AkParagraph>

        <div class="editmail__editor editmail__editor--cta text-center">
          <span
            v-if="form.language"
            class="button button-discount button-fake"
          >
            <input
              v-model="form.ctaText"
              type="text"
            />
          </span>

          <AkParagraph
            v-if="errors?.templateButton"
            class="error error-msg"
            role="alert"
          >
            {{ $t('This field is required') }}
          </AkParagraph>
        </div>
        <div
          class="editmail__editor editmail__editor--menu"
          :class="{ 'error-border': errors?.fields?.footer || errors.templateFooter }"
        >
          <RichTextEditor
            ref="editorFooter"
            :placeholder="placeholder.footer"
            :original-content="form.footer"
            :disabled="disabled"
            identifier="footer"
            height="300"
            @content-updated="emit('update:footer', $event)"
          />
        </div>
        <AkParagraph
          v-if="errors?.fields?.footer"
          class="error error-msg"
        >
          {{ errors.fields.footer[0] }}
        </AkParagraph>
        <AkParagraph
          v-if="errors?.templateFooter"
          class="error error-msg"
          role="alert"
        >
          {{ $t('This field is required') }}
        </AkParagraph>
        <div
          v-if="form.language"
          class="editmail__content"
        >
          <p id="unfollow">
            {{
              $t('Unsubscribe from emails from {brand}', form.language, {
                brand: brand.name,
              })
            }}
          </p>
        </div>
      </div>
    </div>
  </div>
</template>
<script lang="ts">
export default defineComponent({
  compatConfig: { COMPONENT_V_MODEL: false },
});
</script>
<script setup lang="ts">
import { computed, toRefs, watch, ref, Ref, onMounted, defineComponent } from 'vue';
import { AkHeading4, AkInput, AkParagraph } from '@ankorstore/design-system';
import { Brand } from '@/types/api/brand';
import { useStore } from '@/composables/use-store';
import { Placeholder, CreateCampaignFormState, FormErrors } from '../../types/create-campaigns.types';
import CreateCampaignsEmailHeader from '@/components/account/campaigns/create-campaigns-email-header.vue';
import {RichTextEditor} from "@bc/shared";

const props = withDefaults(
  defineProps<{
    errors?: FormErrors;
    disabled?: boolean;
    form: CreateCampaignFormState;
    placeholder: Placeholder;
  }>(),
  {
    errors: null,
    disabled: false,
  }
);
const emit = defineEmits(['update:subject', 'update:body', 'update:footer']);

const store = useStore();
const brand = computed((): Brand => store.getters['brand']);
const { form, errors } = toRefs(props);
const editorBody: Ref = ref(null);
const editorFooter: Ref = ref(null);

const charCounter = computed(() => {
  return (form.value?.subject?.length || 0) + '/75';
});

watch(
  form,
  (newForm) => {
    editorBody.value?.setContent(newForm.body);
    editorFooter.value?.setContent(newForm.footer);
  },
  { deep: true }
);

onMounted(() => {});
</script>

<style lang="scss" scoped>
.campaigns-template-editor {
  &__char-counter {
    @apply ds-absolute ds-right-0 ds-top-5 ds-p-4 ds-text-neutral-700;
  }
}

.error {
  @apply ds-text-error-700 ds-text-center ds-mt-4;
}

.error-border {
  @apply ds-border-error-700 ds-border-solid;
  border-radius: 11px;
  border-width: 1px;
}

.editmail {
  &__constructor {
    max-width: 465px;
    margin: 0 auto 20px;
    @apply ds-bg-white;
    .button-fake {
      width: 100%;
      input {
        border: none;
        background: none;
        @apply ds-text-white;
        text-align: center;
        font-size: 16px;
        outline: none;
        padding: 0;
        width: 100%;
        height: auto;
        &::placeholder {
          @apply ds-text-white;
        }
      }
    }
  }
  &__content {
    p {
      margin-top: 15px;
      margin-bottom: 0;
    }
    img {
      margin-top: 0px;
      margin-bottom: 0;
      display: block;
      margin-left: auto;
      margin-right: auto;
    }
    #sendby {
      margin-top: 25px;
      margin-bottom: 25px;
      font-size: 13px;
    }
    #unfollow {
      @apply ds-text-neutral-700;
      margin-bottom: 15px;
      font-size: 12px;
    }
    #sendby,
    #unfollow {
      text-align: center;
    }
  }
  &__actions {
    display: flex;
    flex-direction: column;
    button {
      margin-bottom: 10px;
      &:disabled {
        @apply ds-text-neutral-700 ds-border ds-border-solid ds-border-neutral-700;
        &:hover {
          @apply ds-bg-white;
          @apply ds-text-neutral-700;
          cursor: not-allowed;
        }
      }
    }
  }
  &__editor {
    &--menu,
    &--cta {
      margin-top: 15px;
    }
    &--cta {
      a {
        @apply ds-font-basic;
        font-size: 14px;
        font-weight: bold;
      }
    }
    textarea {
      @apply ds-border-neutral-300;
      width: 100%;
      height: auto;
      resize: none;
      border-radius: 0;
      &:hover,
      &:focus {
        @apply ds-border-solid ds-border-primary;
      }
      &:focus {
        outline: none;
        box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
      }
    }
  }
}
</style>
