<template>
  <div
    v-if="getSelectedFilters.length"
    class="ds-flex"
  >
    <div class="ds-flex-1">
      <AkTag
        v-for="filter in getSelectedFilters"
        :key="filter.id"
        closable
        color="accent"
        size="xs"
        class="ds-mr-2 ds-mb-2"
        data-testid="selectedFilterTag"
        @click.capture.stop="removeFilter(filter.id, true)"
      >
        <span class="ds-font-bold">{{ filter.label }}</span>
      </AkTag>
    </div>

    <AkButton
      v-if="getSelectedFilters.length > 1"
      class="ds-mb-2"
      link
      @click="handleClearFilterEvent"
    >
      {{ $t('Clear all') }}
    </AkButton>
  </div>
  <AkParagraph
    v-else-if="!getSelectedFilters.length && showEmptyMessage"
    class="!ds-text-sm ds-text-neutral-700"
  >
    {{ $t('brands.network.filters.empty') }}
  </AkParagraph>
</template>
<script setup lang="ts">
import { AkTag, AkButton, AkParagraph } from '@ankorstore/design-system';
import { useStore } from '@/composables/use-store';
import { computed } from 'vue';
const emit = defineEmits(['triggerFunction']);

const props = withDefaults(
  defineProps<{
    showEmptyMessage?: boolean;
    emitFunctions?: boolean;
  }>(),
  {
    showEmptyMessage: false,
    emitFunctions: false,
  }
);

const store = useStore();
const getSelectedFilters = computed(() => store.getters['account/contactManagement/getSelectedFilters']);
const removeFilter = (filterId: string, skipEmit = false) => {
  const setFilter = () => {
    store.dispatch('account/contactManagement/setFilter', [filterId, null]);
  };
  props.emitFunctions && !skipEmit ? emit('triggerFunction', setFilter) : setFilter();
};
const handleClearFilterEvent = () => {
  const setFilters = () => {
    store.dispatch('account/contactManagement/setFilters', []);
  };
  props.emitFunctions ? emit('triggerFunction', setFilters) : setFilters();
};
</script>
