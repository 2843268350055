<template>
  <div
    data-testid="qatc"
    :class="{
      QATC: true,
      [NativeAdClickOrigin.QuickAddToCart]: true,
      'QATC--with-feedback': willShowFeedback || isShowingFeedback,
    }"
  >
    <AkButton
      :class="{ QATC__button: true, 'ds-absolute ds-w-full': !mobile }"
      data-testid="qatc-button"
      :disabled="isShowingFeedback"
      outlined
      color="white"
      multiline
      @click="clickHandler"
    >
      <AkIcon
        class="ds-pb-0.5 ds-mr-0.5"
        :symbol="isShowingFeedback ? 'check' : 'plus'"
        size="sm"
      />
      <span v-if="!mobile">{{ buttonLabel }}</span>
    </AkButton>
    <AkModal
      v-if="$isEnabled('oxp-1484-r3')"
      ref="preorderArrivedPopup"
      size="md"
      data-testid="preorderArrivedPopup"
      @cancelled="handlePopupClose"
    >
      <PreorderArrivedPopup @popup-close="handlePopupClose" />
    </AkModal>
  </div>
</template>

<script lang="ts">
import { debounce, type DebouncedFunc } from 'lodash-es';
import { defineComponent, PropType, ref } from 'vue';
import { BrandProperty } from '@/services/analytics/properties/brand-property';
import Product, { Option } from '@/types/product';
import { ESProductHit } from '@bc/discovery';
import { AkIcon } from '@ankorstore/design-system';
import { CanAddFirstVariantToCartUseCase } from '@bc/discovery';
import { useEventBus } from '@bc/shared';
import { NativeAdClickOrigin } from '@bc/advertisement';
import { isPreorder } from '@/services/product-variants';
import PreorderArrivedPopup from '@/components/popins/preorder-arrived-popup.vue';
import Cookie from '@/utilities/cookies/cookies';
import { COOKIE_NAME } from '@/types/cookies';
import StoreRootHelpers from '@/store/helpers';
import CartHelpers from '@/store/cart/helpers';
import CartPreorderHelpers from '@/store/cart-preorder/helpers';

interface QuickAddToCartData {
  item: {
    option: Partial<Option> & { unit_multiplier: number; name: string };
    brand: BrandProperty;
    quantity: number;
    preorderQuantity: number;
  };
  hasVariants: boolean;
  itemsToAddNext: number;
  willShowFeedback: boolean;
  feedbackTimeoutID: number;
  debouncedExecuteUpdateActions: DebouncedFunc<() => Promise<void>>;
}

const CLICK_DEBOUNCE_TIME = 550;
const FEEDBACK_TIME = 2000;

export default defineComponent({
  name: 'QuickAddToCart',
  components: {
    PreorderArrivedPopup,
    AkIcon,
  },
  props: {
    mobile: {
      type: Boolean,
      default: false,
    },
    index: {
      type: Number,
      required: false,
      default: undefined,
    },
    pageNumber: {
      type: Number,
      required: false,
      default: undefined,
    },
    product: {
      type: Object as PropType<Product | ESProductHit>,
      required: true,
    },
    parentSectionId: {
      type: String,
      default: undefined,
    },
    parentComponent: {
      type: String as PropType<string>,
      default: null,
    },
    isSponsored: {
      type: Boolean,
      required: false,
    },
    // Temporary prop to disable tracking while we work on RET-3307
    disableTracking: {
      type: Boolean,
      default: false,
    },
  },
  emits: ['clicked', 'clickVariantsModal', 'productAddedToCart'],
  setup() {
    const { eventBus } = useEventBus();
    const preorderArrivedPopup = ref(null);

    const handlePopupClose = () => {
      Cookie.set(COOKIE_NAME.PREORDER_ARRIVED_POPUP_SNOOZED, { expires: 365 });
      (preorderArrivedPopup.value as DS['AkModal']).close();
    };

    const { productByOptionId } = StoreRootHelpers.useGetters(['productByOptionId']);
    const { itemByOptionId } = CartHelpers.useGetters(['itemByOptionId']);
    const { itemByVariantId } = CartPreorderHelpers.useGetters(['itemByVariantId']);
    const { addToCart: addToCartAction, updateItemQuantity: updateItemQuantityAction } = CartHelpers.useActions([
      'addToCart',
      'updateItemQuantity',
    ]);
    const { addToCart: addToPreorderCartAction, updateItemQuantity: updatePreorderItemQuantityAction } =
      CartPreorderHelpers.useActions(['addToCart', 'updateItemQuantity']);

    return {
      eventBus,
      NativeAdClickOrigin,
      preorderArrivedPopup,
      handlePopupClose,
      productByOptionId,
      itemByOptionId,
      itemByVariantId,
      addToCartAction,
      updateItemQuantityAction,
      addToPreorderCartAction,
      updatePreorderItemQuantityAction,
    };
  },
  data(): QuickAddToCartData {
    return {
      item: {
        option: { unit_multiplier: 1, name: '' } as QuickAddToCartData['item']['option'],
        brand: undefined as BrandProperty,
        quantity: 0,
        preorderQuantity: 0,
      },
      hasVariants: undefined,
      itemsToAddNext: 0,
      willShowFeedback: false,
      feedbackTimeoutID: undefined as number,
      debouncedExecuteUpdateActions: undefined as QuickAddToCartData['debouncedExecuteUpdateActions'],
    };
  },
  computed: {
    buttonLabel(): string {
      return this.isShowingFeedback
        ? this.$tc(
            '{count} item added to cart! | {count} item added to cart! | {count} items added to cart!',
            this.itemsToAddNext * this.product.unit_multiplier
          )
        : this.$tc(
            'Quick add of {count} product | Quick add of {count} product | Quick add of {count} products',
            this.product.unit_multiplier
          );
    },
    isShowingFeedback(): boolean {
      return Number.isInteger(this.feedbackTimeoutID);
    },
    productFromStore(): Product {
      return this.$store.getters['productById'](this.product.id);
    },
  },
  watch: {
    product: {
      handler: function () {
        this.item.brand = this.product.brand;
        const variant = this.product.variants[0];
        this.item.option = {
          ...this.product.options.find(({ sku }) => sku === variant?.sku),
          unit_multiplier: this.product.unit_multiplier,
          name: this.product.name as string,
        };
        this.item.quantity =
          this.itemByOptionId(this.item.option?.id) !== null ? this.itemByOptionId(this.item.option.id).quantity : 0;
        this.item.preorderQuantity =
          this.$isEnabled('oxp-1484-r3') && this.itemByVariantId(this.productFromStore?.variants[0]?.uuid) !== null
            ? this.itemByVariantId(this.productFromStore?.variants[0]?.uuid).quantity
            : 0;
        this.hasVariants = Boolean(this.product.variants.length > 1);
      },
      immediate: true,
    },
  },
  created() {
    this.debouncedExecuteUpdateActions = debounce(this.executeUpdateActions, CLICK_DEBOUNCE_TIME);
  },
  unmounted() {
    this.feedbackTimeoutID && window.clearTimeout(this.feedbackTimeoutID);
  },
  methods: {
    clickHandler() {
      this.$emit('clicked');
      this.willShowFeedback = true;
      this.item.quantity += 1;
      this.item.preorderQuantity += 1;
      this.itemsToAddNext += 1;
      this.debouncedExecuteUpdateActions();
    },
    async executeUpdateActions() {
      if (this.hasVariants) {
        this.clearFeedbackState();
        this.$emit('clickVariantsModal');
        return;
      }

      const canAddToCart = await new CanAddFirstVariantToCartUseCase(this.$store).execute(this.product, this.item.quantity);

      if (!canAddToCart) {
        return;
      }

      this.displayFeedback();
      if (
        isPreorder(this.product) &&
        this.$isEnabled('oxp-1484-r3') &&
        (!this.productFromStore || !this.productFromStore?.variants[0]?.uuid)
      ) {
        await this.refreshProduct();
      }
      if (
        this.itemByOptionId(this.item.option?.id) ||
        (isPreorder(this.product) &&
          this.$isEnabled('oxp-1484-r3') &&
          this.itemByVariantId(this.productFromStore?.variants[0]?.uuid))
      ) {
        return this.executeUpdateQuantityAction();
      }
      await this.executeAddToCartAction();

      const { brand, option, quantity } = this.item;
      const payload = {
        brand,
        option: { id: option.id, name: option.name as string },
        variant: null,
        quantity,
      };
      this.$emit('productAddedToCart', payload);
    },
    displayFeedback() {
      this.feedbackTimeoutID = window.setTimeout(this.clearFeedbackState, FEEDBACK_TIME);
    },
    clearFeedbackState() {
      this.willShowFeedback = false;
      this.itemsToAddNext = 0;
      this.feedbackTimeoutID = undefined;
    },
    executeUpdateQuantityAction() {
      if (isPreorder(this.product) && this.$isEnabled('oxp-1484-r3')) {
        const { brand } = this.productFromStore;

        this.updatePreorderItemQuantityAction({
          brand,
          item: this.itemByVariantId(this.productFromStore?.variants[0]?.uuid),
          quantity: this.item.preorderQuantity,
        });
      } else {
        const { brand, quantity } = this.item;

        this.updateItemQuantityAction({
          brand,
          item: this.itemByOptionId(this.item.option?.id),
          quantity,
        });
      }
    },
    async executeAddToCartAction() {
      if (isPreorder(this.product) && this.$isEnabled('oxp-1484-r3')) {
        const variant = this.productFromStore.variants[0];
        const quantity = this.item.preorderQuantity;

        await this.addToPreorderCartAction({ variant, quantity });
        if (!Cookie.get(COOKIE_NAME.PREORDER_ARRIVED_POPUP_SNOOZED)) {
          (this.preorderArrivedPopup as DS['AkModal']).openModal();
        }
      } else {
        const { brand, option, quantity } = this.item;
        const { variants = [] } = this.product;
        const [variant] = variants;

        await this.addToCartAction({ brand, option, variant, quantity, noTrack: true });
      }
    },
    async refreshProduct(): Promise<void> {
      await this.$store.dispatch('fetchProduct', {
        product_id: this.product.id,
      });
    },
  },
});
</script>

<style scoped lang="scss">
@import '@css/vue-import';
.QATC {
  &__button {
    z-index: $z-index-catalog-card-items;
    transition: transform 0.025s;
    &:disabled {
      opacity: 100 !important;
    }
    &:active:not(:disabled) {
      @apply ds-bg-warm-white;
    }
  }
}
</style>
