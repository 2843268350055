import { onMounted, watchEffect, Ref, reactive, ref } from 'vue';
import { isEnabled } from '@/services/features';
import {
  getLocations,
  getDiscounts,
  getAttributes,
  LabelOverrides,
  AttributeDefinition,
  facetRef,
  Facet,
  Config,
  FacetGroup,
  getMargins,
  getShipping,
  getAksPlus,
} from '@bc/discovery/domain/catalog';
import useCategories from '../composables/useCategories';

type UseLabels = (config: Ref<Config>, selectedCategoryId: Ref<number>, lang: Ref<string>) => { labelOverrides: LabelOverrides };

export const useLabels: UseLabels = (config, selectedCategoryId, lang) => {
  if (!isEnabled('RET-3019')) {
    return useLabelsLocal(config, selectedCategoryId, lang);
  }

  const { addLabelOverrides, labelOverrides } = useLabelOverrides();
  const { categoryLabelOverrides } = useCategoryLabelOverrides();

  watchEffect(() => addLabelOverrides(categoryLabelOverrides.value));

  watchEffect(async () => {
    const createLabel = (facet: Facet) => (choice: { slug: string; name: string }) => [
      `${facet.uid}:${choice.slug}`,
      choice.name,
    ];
    const mapFacetToLabelOverrides = (facet: Facet) => ('choices' in facet ? facet.choices?.map(createLabel(facet)) : []);
    const mapFacetGroupToLabelOverrides = (group: FacetGroup) => group.facets.flatMap((facet) => mapFacetToLabelOverrides(facet));

    const facetLabelOverrides = Object.fromEntries(
      config.value.Facets.flatMap((facet) =>
        'facets' in facet ? mapFacetGroupToLabelOverrides(facet) : mapFacetToLabelOverrides(facet)
      )
    );

    addLabelOverrides(facetLabelOverrides);
  });

  onMounted(async () => {
    const locations = await getLocations();

    const locationLabelOverrides = Object.fromEntries((locations ?? []).map((location) => [location.id, location.name]));
    addLabelOverrides(locationLabelOverrides);
  });

  return { labelOverrides };
};

const useLabelOverrides = () => {
  const labelOverrides = reactive<LabelOverrides>({});

  const addLabelOverrides = (overrides: Record<string, string>, keyPrefix?: string) => {
    Object.entries(overrides).forEach(([key, value]) => {
      if (keyPrefix) {
        labelOverrides[`${keyPrefix}:${key}`] = value;
      } else {
        labelOverrides[key] = value;
      }
    });
  };

  return { labelOverrides, addLabelOverrides };
};

const useCategoryLabelOverrides = () => {
  const categoryLabelOverrides = ref<LabelOverrides>({});
  const { categories } = useCategories();

  watchEffect(() => {
    categoryLabelOverrides.value = Object.fromEntries(
      Object.values(categories.value ?? {}).map((category) => [category.id, category.full_name])
    );
  });

  return { categoryLabelOverrides };
};

/**
 * @deprecated
 */
const useLabelsLocal: UseLabels = (context, selectedCategoryId, lang) => {
  const { addLabelOverrides, labelOverrides } = useLabelOverrides();
  const { categoryLabelOverrides } = useCategoryLabelOverrides();

  watchEffect(() => addLabelOverrides(categoryLabelOverrides.value));

  onMounted(async () => {
    const [margins, shipping, discounts, aksPlus, locations] = await Promise.all([
      getMargins(),
      getShipping(),
      getDiscounts(),
      getAksPlus(),
      getLocations(),
    ]);

    const locationLabelOverrides = Object.fromEntries((locations ?? []).map((location) => [location.id, location.name]));
    const discountLabelOverrides = Object.fromEntries(discounts.map((discount) => [discount.id, discount.label]));

    addLabelOverrides(locationLabelOverrides, facetRef.location);
    addLabelOverrides(locationLabelOverrides, facetRef.locationBrand);
    addLabelOverrides(discountLabelOverrides, facetRef.discount);
    addLabelOverrides(margins, facetRef.margin);
    addLabelOverrides(margins, facetRef.marginBrand);
    addLabelOverrides(shipping, facetRef.shipping_lead_time);
    addLabelOverrides(shipping, facetRef.shipping_lead_timeBrand);
    addLabelOverrides(aksPlus, facetRef.aks_plus);
    addLabelOverrides(aksPlus, facetRef.aks_plus_brand);
  });

  watchEffect(async () => {
    if (!selectedCategoryId.value || !lang.value) {
      return;
    }

    const attributes = await getAttributes(selectedCategoryId.value.toString(), lang.value);

    if (!attributes.length) {
      return;
    }

    const attributeLabelOverrides = Object.fromEntries(attributes.flatMap(mapAttributes));

    addLabelOverrides(attributeLabelOverrides);
  });

  return { labelOverrides };
};

export const getLabel = (labelOverrides: LabelOverrides, facet: Pick<Facet, 'uid'>, originalLabel: string) => {
  return labelOverrides?.[`${facet?.uid}:${originalLabel}`] ?? labelOverrides?.[originalLabel] ?? originalLabel;
};

const mapAttribute = (attribute: AttributeDefinition) =>
  'choices' in attribute ? attribute.choices.map((choice) => [`${attribute.slug}:${choice.slug}`, choice.name]) : [];

const mapAttributes = (item: AttributeDefinition) =>
  item.type === 'group' ? item.attributes.flatMap(mapAttributes) : mapAttribute(item);
